import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  Paper,
  TextField,
  Select,
  MenuItem,
  CircularProgress,
  InputLabel
} from "@material-ui/core";
import { useTranslate } from "../../../services/appLanguageService";
import { EnumStrings } from "../../../redux/strings";
import * as Yup from "yup";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { EditableCommiter } from "../../EditableCommiter";
import { Editor } from "react-draft-wysiwyg";
import { draftToMarkdown, markdownToDraft } from "markdown-draft-js";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import {
  getPersonAPI,
  getPersonsByCompanyAPI
} from "../../../services/api-declaration";
import ReactMarkdown from "react-markdown";
import { format, isValid } from "date-fns";

const useFieldStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(2)
    },
    label: {},
    values: {
      display: "flex",
      width: "100%",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: "solid 1px lightgray",
      "& .editing": {
        minHeight: "55px"
      },
      "& > div:first-child": {
        fontWeight: "bold",
        minWidth: "120px"
      },
      "& > div": {
        display: "flex",
        alignItems: "center",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
      }
    },
    editable: {
      justifyContent: "space-between",
      flexGrow: 1
    },
    multiDatePickers: {
      marginRight: theme.spacing(1)
    },
    editor: {
      border: "1px solid lightgray",
      borderRadius: "5px",
      height: "400px"
    }
  })
);

type ContractFields = {
  name: string;
  display_name: string;
  valid_from: string | null;
  valid_to: string | null;
  duedays: number;
  display_as: string | null;
  contract_text: string | null;
  comment: string | null;
  description: string | null;
  footnote: string | null;
  final_conditions: string | null;
  signer: number | null;
  invoicingperiod: "WEEKLY" | "MONTHLY";
};

type ContractSignerEditorProps = {
  companyId: number;
  signer: number | null;
  onChange: (signer: number | null) => void;
};

const ContractSignerEditor: React.FC<ContractSignerEditorProps> = ({
  companyId,
  signer,
  onChange
}) => {
  const [personNames, setPersonNames] = useState<[number, string][]>();

  useEffect(() => {
    let alive = true;
    void getPersonsByCompanyAPI(companyId).then(({ results }) => {
      alive &&
        setPersonNames(
          results.map((p) => [p.id, `${p.first_name} ${p.last_name}`])
        );
    });
    return () => {
      alive = false;
    };
  }, [companyId]);

  if (!personNames) {
    return <CircularProgress size={20} />;
  }

  return (
    <Select
      value={signer}
      onChange={(e) => onChange(e.target.value as number | null)}
      fullWidth
    >
      {personNames?.map(([id, name]) => (
        <MenuItem key={id} value={id}>
          {name}
        </MenuItem>
      ))}
    </Select>
  );
};

type ContractFieldsProps = {
  companyId: number;
  fields: ContractFields;
  onUpdate: (patch: Partial<ContractFields>) => Promise<void>;
};

const ContractFieldsView: React.FC<ContractFieldsProps> = ({
  companyId,
  fields,
  onUpdate
}) => {
  const classes = useFieldStyles();
  const t = useTranslate("ContractPage");
  const t2 = useTranslate("Contracts");

  const [signerName, setSignerName] = useState<string>("");

  useEffect(() => {
    if (fields.signer) {
      let alive = true;
      void getPersonAPI(fields.signer).then(
        (p) => alive && setSignerName(`${p.first_name} ${p.last_name}`)
      );
      return () => {
        alive = false;
      };
    }
  }, [fields.signer]);

  return (
    <Paper>
      <div className={classes.root}>
        <div className={classes.values}>
          <div>{t("nameLabel")}</div>
          <EditableCommiter
            display={fields.name}
            value={fields.name}
            schema={Yup.string()
              .required(t("fieldRequired"))
              .max(255, t("fieldLengthExceeded"))}
            onCommit={(name) => onUpdate({ name })}
            className={classes.editable}
          >
            {(value, onChange, error) => (
              <TextField
                value={value ?? ""}
                onChange={(e) => onChange(e.target.value)}
                error={!!error}
                helperText={error}
                fullWidth
              />
            )}
          </EditableCommiter>
        </div>
        <div className={classes.values}>
          <div>{t("displayNameLabel")}</div>
          <EditableCommiter
            display={fields.display_name}
            value={fields.display_name}
            schema={Yup.string()
              .required(t("fieldRequired"))
              .max(255, t("fieldLengthExceeded"))}
            onCommit={(display_name) => onUpdate({ display_name })}
            className={classes.editable}
          >
            {(value, onChange, error) => (
              <TextField
                value={value ?? ""}
                onChange={(e) => onChange(e.target.value)}
                error={!!error}
                helperText={error}
                fullWidth
              />
            )}
          </EditableCommiter>
        </div>
        <div className={classes.values}>
          <div>{t("validDatesLabel")}</div>
          <EditableCommiter
            display={
              <>
                {fields.valid_from ?? t("noDateLabel")} -{" "}
                {fields.valid_to ?? t("noDateLabel")}
              </>
            }
            value={[fields.valid_from, fields.valid_to] as const}
            onCommit={([valid_from, valid_to]) =>
              onUpdate({ valid_from, valid_to })
            }
            className={classes.editable}
          >
            {([valid_from, valid_to], onChange) => (
              <>
                <KeyboardDatePicker
                  label={t("validFromDate")}
                  placeholder="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  value={valid_from}
                  onChange={(d) => {
                    if (isValid(d)) {
                      onChange([d ? format(d, "yyyy-MM-dd") : "", valid_to])
                    }
                  }}
                  fullWidth
                  className={classes.multiDatePickers}
                />
                <KeyboardDatePicker
                  label={t("validToDate")}
                  placeholder="yyyy-MM-dd"
                  format="yyyy-MM-dd"
                  value={valid_to}
                  onChange={(d) => {
                    if (isValid(d)) {
                      onChange([valid_from, d ? format(d, "yyyy-MM-dd") : ""])
                    }
                  }}
                  fullWidth
                  className={classes.multiDatePickers}
                />
              </>
            )}
          </EditableCommiter>
        </div>
        <div className={classes.values}>
          <div>{t("duedaysLabel")}</div>
          <EditableCommiter
            display={fields.duedays}
            value={fields.duedays.toFixed(0)}
            schema={Yup.string()
              .required(t("fieldRequired"))
              .matches(/^\d+$/, t("fieldDigitOnly"))}
            onCommit={(duedays) => onUpdate({ duedays: parseInt(duedays) })}
            className={classes.editable}
          >
            {(value, onChange, error) => (
              <TextField
                value={value}
                onChange={(e) => onChange(e.target.value)}
                error={!!error}
                helperText={error}
                fullWidth
              />
            )}
          </EditableCommiter>
        </div>
        <div className={classes.values}>
          <div>{t("invoicingPeriodLabel")}</div>
          <EditableCommiter
            display={
              fields.invoicingperiod === "MONTHLY"
                ? t("monthlyPeriod")
                : t("weeklyPeriod")
            }
            value={fields.invoicingperiod}
            schema={Yup.string()
              .required(t("fieldRequired"))
              .oneOf(["WEEKLY", "MONTHLY"])}
            onCommit={(invoicingperiod) =>
              onUpdate({
                invoicingperiod:
                  invoicingperiod as ContractFields["invoicingperiod"]
              })
            }
            className={classes.editable}
          >
            {(value, onChange, error) => (
              <>
                <Select
                  value={value}
                  onChange={(e) => onChange(e.target.value as string)}
                  error={!!error}
                  fullWidth
                >
                  <MenuItem value="MONTHLY">{t("monthlyPeriod")}</MenuItem>
                  <MenuItem value="WEEKLY">{t("weeklyPeriod")}</MenuItem>
                </Select>
                {!!error && (
                  <InputLabel error shrink>
                    {error}
                  </InputLabel>
                )}
              </>
            )}
          </EditableCommiter>
        </div>
        <div className={classes.values}>
          <div>{t("signerLabel")}</div>
          <EditableCommiter
            display={signerName}
            value={fields.signer}
            onCommit={(signer) => onUpdate({ signer })}
            className={classes.editable}
          >
            {(value, onChange) => (
              <ContractSignerEditor
                companyId={companyId}
                signer={value}
                onChange={onChange}
              />
            )}
          </EditableCommiter>
        </div>
        <div className={classes.values}>
          <div>{t("displayAsLabel")}</div>
          <EditableCommiter
            display={t2(fields.display_as ?? "")}
            value={fields.display_as}
            onCommit={(display_as) => onUpdate({ display_as })}
            className={classes.editable}
          >
            {(value, onChange) => (
              <Select
                value={value}
                onChange={(e) => onChange(e.target.value as string)}
                fullWidth
              >
                <MenuItem value={EnumStrings.PENDING}>
                  {t2(EnumStrings.PENDING)}
                </MenuItem>
                <MenuItem value={EnumStrings.CONTRACT}>
                  {t2(EnumStrings.CONTRACT)}
                </MenuItem>
              </Select>
            )}
          </EditableCommiter>
        </div>
        <div className={classes.values}>
          <div>{t("contractTextLabel")}</div>
          <EditableCommiter
            display={<ReactMarkdown children={fields.contract_text ?? ""} />}
            value={EditorState.createWithContent(
              convertFromRaw(markdownToDraft(fields.contract_text ?? ""))
            )}
            onCommit={(editorState) =>
              onUpdate({
                contract_text: draftToMarkdown(
                  convertToRaw(editorState.getCurrentContent())
                )
              })
            }
            className={classes.editable}
          >
            {(value, onChange) => (
              <Editor
                toolbar={{
                  options: ["inline", "blockType", "list", "remove", "history"],
                  inline: {
                    options: ["bold", "italic", "monospace"]
                  },
                  blockType: {
                    inDropdown: false,
                    options: [
                      "Normal",
                      "H1",
                      "H2",
                      "H3",
                      "H4",
                      "H5",
                      "H6",
                      "Blockquote"
                    ]
                  }
                }}
                editorState={value}
                onEditorStateChange={onChange}
                editorClassName={classes.editor}
              />
            )}
          </EditableCommiter>
        </div>
        <div className={classes.values}>
          <div>{t("commentLabel")}</div>
          <EditableCommiter
            display={fields.comment}
            value={fields.comment}
            onCommit={(comment) => onUpdate({ comment })}
            className={classes.editable}
          >
            {(value, onChange) => (
              <TextField
                value={value ?? ""}
                onChange={(e) => onChange(e.target.value)}
                variant="outlined"
                multiline
                minRows={3}
                fullWidth
              />
            )}
          </EditableCommiter>
        </div>
        <div className={classes.values}>
          <div>{t("descriptionLabel")}</div>
          <EditableCommiter
            display={fields.description}
            value={fields.description}
            onCommit={(description) => onUpdate({ description })}
            className={classes.editable}
          >
            {(value, onChange) => (
              <TextField
                value={value ?? ""}
                onChange={(e) => onChange(e.target.value)}
                variant="outlined"
                multiline
                minRows={3}
                fullWidth
              />
            )}
          </EditableCommiter>
        </div>
        <div className={classes.values}>
          <div>{t("footnoteLabel")}</div>
          <EditableCommiter
            display={fields.footnote}
            value={fields.footnote}
            onCommit={(footnote) => onUpdate({ footnote })}
            className={classes.editable}
          >
            {(value, onChange) => (
              <TextField
                value={value ?? ""}
                onChange={(e) => onChange(e.target.value)}
                variant="outlined"
                multiline
                minRows={3}
                fullWidth
              />
            )}
          </EditableCommiter>
        </div>
        <div className={classes.values}>
          <div>{t("finalConditionsLabel")}</div>
          <EditableCommiter
            display={fields.final_conditions}
            value={fields.final_conditions}
            onCommit={(final_conditions) => onUpdate({ final_conditions })}
            className={classes.editable}
          >
            {(value, onChange) => (
              <TextField
                value={value ?? ""}
                onChange={(e) => onChange(e.target.value)}
                variant="outlined"
                multiline
                minRows={3}
                fullWidth
              />
            )}
          </EditableCommiter>
        </div>
      </div>
    </Paper>
  );
};

export default ContractFieldsView;
