import {
  createStyles,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  HistoryLineItem,
  HistoryLineItemParent,
  HistoryRow,
  Material,
  ServiceCategory,
  TimeSheetRowApproval
} from "../../../redux/types";
import clsx from "clsx";
import TableContainer from "@material-ui/core/TableContainer";
import { useTranslate } from "../../../services/appLanguageService";
import { formatDateTime, getDate, niceAmount } from "../../FormatHelpers";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { orderBy } from "lodash";
import { Close } from "@material-ui/icons";
import {
  getMaterialsAPI,
  getWorkApprovalAPI
} from "../../../services/api-declaration";
import { useEffect, useState } from "react";
import { Paper } from "@material-ui/core";

const TransactionGroupStyles = (theme: Theme) =>
  createStyles({
    borderLessRow: {
      "& > *": {
        borderBottom: "unset"
      }
    },
    paddedRow: {
      "& > *": {
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1.5)}px`,
        "&:last-child": {
          paddingRight: theme.spacing(2)
        }
      }
    },
    tableHeaderRow: {
      "& > *": {
        padding: `0 ${theme.spacing(2)}px`,
        backgroundColor: "rgba(0, 0, 0, 0.01)",
        "&:last-child": {
          paddingRight: theme.spacing(2)
        }
      }
    },
    taskRectangle: {
      display: "grid",
      gridTemplateColumns: "auto 1fr 22% 22% 12%",
      border: "1px solid #cdcdcd",
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      gridColumnGap: theme.spacing(1),
      "& *:not(.collapse-icon)": {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5)
      },
      "&.expanded-item .collapse-icon": {
        transform: "rotate(-180deg)"
      }
    },
    collapsedIcon: {
      width: 28,
      height: 28,
      transition: "transform 400ms ease-out"
    },
    boldRow: {
      "& > *": {
        fontWeight: 700
      }
    },
    transactionItemRow: {
      "&.first-step > *": {
        paddingTop: theme.spacing(1.5)
      },
      "&:not(.first-step) .stepperLeftPart": {
        width: 1,
        marginTop: -theme.spacing(0.5),
        marginRight: -1,
        marginBottom: -theme.spacing(0.5),
        marginLeft: 6,
        display: "flex",
        flexDirection: "column"
      },
      "&:not(.first-step) .stepperLeftUpperPart, &:not(.first-step) .stepperLeftLowerPart":
        {
          backgroundColor: "black",
          flex: 1
        },
      "&.last-step .stepperLeftLowerPart": {
        backgroundColor: "unset !important"
      },
      "&:not(.first-step) .stepperRightPart": {
        backgroundColor: "black",
        width: 9,
        height: 1,
        marginRight: theme.spacing(0.5),
        alignSelf: "center"
      },
      "&:last-child > *": {
        paddingBottom: theme.spacing(1.5)
      }
    },
    headerRow: {
      border: "1px solid #cdcdcd"
    },
    faceCell: {
      display: "flex"
    },
    signIcon: {
      width: 15,
      height: 15,
      marginRight: 2,
      alignSelf: "center"
    },
    greenColor: {
      color: "#09873a"
    },
    redColor: {
      color: "#e80d0d"
    },
    yellowColor: {
      color: "#e1b312"
    },
    clickable: {
      cursor: "pointer"
    },
    expansionContainer: {
      marginBottom: theme.spacing(2),
      "&:last-child": {
        marginBottom: theme.spacing(3)
      }
    },
    greenBack: {
      "& .task-header, & .task-footer": {
        backgroundColor: "#a5d1b7"
      }
    },
    yellowBack: {
      "& .task-header, & .task-footer": {
        backgroundColor: "#fbf6e2"
      }
    },
    redBack: {
      "& .task-header, & .task-footer": {
        backgroundColor: "#eba5a3"
      }
    },
    justifyEnd: {
      justifySelf: "end"
    },
    correctionsContainer: {
      marginTop: 10,
      marginBottom: 10
    },
    correctionsHeader: {
      padding: 2,
      fontWeight: "bold"
    },
    correctionsBody: {
      marginTop: 5,
      marginBottom: 5,
      padding: 10
    },
    correction: {
      marginBottom: 3
    },
    pointer: {
      cursor: "pointer"
    },
    vAlignMiddle: {
      verticalAlign: "middle"
    }
  });

export interface TransactionGroupTaskProps
  extends WithStyles<typeof TransactionGroupStyles> {
  itemParent: HistoryLineItemParent;
  tz_name: string;
  isCost: boolean;
  showPrices: boolean;
}

const TransactionGroupTask = withStyles(TransactionGroupStyles)(
  ({ classes, itemParent, isCost, showPrices }: TransactionGroupTaskProps) => {
    const t = useTranslate("HistoryTransactionsDialog");
    const z = useTranslate("ContractItems");
    const sortedSubItems = orderBy(
      itemParent.subitems.filter((i) => i.quantity !== 0),
      ["in_time", "item_type", "title"]
    );
    const Renderer = ({
      item,
      step
    }: {
      item: HistoryLineItem;
      step: "first-step" | "last-step" | null;
    }): JSX.Element => {
      return (
        <TableRow
          className={clsx(
            classes.transactionItemRow,
            classes.borderLessRow,
            classes.paddedRow,
            step
          )}
        >
          <TableCell>
            <div className={classes.faceCell}>
              {!item.disabled && isCost && (
                <RemoveIcon
                  className={clsx(classes.signIcon, classes.redColor)}
                />
              )}
              {!item.disabled && !isCost && (
                <AddIcon
                  className={clsx(classes.signIcon, classes.greenColor)}
                />
              )}
              {item.disabled && <Close className={classes.signIcon} />}
              <div className="stepperLeftPart">
                <div className="stepperLeftUpperPart"></div>
                <div className="stepperLeftLowerPart"></div>
              </div>
              <div className="stepperRightPart"></div>
              <div>
                {z(item.item_type)} {item.disabled ? t("itemDisabled") : ""}
              </div>
            </div>
          </TableCell>
          <TableCell>{item.title}</TableCell>
          <TableCell>{item.in_time}</TableCell>
          <TableCell>{item.out_time}</TableCell>
          <TableCell align="right">
            {item.quantity.toFixed(2)} {item.unit}
          </TableCell>
          {showPrices && (
            <>
              <TableCell align="right">{niceAmount(item.unit_price)}</TableCell>
              <TableCell
                align="right"
                className={isCost ? classes.redColor : classes.greenColor}
              >
                {niceAmount(item.total)}
              </TableCell>
            </>
          )}
        </TableRow>
      );
    };

    return (
      <>
        <Renderer key="parent" item={itemParent} step="first-step" />
        {itemParent.item_type !== "SERVICEPACKAGE" &&
          sortedSubItems.map((item, idx) => (
            <Renderer
              key={idx}
              item={item}
              step={idx === sortedSubItems.length - 1 ? "last-step" : null}
            />
          ))}
      </>
    );
  }
);

interface TransactionGroupCorrectionsProps
  extends WithStyles<typeof TransactionGroupStyles> {
  timesheetRowApproval: TimeSheetRowApproval;
  serviceCategories: ServiceCategory[];
}

const correctionTypes = [
  "corrections",
  "contractor_payables",
  "customer_billables"
] as const;

const TransactionGroupCorrections = withStyles(TransactionGroupStyles)(
  (props: TransactionGroupCorrectionsProps) => {
    const { classes, timesheetRowApproval, serviceCategories } = props;
    const t = useTranslate("HistoryTransactionsDialog");

    const [expanded, setExpanded] = useState(false);
    const [materialsMap, setMaterialsMap] = useState<Map<number, Material>>();

    useEffect(() => {
      const uniqueMaterialIds = [
        ...new Set(
          correctionTypes.flatMap((correctionType) =>
            timesheetRowApproval[correctionType]?.materials
              ? Object.keys(timesheetRowApproval[correctionType].materials).map(
                  (materialId) => +materialId
                )
              : []
          )
        )
      ];
      if (uniqueMaterialIds.length > 0) {
        let isAlive = true;

        getMaterialsAPI({ filter__id__in: uniqueMaterialIds }).then(
          (materialsResponse) =>
            isAlive &&
            setMaterialsMap(
              new Map(
                materialsResponse.results.map((material) => [
                  material.id,
                  material
                ])
              )
            )
        );

        return () => {
          isAlive = false;
        };
      }
    }, [timesheetRowApproval]);

    const originalValues = {
      startTime: timesheetRowApproval.submittedwork.timesheetrow.start_time,
      endTime: timesheetRowApproval.submittedwork.timesheetrow.end_time,
      servicecategoryName:
        serviceCategories
          .filter(
            (servicecategory) =>
              timesheetRowApproval.submittedwork.timesheetrow
                .servicecategory === servicecategory.id
          )
          .map((sc) => sc.name)
          .join(", ") || "",
      materials: timesheetRowApproval.submittedwork.timesheetrow.materials
    };

    const correctionsByType = correctionTypes
      .filter((correctionType) => timesheetRowApproval[correctionType])
      .map((correctionType) => ({
        correctionType: correctionType,
        startTime: timesheetRowApproval[correctionType].start_time,
        endTime: timesheetRowApproval[correctionType].end_time,
        servicecategoryName: serviceCategories.find(
          (servicecategory) =>
            servicecategory.id ===
            timesheetRowApproval[correctionType].servicecategory
        )?.name,
        materials: timesheetRowApproval[correctionType].materials
      }));

    return (
      <div className={classes.correctionsContainer}>
        <Paper
          className={`${classes.correctionsHeader}`}
          variant="outlined"
          square
        >
          <div
            className={classes.pointer}
            onClick={() => setExpanded((_expanded) => !_expanded)}
          >
            {expanded ? (
              <KeyboardArrowUpIcon className={classes.vAlignMiddle} />
            ) : (
              <KeyboardArrowDownIcon className={classes.vAlignMiddle} />
            )}{" "}
            <span className={classes.vAlignMiddle}>Corrections</span>
          </div>
        </Paper>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Paper
            className={`${classes.correctionsBody}`}
            variant="outlined"
            square
          >
            {correctionsByType.map((corrections) => (
              <>
                {corrections.startTime && (
                  <div className={classes.correction}>
                    {t(corrections.correctionType)}:{" "}
                    <strong>{t("startLabel")}</strong> {t("correctionLabel")}{" "}
                    <strong>
                      {formatDateTime(
                        originalValues.startTime,
                        getDate(originalValues.startTime) ===
                          getDate(corrections.startTime)
                      )}
                    </strong>{" "}
                    {t("toLabel")}{" "}
                    <strong>
                      {formatDateTime(
                        corrections.startTime,
                        getDate(originalValues.startTime) ===
                          getDate(corrections.startTime)
                      )}
                    </strong>
                  </div>
                )}
                {corrections.endTime && (
                  <div className={classes.correction}>
                    {t(corrections.correctionType)}:{" "}
                    <strong>{t("stopLabel")}</strong> {t("correctionLabel")}{" "}
                    <strong>
                      {formatDateTime(
                        originalValues.endTime,
                        getDate(originalValues.endTime) ===
                          getDate(corrections.endTime)
                      )}
                    </strong>{" "}
                    {t("toLabel")}{" "}
                    <strong>
                      {formatDateTime(
                        corrections.endTime,
                        getDate(originalValues.endTime) ===
                          getDate(corrections.endTime)
                      )}
                    </strong>
                  </div>
                )}
                {corrections.materials &&
                  materialsMap &&
                  Object.keys(corrections.materials)
                    .map((id) => +id)
                    .filter(
                      (id) =>
                        !originalValues.materials[id] ||
                        (originalValues.materials[id] &&
                          originalValues.materials[id].amount !==
                            corrections.materials[id]?.amount)
                    )
                    .map((id) => (
                      <div className={classes.correction}>
                        {t(corrections.correctionType)}:{" "}
                        <strong>{materialsMap.get(id)?.name}</strong>{" "}
                        {t("correctionLabel")}{" "}
                        <strong>
                          {`${
                            originalValues.materials[id]
                              ? originalValues.materials[id].amount
                              : 0
                          } ${materialsMap.get(id)?.unit}`}
                        </strong>{" "}
                        {t("toLabel")}{" "}
                        <strong>{`${corrections.materials[id]?.amount || 0} ${
                          materialsMap.get(id)?.unit
                        }`}</strong>
                      </div>
                    ))}
                {corrections.servicecategoryName && (
                  <div className={classes.correction}>
                    {t(corrections.correctionType)}:{" "}
                    <strong>{t("serviceCategoryLabel")}</strong>{" "}
                    {t("correctionLabel")}{" "}
                    <strong>{originalValues.servicecategoryName}</strong>{" "}
                    {t("toLabel")}{" "}
                    <strong>{corrections.servicecategoryName}</strong>
                  </div>
                )}
              </>
            ))}
          </Paper>
        </Collapse>
      </div>
    );
  }
);

export interface TransactionGroupProps
  extends WithStyles<typeof TransactionGroupStyles> {
  expanded: boolean;
  toggleExpanded: () => void;
  historyRow: HistoryRow;
  showPrices: boolean;
  serviceCategories: ServiceCategory[];
}

const TransactionGroup = withStyles(TransactionGroupStyles)(
  (props: TransactionGroupProps) => {
    const {
      classes,
      expanded,
      toggleExpanded,
      historyRow,
      showPrices,
      serviceCategories
    } = props;
    const t = useTranslate("HistoryTransactionsDialog");
    const v = useTranslate("TimesheetApprovalPage");
    const [timesheetRowApproval, setTimesheetRowApproval] =
      useState<TimeSheetRowApproval>();

    useEffect(() => {
      if (expanded && !timesheetRowApproval) {
        let isAlive = true;
        getWorkApprovalAPI(historyRow.approval_id).then(
          (response) => isAlive && setTimesheetRowApproval(response)
        );

        return () => {
          isAlive = false;
        };
      }
    }, [expanded, historyRow.approval_id, timesheetRowApproval]);

    const GroupBanner = () => (
      <>
        <div>
          {t("totalCustomerLabel")}:&nbsp;
          {niceAmount(historyRow.customer_total)}
        </div>
        <div>
          {t("totalContractorLabel")}:&nbsp;
          {niceAmount(historyRow.contractor_total)}
        </div>
        <div className={classes.justifyEnd}>
          {t("profitLabel")}:&nbsp;{niceAmount(historyRow.profit)}
        </div>
      </>
    );

    return (
      <div
        key={historyRow.approval_id}
        className={
          historyRow.profit > 0
            ? classes.greenBack
            : historyRow.profit < 0
            ? classes.redBack
            : classes.yellowBack
        }
      >
        <div
          className={clsx(
            classes.taskRectangle,
            classes.boldRow,
            classes.clickable,
            "task-header",
            expanded ? "expanded-item" : ""
          )}
          onClick={toggleExpanded}
        >
          <KeyboardArrowDownIcon
            className={clsx(classes.collapsedIcon, "collapse-icon")}
          />
          <div>
            {v("classesObject")}: {historyRow.task_name} [
            {historyRow.businessarea_name}
            {" - "}
            {historyRow.service_categories.length ? (
              <>{historyRow.service_categories.join(", ")}</>
            ) : null}
            ]
          </div>
          {showPrices && !expanded ? <GroupBanner /> : <div />}
        </div>
        <div className={classes.expansionContainer}>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {timesheetRowApproval &&
              Object.keys(timesheetRowApproval?.corrections || []).length >
                0 && (
                <TransactionGroupCorrections
                  timesheetRowApproval={timesheetRowApproval}
                  serviceCategories={serviceCategories}
                />
              )}
            <TableContainer>
              <Table aria-label="transaction table">
                <TableHead>
                  <TableRow
                    className={clsx(
                      classes.headerRow,
                      classes.borderLessRow,
                      classes.tableHeaderRow,
                      classes.boldRow
                    )}
                  >
                    <TableCell>{t("typeLabel")}</TableCell>
                    <TableCell>{t("productInstanceLabel")}</TableCell>
                    <TableCell>{t("startLabel")}</TableCell>
                    <TableCell>{t("stopLabel")}</TableCell>
                    <TableCell align="right">{t("amountLabel")}</TableCell>
                    {showPrices && (
                      <>
                        <TableCell align="right">{t("rateLabel")}</TableCell>
                        <TableCell align="right">{t("totalLabel")}</TableCell>
                      </>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyRow.customer_lines.map(({ key, items }) =>
                    items.map((item) => (
                      <TransactionGroupTask
                        key={`customer-${key}-${item.workapproval}`}
                        isCost={false}
                        itemParent={item}
                        tz_name={historyRow.tz_name}
                        showPrices={showPrices}
                      />
                    ))
                  )}
                  {historyRow.contractor_lines.map(({ key, items }) =>
                    items.map((item) => (
                      <TransactionGroupTask
                        key={`contractor-${key}-${item.workapproval}`}
                        isCost={true}
                        itemParent={item}
                        tz_name={historyRow.tz_name}
                        showPrices={showPrices}
                      />
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            {showPrices ? (
              <div
                className={clsx(
                  classes.taskRectangle,
                  classes.boldRow,
                  classes.clickable,
                  "task-footer"
                )}
                onClick={toggleExpanded}
              >
                <div></div>
                <div></div>
                <GroupBanner />
              </div>
            ) : null}
          </Collapse>
        </div>
      </div>
    );
  }
);

export default TransactionGroup;
