import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import {
  BaseMaterial,
  Contract,
  Material,
  SelectFieldOption
} from "../../../redux/types";
import EditableTextField from "../../EditableTextField";
import EditableCheckBoxField from "../../EditableCheckBoxField";
import { niceDate } from "../../FormatHelpers";
import ResourceLink from "../../links/ResourceLink";
import {
  getBaseMaterialAPI,
  getMaterialAPI,
  getMaterialSuppliersAPI,
  patchMaterialAPI
} from "../../../services/api-declaration";
import * as Yup from "yup";
import SimpleImageUpload from "../../imageUploadComponent/SimpleImageUpload";
import LockIcon from "@material-ui/icons/Lock";
import EditableAutoCompleteSelect from "../../EditableAutoCompleteSelect";
import ContractItemTask from "../ContractItemTask";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    editButton: {
      marginLeft: 20
    },
    pageNotFound: {
      marginTop: 20,
      padding: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    logo: {
      maxWidth: 300,
      maxHeight: 300
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface MaterialProps extends WithStyles<typeof styles> {
  tabId: number;
  material: Material;
  contract: Contract | undefined;
  businessAreaNames: Map<number, string>;
}

const MaterialView: React.FC<MaterialProps> = (props) => {
  const { classes, tabId, contract, material: initialMaterial, businessAreaNames } = props;
  const t = useTranslate("MaterialPage");
  const t3 = useTranslate("ValidationErrorMessages");

  const [expanded, setExpanded] = useState(false);
  const [material, setMaterial] = useState<Material>(initialMaterial);
  const [baseMaterial, setBaseMaterial] = useState<BaseMaterial>();
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [supplierOptions, setSupplierOptions] = useState<SelectFieldOption[]>(
    []
  );
  const updateResourceState = (value: Material) => setMaterial(value);

  const patchUrl = `contracts/materials/${initialMaterial.id}/`;

  useEffect(() => {
    if (material) store.dispatch(setTitle(material.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [material]);

  useEffect(() => {
    let alive = true;

    if (contract?.id) {
      (async () => {
        const material = initialMaterial;
        const baseMaterialResponse = await getBaseMaterialAPI(material.base);

        if (alive) {
          setBaseMaterial(baseMaterialResponse);
        }
      })();
    }
    return () => {
      alive = false;
    };
  }, [contract?.id, initialMaterial]);

  useEffect(() => {
    let isAlive = true;
    (async () => {
      const response = await getMaterialSuppliersAPI();

      if (isAlive) {
        setSupplierOptions(
          response.results.map((supplier) => ({
            label: supplier.name,
            value: supplier.id
          }))
        );
      }
    })();

    return () => {
      isAlive = false;
    };
  }, []);

  const materialIsLocked =
    contract?.locked_items["materials"].includes(initialMaterial.id) || false;

  const addImage = async (image: string) => {
    setImageLoading(true);
    const response = await patchMaterialAPI(initialMaterial.id, {
      image: image
    });

    updateResourceState(response);
    setImageLoading(false);
  };

  const deleteImage = async () => {
    setImageLoading(true);
    const response = await patchMaterialAPI(initialMaterial.id, {
      image: null
    });

    updateResourceState(response);
    setImageLoading(false);
  };

  if (!material) return <LoadingSpinnerPaper />;

  return material.contract !== contract?.id ? (
    <Paper className={classes.pageNotFound}>
      <Typography variant="h6">{t("pageNotFoundTitle")}</Typography>
    </Paper>
  ) : (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow key={0}>
                  <TableCell align="left" className={classes.bold} colSpan={2}>
                    <SimpleImageUpload
                      handleImageSave={addImage}
                      handleImageDelete={deleteImage}
                      image={material.image}
                      alt={material.name}
                      isLoading={imageLoading}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={1}>
                  <TableCell align="left" colSpan={2}>
                    <Typography variant="h5">
                      {contract?.name}: <b>{material.name}</b>{" "}
                      {materialIsLocked && (
                        <Tooltip title={t("materialLockedLabel")}>
                          <LockIcon />
                        </Tooltip>
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {materialIsLocked ? (
                      material.name
                    ) : (
                      <EditableTextField
                        field="name"
                        initValue={material.name}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .min(1, t3("minLengthError"))
                          .max(255, t3("max255LengthError"))
                          .required(t3("requiredError"))}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell align="left" className={classes.bold}>
                    {t("contractLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {contract && (
                      <ResourceLink
                        label={contract.name}
                        id={contract.id}
                        url="contracts/contracts"
                      />
                    )}
                  </TableCell>
                </TableRow>
                {material.price !== null && (
                  <TableRow key={4}>
                    <TableCell align="left" className={classes.bold}>
                      {t("priceLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {materialIsLocked ? (
                        material.price
                      ) : (
                        <EditableTextField
                          isPrice
                          field="price"
                          initValue={material.price}
                          url={patchUrl}
                          updateResourceState={updateResourceState}
                          number={true}
                          validation={Yup.number()
                            .typeError(t3("numberRequiredError"))
                            .min(0, t3("minZeroError"))
                            .max(10000000, t3("max10000000NumberError"))
                            .required(t3("requiredError"))}
                        />
                      )}{" "}
                      kr {material.unit ? `/ ${material.unit}` : ""}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow key={5}>
                  <TableCell align="left" className={classes.bold}>
                    {t("baseMaterialLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {baseMaterial && (
                      <ResourceLink
                        url="contracts/basematerials"
                        label={baseMaterial.name}
                        id={baseMaterial.id}
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow key={0}>
                    <TableCell align="left" className={classes.bold}>
                      {t("supplierLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableAutoCompleteSelect
                        resourceUrl="companies/companies"
                        url={patchUrl}
                        field="supplier"
                        options={supplierOptions}
                        label={t("supplierLabel")}
                        updateResourceState={updateResourceState}
                        initValue={material.supplier}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={1}>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="notes"
                        initValue={material.notes}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={2}>
                    <TableCell align="left" className={classes.bold}>
                      {t("displayLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="display"
                        label={t("displayLabel")}
                        initValue={material.display}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell align="left" className={classes.bold}>
                      {t("debitsDepotLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="debits_depot"
                        label={t("debitsDepotLabel")}
                        initValue={material.debits_depot}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell align="left" className={classes.bold}>
                      {t("coefficientLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="coefficient"
                        initValue={material.coefficient}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100, t3("max100NumberError"))
                          .nullable()
                          .notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={5}>
                    <TableCell align="left" className={classes.bold}>
                      {t("orderByLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="order_by"
                        initValue={material.order_by}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={6}>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(material.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow key={7}>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(material.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContractItemTask taskId={material.task} businessAreaNames={businessAreaNames}/>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(MaterialView);
