import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  WithStyles
} from "@material-ui/core";
import { useTranslate } from "../../../services/appLanguageService";
import { Add, DeleteOutline, Edit } from "@material-ui/icons";
import {
  Company,
  InvoiceRecipient,
  InvoiceRecipientForm,
  SelectFieldOption
} from "../../../redux/types";
import { useEffect, useState } from "react";
import {
  createInvoiceRecipientAPI,
  deleteInvoiceRecipientAPI,
  getInvoiceRecipientsByIdAPI,
  getPersonsByCompanyAPI,
  updateInvoiceRecipientAPI
} from "../../../services/api-declaration";
import { Autocomplete } from "@material-ui/lab";
import ConfirmationDialog from "../../confirmationDialog/ConfirmationDialog";
import * as Yup from "yup";
import ResourceLink from "../../links/ResourceLink";
import { EnumStrings } from "../../../redux/strings";
import LoadingSpinner from "../../LoadingSpinner";

const styles = (theme: any) =>
  createStyles({
    paper: {
      marginTop: 20,
      paddingTop: theme.spacing(1)
    },
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& h6": {
        paddingLeft: theme.spacing(2),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1)
      }
    },
    addButton: {
      marginRight: theme.spacing(2)
    },
    bold: {
      fontWeight: "bold"
    },
    title: {
      padding: 20
    },
    content: {
      "& > div": {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    },
    root: {
      marginTop: 20
    },
    options: {
      display: "flex"
    },
    dense: {
      padding: 6,
      "&:last-child": {
        paddingRight: 7
      },
      "&:first-child": {
        paddingLeft: 7
      }
    },
    container: {
      overflow: "clip"
    },
    field: {
      marginBottom: 20
    }
  });

const invoiceRecipientTypes = [
  EnumStrings.MAIN,
  EnumStrings.CC,
  EnumStrings.BCC
];

interface InvoiceRecipientModalProps extends WithStyles<typeof styles> {
  personOptions: SelectFieldOption[];
  onClose: () => void;
  company: Company;
  invoiceRecipient?: InvoiceRecipient;
  reloadTable: () => void;
  addInvoiceRecipientIdToState?: (newId: number) => void;
}

const InvoiceRecipientModal: React.FC<InvoiceRecipientModalProps> = (props) => {
  const {
    personOptions,
    onClose,
    classes,
    company,
    invoiceRecipient,
    reloadTable,
    addInvoiceRecipientIdToState
  } = props;

  const mode = invoiceRecipient ? "update" : "create";

  const t = useTranslate("InvoiceRecipientsPage");
  const t2 = useTranslate("InvoiceRecipients");
  const t3 = useTranslate("ValidationErrorMessages");
  const [formValidation, setFormValidation] = useState<{
    [key: string]: {
      error: boolean;
      errorText: string;
    };
  }>({
    email: { error: false, errorText: "" },
    type: { error: false, errorText: "" },
    person: { error: false, errorText: "" }
  });
  const [invoicerecipientForm, setInvoicerecipientForm] =
    useState<InvoiceRecipientForm>({
      email: "",
      type: "MAIN",
      person: null,
      company: company.id
    });

  const clearDialog = () => {
    setInvoicerecipientForm({
      email: "",
      type: "MAIN",
      person: null,
      company: company.id
    });
    setFormValidation({});
    onClose();
  };

  const createBaseaddon = async () => {
    createInvoiceRecipientAPI({
      email: invoicerecipientForm.email,
      type: invoicerecipientForm.type,
      person: invoicerecipientForm.person,
      company: invoicerecipientForm.company
    }).then((invoiceRecipientResponse: InvoiceRecipient) => {
      if (addInvoiceRecipientIdToState) {
        addInvoiceRecipientIdToState(invoiceRecipientResponse.id);
      }
      clearDialog();
      reloadTable();
    });
  };

  const updateBaseaddon = async () => {
    if (invoiceRecipient) {
      updateInvoiceRecipientAPI(invoiceRecipient.id, {
        email: invoicerecipientForm.email,
        type: invoicerecipientForm.type,
        person:
          invoicerecipientForm.person !== 0
            ? invoicerecipientForm.person
            : null,
        company: invoicerecipientForm.company
      }).then(() => {
        clearDialog();
        reloadTable();
      });
    }
  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .min(1, t3("minLengthError"))
      .max(150, t3("max150LengthError"))
      .email(t3("emailInvalidError")),
    type: Yup.string().oneOf(
      invoiceRecipientTypes,
      t3("notValidEnumValueError")
    ),
    person: Yup.number().nullable().notRequired()
  });

  useEffect(() => {
    if (invoiceRecipient) {
      setInvoicerecipientForm({
        email: invoiceRecipient.email,
        type: invoiceRecipient.type,
        person: invoiceRecipient.person,
        company: invoiceRecipient.company
      });
    }
  }, [invoiceRecipient]);

  const validate = (value: string, field: keyof InvoiceRecipientForm) => {
    schema
      .validate({ [field]: value })
      .then((valid: any) => {
        if (
          formValidation[field].error &&
          formValidation[field].errorText !== ""
        ) {
          setFormValidation((fv) => ({
            ...fv,
            [field]: {
              error: false,
              errorText: ""
            }
          }));
        }
      })
      .catch((e: Yup.ValidationError) => {
        setFormValidation((fv) => ({
          ...fv,
          [field]: {
            error: true,
            errorText: e.errors?.join() || ""
          }
        }));
      });
  };

  const formInvalid =
    Object.values(formValidation)
      .map((fv) => fv.error)
      .includes(true) || invoicerecipientForm.email === "";

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>
        {mode === "create"
          ? t("addInvoiceRecipientTitle")
          : t("updateInvoiceRecipientTitle")}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <form className={classes.root} autoComplete="off">
          <TextField
            className={classes.field}
            data-cy="invoicerecipient-form-email"
            defaultValue={invoicerecipientForm.email}
            label={t("emailLabel")}
            fullWidth
            margin="normal"
            onBlur={(event) => {
              validate(event.target.value, "email");
              setInvoicerecipientForm((f) => ({
                ...f,
                email: event.target.value
              }));
            }}
            error={formValidation.email?.error}
            helperText={formValidation.email?.errorText}
          />
          <FormControl fullWidth>
            <InputLabel id="invoicerecipient-type">{t("typeLabel")}</InputLabel>
            <Select
              data-cy="invoicerecipient-form-type"
              labelId="invoicerecipient-type"
              className={classes.field}
              value={invoicerecipientForm.type}
              onChange={(event) => {
                validate(event.target.value as "MAIN" | "CC" | "BCC", "type");
                setInvoicerecipientForm((f) => ({
                  ...f,
                  type: event.target.value as "MAIN" | "CC" | "BCC"
                }));
              }}
              error={formValidation.type?.error}
            >
              {invoiceRecipientTypes
                .map((type) => ({
                  label: t2(type),
                  value: type
                }))
                .map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Autocomplete
            data-cy="invoicerecipient-form-person"
            className={classes.field}
            value={
              personOptions.find(
                (p) => p.value === invoicerecipientForm.person
              ) || null
            }
            onChange={(event: any, value: SelectFieldOption | null) =>
              setInvoicerecipientForm((f) => ({
                ...f,
                person: value?.value || null
              }))
            }
            renderInput={(params: any) => (
              <TextField {...params} label={t("personLabel")} />
            )}
            getOptionLabel={(o) => o.label}
            options={personOptions}
          />
          <DialogActions>
            <Button
              variant="contained"
              className={classes.addButton}
              onClick={clearDialog}
            >
              {t("cancelLabel")}
            </Button>
            <Button
              data-cy="invoicerecipient-form-send-button"
              disabled={formInvalid}
              variant="contained"
              className={classes.addButton}
              onClick={() => {
                if (mode === "create") {
                  createBaseaddon();
                } else {
                  updateBaseaddon();
                }

                onClose();
              }}
            >
              {mode === "create" ? t("addLabel") : t("updateLabel")}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

interface InvoiceRecipientsProps extends WithStyles<typeof styles> {
  company: Company;
}

const InvoiceRecipients: React.FC<InvoiceRecipientsProps> = (props) => {
  const { classes, company } = props;

  const t = useTranslate("InvoiceRecipientsPage");
  const t2 = useTranslate("InvoiceRecipients");
  const [modal, setModal] = useState<
    | { m: "create" }
    | { m: "delete"; invoiceRecipientId: number }
    | { m: "update"; invoiceRecipient: InvoiceRecipient }
  >();
  const [invoiceRecipientIds, setInvoiceRecipientIds] = useState<number[]>(
    company.invoicerecipients
  );
  const [invoiceRecipients, setInvoiceRecipients] = useState<
    InvoiceRecipient[]
  >([]);
  const [personOptions, setPersonOptions] = useState<SelectFieldOption[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState(true);

  const reloadTable = () => {
    setReload(true);
    setIsLoading(true);
  };

  const addInvoiceRecipientIdToState = (newId: number) =>
    setInvoiceRecipientIds((ids) => [...ids, newId]);

  useEffect(() => {
    if (reload) {
      let isAlive = true;
      setIsLoading(true);
      getInvoiceRecipientsByIdAPI(invoiceRecipientIds).then(
        (invoicerecipientsResponse) => {
          if (isAlive) {
            setInvoiceRecipients(invoicerecipientsResponse.results);
          }
          setIsLoading(false);
          setReload(false);
        }
      );

      return () => {
        isAlive = false;
      };
    }
  }, [invoiceRecipientIds, reload]);

  useEffect(() => {
    let isAlive = true;

    getPersonsByCompanyAPI(company.id).then(
      (personsResponse) =>
        isAlive &&
        setPersonOptions(
          personsResponse.results.map((person) => ({
            label: `${person.first_name} ${person.last_name}`,
            value: person.id
          }))
        )
    );

    return () => {
      isAlive = false;
    };
  }, [company.id]);

  const handleClose = () => setModal(undefined);

  const handleDeleteInvoicerecipient = (invoiceRecipientId: number) => {
    setIsLoading(true);
    deleteInvoiceRecipientAPI(invoiceRecipientId).then(() => {
      setInvoiceRecipientIds((ids) =>
        ids.filter((id) => id !== invoiceRecipientId)
      );
      setReload(true);
      setModal(undefined);
      setIsLoading(false);
    });
  };

  return (
    <>
      <Paper className={classes.paper}>
        <div className={classes.header}>
          <Typography
            className={classes.title}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {t("invoiceRecipientsTitle")}
          </Typography>
          <Button
            data-cy="add-invoicerecipient"
            variant="contained"
            startIcon={<Add />}
            className={classes.addButton}
            onClick={() => setModal({ m: "create" })}
          >
            {t("addLabel")}
          </Button>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <TableContainer className={classes.container}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell className={`${classes.bold} ${classes.dense}`}>
                    {t("emailLabel")}
                  </TableCell>
                  <TableCell className={`${classes.bold} ${classes.dense}`}>
                    {t("typeLabel")}
                  </TableCell>
                  <TableCell className={`${classes.bold} ${classes.dense}`}>
                    {t("personLabel")}
                  </TableCell>
                  <TableCell className={`${classes.bold} ${classes.dense}`}>
                    {t("optionsLabel")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody data-cy="invoicerecipients-table-body">
                {invoiceRecipients.map((invoicerecipient) => {
                  const person = personOptions.find(
                    (option) => option.value === invoicerecipient.person
                  );

                  return (
                    <TableRow key={invoicerecipient.id}>
                      <TableCell
                        className={classes.dense}
                        data-cy="invoicerecipient-email"
                      >
                        {invoicerecipient.email}
                      </TableCell>
                      <TableCell className={classes.dense}>
                        {t2(invoicerecipient.type)}
                      </TableCell>
                      <TableCell className={classes.dense}>
                        {person && (
                          <ResourceLink
                            label={person.label}
                            id={person?.value}
                            url={"auth/persons"}
                          />
                        )}
                      </TableCell>
                      <TableCell className={classes.dense}>
                        <div className={classes.options}>
                          <IconButton
                            data-cy="edit-invoicerecipient"
                            size="small"
                            onClick={() =>
                              setModal({
                                m: "update",
                                invoiceRecipient: invoicerecipient
                              })
                            }
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            data-cy="delete-invoicerecipient"
                            size="small"
                            onClick={() =>
                              setModal({
                                m: "delete",
                                invoiceRecipientId: invoicerecipient.id
                              })
                            }
                          >
                            <DeleteOutline />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
      {modal?.m === "create" && invoiceRecipients && (
        <InvoiceRecipientModal
          personOptions={personOptions}
          onClose={handleClose}
          company={company}
          classes={classes}
          reloadTable={reloadTable}
          addInvoiceRecipientIdToState={addInvoiceRecipientIdToState}
        />
      )}
      {modal?.m === "update" && modal?.invoiceRecipient && (
        <InvoiceRecipientModal
          personOptions={personOptions}
          onClose={handleClose}
          company={company}
          classes={classes}
          invoiceRecipient={modal.invoiceRecipient}
          reloadTable={reloadTable}
        />
      )}
      {modal?.m === "delete" && modal?.invoiceRecipientId && (
        <ConfirmationDialog
          title={t("confirmDeleteTitle")}
          description={t("confirmDeleteText")}
          onSuccess={() =>
            handleDeleteInvoicerecipient(modal.invoiceRecipientId)
          }
          onClose={handleClose}
          open
        />
      )}
    </>
  );
};

export default withStyles(styles)(InvoiceRecipients);
