import React, { useEffect } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  FormikSubmitDispatchProps,
  CustomerobjectForm,
  Customerobject,
  mainRootState,
  SelectFieldOption,
  TabComponentProps
} from "../../../redux/types";
import CustomerobjectNewEditForm from "../../../components/contracts/customerobjects/CustomerobjectNewEditForm";
import * as RestActions from "../../../redux/sagas/restActions";
import { timeToDate } from "../../../components/FormatHelpers";
import {
  RestStrings,
  ReduxActionStrings,
  EnumStrings
} from "../../../redux/strings";
import { format } from "date-fns";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";
import { mapTabRouteProps } from "../../../helpers/routesHelper";

interface EditCustomerobjectContainerProps
  extends FormikSubmitDispatchProps<CustomerobjectForm>,
    TabComponentProps<"customerobjectId"> {
  customerobject: Customerobject;
  loadCustomerobject: (customerobjectId: number) => void;
  loadCustomerPersons: (companyId: number) => void;
  personOptions: SelectFieldOption[];
  loadingCustomerobject: boolean;
}

interface mapDispatchToPropsProps
  extends FormikSubmitDispatchProps<Customerobject | CustomerobjectForm> {
  loadCustomerobject: (customerobjectId: number) => void;
  loadCustomerPersons: (companyId: number) => void;
}

const EditCustomerobjectContainer: React.FC<
  EditCustomerobjectContainerProps
> = (props) => {
  const {
    loadCustomerobject,
    customerobject,
    routeParams: { customerobjectId },
    loadingCustomerobject,
    loadCustomerPersons,
    personOptions,
    handleSubmit
  } = props;
  useEffect(
    () => loadCustomerobject(customerobjectId),
    [loadCustomerobject, customerobjectId]
  );
  useEffect(
    () => loadCustomerPersons(customerobject.customer),
    [loadCustomerPersons, customerobject.customer]
  );

  return loadingCustomerobject ? (
    <LoadingSpinnerPaper />
  ) : (
    <CustomerobjectNewEditForm
      customerobject={customerobject}
      personOptions={personOptions}
      handleSubmit={handleSubmit}
      loadCustomerPersons={loadCustomerPersons}
      {...mapTabRouteProps(props)}
    />
  );
};

const mapDispatchToProps = (
  dispatch: Dispatch,
  ownProps: any
): mapDispatchToPropsProps => ({
  handleSubmit: (values, actions) => {
    const checkedNullValues = {
      ...values,
      weekday_opening: values.weekday_opening
        ? format(timeToDate(values.weekday_opening), "HH:mm")
        : null,
      weekday_closing: values.weekday_closing
        ? format(timeToDate(values.weekday_closing), "HH:mm")
        : null,
      saturday_opening: values.saturday_opening
        ? format(timeToDate(values.saturday_opening), "HH:mm")
        : null,
      saturday_closing: values.saturday_closing
        ? format(timeToDate(values.saturday_closing), "HH:mm")
        : null,
      sunday_opening: values.sunday_opening
        ? format(timeToDate(values.sunday_opening), "HH:mm")
        : null,
      sunday_closing: values.sunday_closing
        ? format(timeToDate(values.sunday_closing), "HH:mm")
        : null
    };
    actions.setSubmitting(false);
    dispatch(
      RestActions.restRequestWithData(
        ownProps.tabId,
        RestStrings.PATCH,
        ReduxActionStrings.CUSTOMEROBJECT,
        `contracts/customerobjects/${
          (checkedNullValues as Customerobject).id
        }/`,
        checkedNullValues,
        `/contracts/customerobjects/${
          (checkedNullValues as Customerobject).id
        }?same_tab=true`
      )
    );
  },
  loadCustomerobject: (customerobjectId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.CUSTOMEROBJECT,
        `contracts/customerobjects/${customerobjectId}/`
      )
    );
  },
  loadCustomerPersons: (companyId: number) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.PERSONS,
        `auth/persons/?order__first_name=asc&filter__company_id=${companyId}&filter__status=ACTIVE`
      )
    );
  }
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  customerobject:
    state.tabStates[ownProps.tabId].customerObjects.customerobject
      .customerobject,
  companyOptions: state.tabStates[ownProps.tabId].companies.companies.companies

    .filter((company) => company.company_type === EnumStrings.CUSTOMER)
    .map((company) => ({
      label: company.name,
      value: company.id
    })),
  personOptions: state.tabStates[ownProps.tabId].persons.persons.results.map(
    (person) => ({
      label: `${person.first_name} ${person.last_name || ""}`,
      value: person.id
    })
  ),
  loadingCustomerobject:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingCustomerobject
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCustomerobjectContainer);
