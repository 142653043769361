import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Collapse,
  WithStyles,
  withStyles
} from "@material-ui/core";
import { Fragment, useEffect, useRef, useState } from "react";
import {
  Service,
  ServiceCategory,
  RouteInstance,
  RouteInstanceStatus,
  Task,
  TimeSheet,
  DeclinedData,
  AcceptedData
} from "../../../redux/types";
import {
  getTaskNamesAPI,
  getContractorNamesAPI,
  getServiceCategoryNamesAPI,
  getServicesByIdsAPI,
  getTimesheetsAPI,
  getPersonsAPI
} from "../../../services/api-declaration";
import { niceDate } from "../../FormatHelpers";
import RouteInstanceDetails from "./RouteInstanceDetails";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { createStyles } from "@material-ui/core/styles";
import { useTranslate } from "../../../services/appLanguageService";
import clsx from "clsx";

const styles = (theme: any) =>
  createStyles({
    table: {
      minWidth: 500,
      [theme.breakpoints.down(600)]: {
        width: "100%"
      }
    },
    boldRow: {
      "& > *": {
        color: "#000",
        fontWeight: "bold"
      }
    },
    expandedRow: {
      "& > *": {
        color: "#000",
        fontWeight: "bold",
        background: "#e3e3e3"
      }
    },
    cellPadRow: {
      "& > *": { padding: "6px" }
    },
    root: {
      "& > *": {
        position: "relative",
        borderBottom: "unset"
      }
    },
    expandButton: { width: theme.spacing(2) },
    pointer: { cursor: "pointer" },
    scrollHelperDummy: {
      position: "relative",
      top: -100,
      left: -theme.spacing(2)
    },
    deadlineText: {
      marginRight: theme.spacing(1)
    }
  });

interface RouteInstancesTableProps extends WithStyles<typeof styles> {
  routeinstances: RouteInstance[];
}

const RouteInstancesTable: React.FC<RouteInstancesTableProps> = (props) => {
  const { classes } = props;

  const [tasks, setTasks] = useState<Task[]>([]);
  const [companies, setCompanies] = useState<{ id: number; name: string }[]>(
    []
  );
  const [services, setServices] = useState<Service[]>([]);
  const [serviceCategories, setServiceCategories] = useState<ServiceCategory[]>(
    []
  );
  const [timesheets, setTimesheets] = useState<TimeSheet[]>([]);
  const [declinedData, setDeclinedData] = useState<DeclinedData>({});
  const [acceptedByData, setAcceptedByData] = useState<AcceptedData>({});

  const scrollElementRef = useRef<HTMLDivElement | null>(null);
  const [expandedRoute, setExpandedRoute] = useState<RouteInstance | undefined>(
    undefined
  );

  const t = useTranslate("RouteInstancesPage");
  const t3 = useTranslate("RouteInstanceEnums");

  const handleRowExpansion = (
    shouldOpen: boolean,
    routeinstance: RouteInstance
  ) => {
    scrollElementRef.current = null;
    if (shouldOpen) {
      setExpandedRoute(routeinstance);
    } else {
      setExpandedRoute(undefined);
    }
  };

  useEffect(() => {
    (async () => {
      const [
        taskNamesResponse,
        contractorNamesResponse,
        serviceCategoryNamesResponse
      ] = await Promise.all([
        getTaskNamesAPI(),
        getContractorNamesAPI(),
        getServiceCategoryNamesAPI()
      ]);

      setTasks(taskNamesResponse);
      setCompanies(contractorNamesResponse.results);
      setServiceCategories(serviceCategoryNamesResponse.results);
    })();
  }, []);

  const getUniqueServiceIdsFromRouteInstances = (
    routeinstances: RouteInstance[]
  ) => [
    ...new Set(
      routeinstances.flatMap((routeinstance) =>
        routeinstance.participants.flatMap((participant) =>
          [participant.service].concat(
            Object.keys(participant.accompanying_people).map((n) => parseInt(n))
          )
        )
      )
    )
  ];

  const getAcceptedPersonsFromTimesheets = async (timesheets: TimeSheet[]) => {
    let tmpAcceptedData: AcceptedData = {};

    const acceptingPersonIds = timesheets
      .map((timesheet) => timesheet.person)
      .filter((id) => id !== null) as number[];

    if (acceptingPersonIds.length > 0) {
      const { results } = await getPersonsAPI({
        filter__id__in: acceptingPersonIds
      });

      timesheets.forEach((timesheet) => {
        if (timesheet.person && timesheet.route_participant !== null) {
          const routeparticipant = timesheet.route_participant;

          const acceptingPerson = results.find(
            (acceptingPerson) => timesheet.person === acceptingPerson.id
          );

          if (acceptingPerson) {
            tmpAcceptedData = {
              ...tmpAcceptedData,
              [routeparticipant]: acceptingPerson
            };
          }
        }
      });
    }

    return tmpAcceptedData;
  };

  const getDeclinedPersonsFromTimesheets = async (timesheets: TimeSheet[]) => {
    let tmpDeclinedData: DeclinedData = {};

    const decliningPersonIds = timesheets.flatMap(
      (timesheet) => timesheet.declining_persons
    );

    if (decliningPersonIds.length > 0) {
      const { results } = await getPersonsAPI({
        filter__id__in: decliningPersonIds
      });

      timesheets.forEach((timesheet) => {
        if (
          timesheet.declining_persons.length > 0 &&
          timesheet.route_participant !== null
        ) {
          const routeparticipant = timesheet.route_participant;

          tmpDeclinedData = {
            ...tmpDeclinedData,
            [routeparticipant]: results.filter((decliningPerson) =>
              timesheet.declining_persons.includes(decliningPerson.id)
            )
          };
        }
      });
    }

    return tmpDeclinedData;
  };

  useEffect(() => {
    (async () => {
      const serviceIds = getUniqueServiceIdsFromRouteInstances(
        props.routeinstances
      ).filter((serviceId) => serviceId !== null);

      const routeinstanceIds = props.routeinstances.map(
        (routeinstance) => routeinstance.id
      );

      const [servicesResponse, timesheetsResponse] = await Promise.all([
        getServicesByIdsAPI(serviceIds),
        getTimesheetsAPI({
          filter__routeinstance_id__in: routeinstanceIds
        })
      ]);

      setServices(servicesResponse.results);
      setTimesheets(timesheetsResponse.results);
    })();
  }, [props.routeinstances]);

  useEffect(() => {
    (async () => {
      const [declinedResponse, acceptedResponse] = await Promise.all([
        getDeclinedPersonsFromTimesheets(timesheets),
        getAcceptedPersonsFromTimesheets(timesheets)
      ]);

      setDeclinedData(declinedResponse);
      setAcceptedByData(acceptedResponse);
    })();
  }, [timesheets]);

  const instanceStatusColors: { [key in RouteInstanceStatus]: string } = {
    CANCELED: "#9c9c9c",
    DRAFT: "#9c9c9c",
    FULLY_ACCEPTED: "#e69500",
    IN_PROGRESS: "#c5c527",
    PARTLY_ACCEPTED: "#e6c000",
    PARTLY_DECLINED: "#fc3503",
    PARTLY_STARTED: "#c5c527",
    RETROSPECTIVE: "#9c9c9c",
    SUBMITTED: "#008000",
    WAITING_FOR_ACCEPT: "#e6c000",
    COMPLETED: "#0f0980"
  };

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={clsx(classes.boldRow, classes.cellPadRow)}>
            <TableCell></TableCell>
            <TableCell>{t("titleHeading")}</TableCell>
            <TableCell>{t("tasksHeading")}</TableCell>
            <TableCell>{t("operatorsHeading")}</TableCell>
            <TableCell>{t("serviceCategoriesHeading")}</TableCell>
            <TableCell>{t("statusHeading")}</TableCell>
            <TableCell>{t("deadlineHeading")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.routeinstances.length === 0 ? (
            <TableRow>
              <TableCell colSpan={10}>{t("noItemsText")}</TableCell>
            </TableRow>
          ) : (
            props.routeinstances.map((routeinstance) => {
              const isSummaryExpanded =
                expandedRoute && expandedRoute.id === routeinstance.id;

              return (
                <Fragment key={routeinstance.id}>
                  <TableRow
                    className={clsx(
                      classes.root,
                      classes.cellPadRow,
                      isSummaryExpanded && classes.expandedRow,
                      classes.pointer
                    )}
                    onClick={() =>
                      handleRowExpansion(!isSummaryExpanded, routeinstance)
                    }
                  >
                    <TableCell className={clsx(classes.expandButton)}>
                      <IconButton aria-label="expand row" size="small">
                        {isSummaryExpanded ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>{routeinstance.title}</TableCell>
                    <TableCell>{routeinstance.rows.length}</TableCell>
                    <TableCell>{routeinstance.participants.length}</TableCell>
                    <TableCell>
                      {serviceCategories
                        .filter((_servicecategory) =>
                          routeinstance.servicecategories.includes(
                            _servicecategory.id
                          )
                        )
                        .map(
                          (servicecategory, index, _servicecategories) =>
                            `${servicecategory.name}${
                              index + 1 !== _servicecategories.length
                                ? ", "
                                : ""
                            }`
                        )}
                    </TableCell>
                    <TableCell
                      style={{
                        color: instanceStatusColors[routeinstance.status]
                      }}
                    >
                      {t3(routeinstance.status)}
                    </TableCell>
                    <TableCell>
                      {routeinstance.deadline === "ASAP" ||
                      routeinstance.deadline === "WAIT_FOR_CALL" ? (
                        t3(routeinstance.deadline)
                      ) : (
                        <span>
                          <span className={classes.deadlineText}>
                            {t3(routeinstance.deadline)}:
                          </span>
                          <span>{niceDate(routeinstance.time)}</span>
                        </span>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow className={classes.root}>
                    <TableCell
                      style={{
                        paddingBottom: 0,
                        paddingTop: 0
                      }}
                      colSpan={10}
                    >
                      <Collapse
                        in={isSummaryExpanded}
                        timeout="auto"
                        unmountOnExit
                      >
                        {isSummaryExpanded && (
                          <div
                            className={classes.scrollHelperDummy}
                            ref={scrollElementRef}
                          >
                            {/* scroll helper */}
                          </div>
                        )}
                        <RouteInstanceDetails
                          routeinstance={routeinstance}
                          tasks={tasks}
                          companies={companies}
                          servicecategories={serviceCategories}
                          services={services}
                          declinedData={declinedData}
                          acceptedByData={acceptedByData}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </Fragment>
              );
            })
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default withStyles(styles)(RouteInstancesTable);
