import React, { useEffect, useState } from "react";
import {
  Field,
  FormikProps,
  FieldArrayRenderProps,
  FieldHookConfig,
  useField,
  FieldProps
} from "formik";
import {
  debounce,
  IconButton,
  TableCell,
  TableRow,
  Typography,
  WithStyles,
  makeStyles,
  FormGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Button,
  FormHelperText
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import {
  PersonForm,
  RouteInstance,
  RouteInstanceForm,
  RouteParticipantForm,
  RoutePlan,
  SelectFieldOption,
  Service
} from "../../../redux/types";
import { AutoCompleteSelect } from "../../AutoCompleteSelect";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslate } from "../../../services/appLanguageService";
import { BlurTextField } from "./BlurTextField";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import { getPersonsByCompanyAPI } from "../../../services/api-declaration";

const useAccompanyingPeopleEditorStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: theme.spacing(1),
      width: "100%"
    },
    entryContainer: {
      display: "flex",
      marginBottom: theme.spacing(1)
    },
    addContainer: {},
    selectService: {
      width: "250px",
      marginRight: theme.spacing(1)
    },
    inputService: {
      width: "150px",
      marginRight: theme.spacing(1)
    }
  })
);

type ServiceOptions = {
  serviceId: number;
  name: string;
};

type AccompanyingPeopleMapping = { [serviceId: string]: { count: number } };

type AccompanyingPeoplesFieldProps = {
  contractorId: number;
  onValueChange?: (value: AccompanyingPeopleMapping) => void;
  options: ServiceOptions[];
  disabled?: boolean;
} & FieldHookConfig<AccompanyingPeopleMapping>;

const AccompanyingPeopleEditor = ({
  contractorId,
  onValueChange,
  options,
  disabled,
  ...props
}: AccompanyingPeoplesFieldProps): JSX.Element | null => {
  const t = useTranslate("TimeSheetRowsAndApprovals");
  const classes = useAccompanyingPeopleEditorStyles();
  const [, { value = {}, error }, { setValue, setError }] = useField(props);
  const [maxAmount, setMaxAmount] = useState(Number.MAX_SAFE_INTEGER);

  useEffect(() => {
    if (!disabled && contractorId >= 0) {
      let alive = true;
      void getPersonsByCompanyAPI(contractorId).then(
        (r) => alive && setMaxAmount(r.results.length)
      );
      return () => {
        alive = false;
      };
    }
  }, [disabled, contractorId]);

  if (options?.length) {
    const invalidOptions = new Set(
      Object.keys(value).filter(
        (serviceId) => !options.some((o) => o.serviceId === parseInt(serviceId))
      )
    );
    if (invalidOptions.size) {
      setValue(
        Object.fromEntries(
          Object.entries(value).filter(
            ([serviceId]) => !invalidOptions.has(serviceId)
          )
        )
      );
    }
  }

  const availableOptions = options.filter((o) => !value[o.serviceId]);

  const onAdd = () => {
    if (availableOptions.length > 0) {
      const serviceId = availableOptions[0].serviceId;
      const newValue: typeof value = {
        ...value,
        [serviceId]: { count: 1 }
      };
      setValue(newValue);
      onValueChange?.(newValue);
    }
  };

  const onRemove = (serviceId: string) => {
    const newValue = { ...value };
    delete newValue[serviceId];
    setValue(newValue);
  };

  const onServiceChanged = (oldServiceId: string, newServiceId: string) => {
    const newValue = Object.fromEntries(
      Object.entries(value)
        .filter(([serviceId]) => serviceId !== newServiceId)
        .map(([serviceId, count]) =>
          serviceId === oldServiceId
            ? [newServiceId, count]
            : [serviceId, count]
        )
    );
    setValue(newValue);
  };

  const onCountChanged = (serviceId: string, v: string) => {
    const n = parseInt(v);
    if (Number.isInteger(n)) {
      const current = Object.values(value)
        .map(({ count }) => count)
        .reduce((acc, count) => acc + count, 0);
      const diff = n - (value[serviceId]?.count ?? 0);
      if (current + diff < maxAmount) {
        const newValue = {
          ...value,
          [serviceId]: { count: n }
        };
        setValue(newValue);
        if (error) {
          setError(undefined);
        }
      } else {
        setError(
          t("contractorMaxColleaguesError").replace("{}", maxAmount.toString())
        );
      }
    }
  };

  return (
    <div className={classes.container}>
      {!!Object.keys(value).length && (
        <Typography variant="overline">
          {t("selectAccompanyingInputLabel")}
        </Typography>
      )}
      {Object.entries(value).map(([serviceId, { count }]) => {
        return (
          <FormGroup key={serviceId} className={classes.entryContainer} row>
            <FormControl className={classes.selectService}>
              <InputLabel>{t("selectServiceInputLabel")}</InputLabel>
              <Select
                data-cy={`timesheet-row-add-accompanying-${serviceId}-select`}
                value={serviceId}
                onChange={(e) =>
                  onServiceChanged(serviceId, e.target.value as string)
                }
                disabled={disabled}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.serviceId}
                    value={option.serviceId}
                    disabled={!!value[option.serviceId]}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.inputService}>
              <InputLabel>{t("amountFieldLabel")}</InputLabel>
              <Input
                data-cy={`timesheet-row-add-accompanying-${serviceId}-input`}
                type="number"
                value={count}
                onChange={(e) =>
                  onCountChanged(serviceId, e.currentTarget.value)
                }
                disabled={disabled}
              />
            </FormControl>
            <IconButton disabled={disabled} onClick={() => onRemove(serviceId)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </FormGroup>
        );
      })}
      <div className={classes.addContainer}>
        <Button
          color="primary"
          data-cy="timesheet-row-add-accompanying"
          variant="contained"
          size="small"
          onClick={onAdd}
          disabled={disabled || !availableOptions.length}
        >
          <AddIcon fontSize="small" /> {t("addAccompanyingPersonButtonLabel")}
        </Button>
      </div>
      {!!error && <FormHelperText error>{error}</FormHelperText>}
    </div>
  );
};

const styles = (theme: any) =>
  createStyles({
    paddingTop: {
      paddingTop: theme.spacing(0.5)
    },
    dispatcheDisable: {
      backgroundColor: "#edf5f7"
    },
    dispatcheEnable: {
      backgroundColor: "#F5F5F5"
    },
    customInput: {
      minWidth: 100,
      maxWidth: 190,
      wordWrap: "break-word",
      whiteSpace: "nowrap",
      overflow: "hidden",

      '& [class*="makeStyles-valueContainer"]': {
        flexWrap: "nowrap !important"
      },
      '& [class*="makeStyles-singleValue"]': {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
      }
    },
    customSelect: {
      minWidth: 100,
      maxWidth: 190,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      fontSize: "14px",
      height: "50px",
      padding: "9px 7px 7px 7px",
      "& .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input":
        {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap"
        }
    },
    customInstructions: {
      minWidth: 100,
      maxWidth: 200,
      "& .MuiInputBase-input.MuiInput-input.MuiInputBase-inputMultiline.MuiInput-inputMultiline":
        {
          padding: "7px",
          fontSize: "14px"
        },
      "& .MuiInputBase-multiline": {
        padding: "9px 8px 8px 8px"
      }
    },
    customTooltip: {
      fontSize: "14px"
    }
  });

interface RouteParticipantsTableRowProps extends WithStyles<typeof styles> {
  route?: RouteInstance | RoutePlan;
  formikProps: FormikProps<RouteInstanceForm>;
  isDispatched: boolean;
  readOnlyMode: boolean;
  participant: RouteParticipantForm;
  companyOptions: SelectFieldOption[];
  services: Service[];
  index: number;
  arrayHelpers: FieldArrayRenderProps;
  showDialogWithData: (
    index: number,
    arrHelpers: FieldArrayRenderProps
  ) => () => void;
  searchContractorCompanies?: (
    filter: string,
    serviceCategoriesIds: string,
    pageNumber?: number,
    keepHistory?: boolean
  ) => void;
  isLoadingServices: boolean;
}

const RouteParticipantsTableRow: React.FC<RouteParticipantsTableRowProps> = (
  props
) => {
  const {
    classes,
    formikProps,
    index,
    route,
    readOnlyMode,
    isDispatched,
    showDialogWithData,
    arrayHelpers,
    participant,
    companyOptions,
    services,
    searchContractorCompanies
  } = props;

  const t = useTranslate("NewRouteInstancePage");

  const [persons, setPersons] = useState<PersonForm[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (participant.contractor >= 0) {
        const response = await getPersonsByCompanyAPI(participant.contractor);
        setPersons(response?.results || []);
      }
    };

    fetchData();
  }, [participant.contractor]);

  const contractorCompanyOption = () => {
    if (searchContractorCompanies) {
      searchContractorCompanies(
        "",
        formikProps.values.servicecategories.toString()
      );
    }
  };

  const selectedCompany = companyOptions.find(
    (contractor) => participant.contractor === contractor.value
  );

  const getContractorLabel = (contractorId: number) => {
    const selectedContractor = companyOptions.find(
      (option) => option.value === contractorId
    );
    return selectedContractor ? selectedContractor.label : "";
  };

  const getPersonName = (personId: number) => {
    const selectedPerson = persons.find((person) => person.id === personId);
    return selectedPerson
      ? `${selectedPerson.first_name} ${selectedPerson.last_name}`
      : "";
  };

  const getSelectedServiceName = (serviceId: number) => {
    const selectedService = services.find(
      (service) => service.id === serviceId
    );
    return selectedService ? selectedService.name : "";
  };

  return (
    <React.Fragment key={index}>
      <TableRow
        className={`${
          route && route.dispatched
            ? classes.dispatcheDisable
            : classes.dispatcheEnable
        }`}
      >
        <TableCell align="center">
          <Typography variant="h6">{`${index + 1}`}</Typography>
        </TableCell>
        <TableCell>
          <div title={getContractorLabel(participant.contractor)}>
            <Field
              className={classes.customInput}
              id={`routeplan-form-routeparticipant-${index}-contractor`}
              type="text"
              name={`participants.${index}.contractor`}
              placeholder={t("contractorLabel")}
              value={selectedCompany}
              options={companyOptions}
              loadOptions={debounce(
                (value) =>
                  searchContractorCompanies &&
                  searchContractorCompanies(
                    value,
                    formikProps.values.servicecategories.toString()
                  ),
                500
              )}
              onMenuOpen={() => contractorCompanyOption()}
              component={AutoCompleteSelect}
            />
          </div>
        </TableCell>
        <TableCell>
          <div title={getPersonName(participant.person)}>
            <Field
              id={`timesheet-row-add-accompanying-${index}-person-select`}
              type="text"
              name={`participants.${index}.person`}
              render={({ field }: FieldProps) => (
                <Select
                  className={classes.customSelect}
                  data-cy={`timesheet-row-add-accompanying-${index}-person-select`}
                  name="specificPersonSelect"
                  value={field.value ?? -1}
                  onChange={(e) => {
                    const selectedPersonId = e.target.value === -1 ? undefined : e.target.value;
                    formikProps.setFieldValue(field.name, selectedPersonId);
                    formikProps.setFieldValue(
                      `participants.${index}.specific_person`,
                      selectedPersonId
                    );
                    field.onChange(e);
                  }}
                  onBlur={field.onBlur}
                  disabled={readOnlyMode || isDispatched}
                >
                  <MenuItem value={-1}>{t("anyPersonLabel")}</MenuItem>
                  {persons.map((person) => (
                    <MenuItem key={person.id} value={person.id}>
                      {`${person.first_name} ${person.last_name}`}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </div>
        </TableCell>
        <TableCell>
          <div title={getSelectedServiceName(participant.service)}>
            <Field
              isLoading={props.isLoadingServices}
              className={classes.customInput}
              id={`routeplan-form-routeparticipant-${index}-service`}
              type="text"
              name={`participants.${index}.service`}
              placeholder={t("serviceLabel")}
              options={services.map((s) => ({ label: s.name, value: s.id }))}
              component={AutoCompleteSelect}
              multiline
            />
          </div>
        </TableCell>
        <TableCell>
          <BlurTextField
            key={formikProps.values.routeplan}
            id={`routeplan-form-routeparticipant-${index}-instructions`}
            name={`participants.${index}.instructions`}
            placeholder={t("instructionsLabel")}
            className={classes.customInstructions}
            multiline
            fullWidth
          />
        </TableCell>
        {!readOnlyMode && (
          <TableCell rowSpan={2}>
            <IconButton
              id={`routeplan-form-routeparticipant-${index}-delete`}
              color="secondary"
              onClick={showDialogWithData(index, arrayHelpers)}
              disabled={isDispatched}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
      <TableRow
        className={`${
          route && route.dispatched
            ? classes.dispatcheDisable
            : classes.dispatcheEnable
        }`}
      >
        <TableCell colSpan={5}>
          <AccompanyingPeopleEditor
            contractorId={participant.contractor}
            name={`participants.${index}.accompanying_people`}
            options={services
              .filter((s) => s.is_accompanying_person_allowed)
              .map((s) => ({ serviceId: s.id, name: s.name }))}
          />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default withStyles(styles)(RouteParticipantsTableRow);
