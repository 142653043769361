import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid
} from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { APP_DATE_FORMAT } from "../../helpers/timesheetHelperService";
import { TableFilterConfig } from "../../redux/types";
import { Formik, Field } from "formik";
import { AutoCompleteSelect } from "../AutoCompleteSelect";
import { useTranslate } from "../../services/appLanguageService";

const DatePicker: any = KeyboardDatePicker;

// type Query = { [key: string]: string | number | undefined };

interface TableFilterProps<QueryType, OptionsType> {
  filters: TableFilterConfig<QueryType, OptionsType>[][];
  onChange: (filter: QueryType) => void;
  defaultValues: QueryType;
  disabled?: boolean;
}

// Component Styles
const useStyles: any = makeStyles((theme: any) =>
  createStyles({
    filterItem: {
      display: "flex",
      margin: `0 ${theme.spacing(0.5)}px`
    },
    filterLabel: {
      display: "flex",
      alignItems: "center",
      fontSize: theme.typography.fontSize,
      color: "#4056b5"
    },
    inputLabel: {
      display: "flex",
      alignItems: "center",
      color: "#43a047"
    },
    pickerLabel: {
      // marginRight: 10,
      marginBottom: 23,
      fontSize: 12,
      color: "#43a047"
    },
    filterItemsContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      marginLeft: "1rem"
    },
    formControl: {
      minWidth: 160
    },
    seperator: {
      textAlign: "center",
      alignSelf: "center",
      width: theme.spacing(10)
    },
    select: {
      paddingLeft: theme.spacing(1),
      paddingTop: theme.spacing(1),
      width: "100%"
    },
    picker: {
      marginRight: theme.spacing(1),
      marginTop: "0 !important"
    },
    deleteIcon: {
      marginTop: theme.spacing(2)
    },
    leftIcon: {
      marginRight: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1)
    },
    flex: {
      display: "flex"
    },
    expansionSummary: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      "& .MuiAccordionSummary-content": {
        justifyContent: "space-between"
      }
    },
    header: {
      color: "#43a047"
    },
    expansionDetailPanel: {
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    filters: {
      display: "flex",
      flexWrap: "wrap",
      padding: `0 0 ${theme.spacing(1)}px`,
      width: "100%"
    },
    selectorLabel: {
      marginBottom: "6px !important"
    },
    root: {
      flexGrow: 1
    }
  })
);

// Component
function TableFilter<QueryType, OptionsType extends { id: number } = any>(
  props: TableFilterProps<QueryType, OptionsType>
) {
  const { filters, defaultValues, disabled, onChange } = props;
  const t = useTranslate("TableFilter");

  const [filterQuery, setFilterQuery] = useState<QueryType>({
    ...defaultValues
  });
  const [dateError, setDateError] = useState<Record<any, boolean>>({});
  const classes = useStyles();

  const handleSelectorChange =
    (key: keyof QueryType, relation?: keyof QueryType) =>
    (value: QueryType) => {
      if (relation) {
        setFilterChange(relation, -1);
      }
      setFilterChange(key, value[key]);
    };
  const handleDateChange = (key: keyof QueryType) => (date: Date) => {
    if (!date) {
      setFilterChange(key);
      setDateError((d) => ({ ...d, [key]: false }));
    } else if (date.toString() !== "Invalid Date") {
      setFilterChange(key, date.toISOString());
      setDateError((d) => ({ ...d, [key]: false }));
    } else {
      setDateError((d) => ({ ...d, [key]: true }));
    }
  };
  const setFilterChange = (key: keyof QueryType, value?: any) => {
    setFilterQuery((_filters) => {
      const clone: QueryType = { ..._filters };
      if (value === undefined) {
        delete clone[key];
      } else {
        clone[key] = value;
      }
      onChange(clone);
      return clone;
    });
  };
  return (
    <Accordion disabled={disabled}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        data-cy="filter-expand"
        className={classes.expansionSummary}
      >
        <Typography
          className={`${classes.flex} ${
            Object.keys(filterQuery).length ? classes.header : ""
          }`}
        >
          <FilterListIcon className={classes.leftIcon} /> Filter
        </Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(event) => {
            event.stopPropagation();
            setFilterQuery((_filters) => {
              let clearedFilters = { ..._filters };

              filters.flat().forEach((_filter) => {
                if (_filter.type === "SELECTOR") {
                  clearedFilters[_filter.key] = -1 as any;
                } else {
                  delete clearedFilters[_filter.key];
                }
              });

              onChange(clearedFilters);
              return clearedFilters;
            });
          }}
        >
          {t("clearButton")}
        </Button>
      </AccordionSummary>
      <AccordionDetails
        data-cy="expanded-filters"
        className={classes.expansionDetailPanel}
      >
        <div className={classes.root}>
          <Grid container spacing={3}>
            {filters.map((filtergroup, index) => (
              <Grid item xs={12} sm={12} key={index}>
                <div className={classes.filters}>
                  {filtergroup.map((filterConfig, filterIndex) => (
                    <div key={filterIndex} className={classes.filterItem}>
                      {
                        <>
                          {filterConfig.type === "SELECTOR" &&
                            filterConfig.key && (
                              <Formik
                                validate={handleSelectorChange(
                                  filterConfig.key,
                                  filterConfig.relation
                                )}
                                initialValues={filterQuery}
                                onSubmit={() => {}}
                              >
                                {({ values }) => {
                                  const value =
                                    filterQuery[filterConfig.key] ===
                                    (-1 as any)
                                      ? -1
                                      : (filterConfig.options &&
                                          filterConfig.options.find(
                                            (el) =>
                                              el.value ===
                                              (values[filterConfig.key] as any)
                                          )) ||
                                        null;
                                  return (
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column"
                                      }}
                                    >
                                      <InputLabel
                                        className={`${classes.pickerLabel} ${classes.selectorLabel}`}
                                      >
                                        {filterConfig.title}
                                      </InputLabel>
                                      <Field
                                        id={filterConfig.key}
                                        data-cy={filterConfig.key}
                                        className={classes.picker}
                                        style={{
                                          minWidth: "220px"
                                        }}
                                        InputLabelProps={{
                                          shrink: true
                                        }}
                                        type="text"
                                        name={filterConfig.key}
                                        value={value}
                                        options={filterConfig.options}
                                        component={AutoCompleteSelect}
                                        margin="normal"
                                        fullWidth
                                        loadOptions={filterConfig.loadOptions}
                                      />
                                    </div>
                                  );
                                }}
                              </Formik>
                            )}
                          {filterConfig.type === "DATE" && (
                            <div>
                              <InputLabel className={classes.pickerLabel}>
                                {filterConfig.title}
                              </InputLabel>
                              <DatePicker
                                margin="normal"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                clearable
                                data-cy={filterConfig.key}
                                animateYearScrolling
                                className={classes.picker}
                                format={APP_DATE_FORMAT}
                                value={
                                  filterQuery[filterConfig.key]
                                    ? filterQuery[filterConfig.key]
                                    : null
                                }
                                onChange={handleDateChange(filterConfig.key)}
                                KeyboardButtonProps={{
                                  "aria-label": "change date"
                                }}
                                error={dateError[filterConfig.key]}
                                helperText={
                                  dateError[filterConfig.key]
                                    ? "Wrong Date"
                                    : undefined
                                }
                              />
                            </div>
                          )}
                          {filterConfig.seperator === "OR" && (
                            <div className={classes.seperator}>
                              <Typography>OR</Typography>
                            </div>
                          )}
                        </>
                      }
                    </div>
                  ))}
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

TableFilter.defaultProps = {
  disabled: false
};

export default TableFilter;
