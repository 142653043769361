import React, { useEffect, useState } from "react";
import createStyles from "@material-ui/core/styles/createStyles";
import { Formik, Field, FormikProps, FormikHelpers } from "formik";
import * as Yup from "yup";
import {
  Paper,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  TextField,
  makeStyles,
  CircularProgress
} from "@material-ui/core";
import RouteParticipantsComponent from "./RouteParticipants";
import RoutePlanSegments from "./RouteSegments";
import {
  SelectFieldOption,
  RouteInstanceForm,
  RoutePlanForm
} from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import Alert from "@material-ui/lab/Alert";
import { AlertTitle, Autocomplete } from "@material-ui/lab";
import { BlurTextField } from "./BlurTextField";
import LoadingSpinner from "../../LoadingSpinner";
import { niceDate } from "../../FormatHelpers";
import DateTimePicker from "../../DateTimePicker";
import { getDate, timeFormat } from "../../FormatHelpers";
import {
  createRouteInstanceAPI,
  createRoutePlanAPI,
  deleteRouteInstanceAPI,
  dispatchRouteInstanceAPI,
  getAllRoutePlanNamesAPI,
  getRoutePlanAPI,
  getSearchContractorOptionsAPI,
  updateRoutePlanAPI
} from "../../../services/api-declaration";
import { Add as AddIcon, Send as SendIcon } from "@material-ui/icons";
import { useHistory } from "react-router";
import { showGlobalSnackbar } from "../../../helpers/globalHelper";
import ConfirmationDialog from "../../confirmationDialog/ConfirmationDialog";

const ValidationSchema = (t: any) =>
  Yup.object().shape({
    title: Yup.string()
      .min(1, t("minLengthError"))
      .max(255, t("max255LengthError"))
      .required(t("requiredError")),
    participants: Yup.array()
      .min(1, t("atLeastOneRowError"))
      .of(
        Yup.object().shape({
          instructions: Yup.string().nullable().notRequired(),
          service: Yup.number()
            .positive(t("requiredError"))
            .required(t("requiredError")),
          contractor: Yup.number()
            .positive(t("requiredError"))
            .required(t("requiredError"))
        })
      ),
    rows: Yup.array()
      .min(1, t("atLeastOneRowError"))
      .of(
        Yup.object().shape({
          notes: Yup.string().nullable().notRequired(),
          task: Yup.number()
            .positive(t("requiredError"))
            .required(t("requiredError")),
          servicecategories: Yup.array()
            .min(1, t("requiredError"))
            .required(t("requiredError"))
        })
      )
  });

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      marginTop: theme.spacing(1)
    },
    submit: {
      marginTop: theme.spacing(3)
    },
    paper: {
      padding: 20,
      marginTop: 20
    },
    marginTop: {
      marginTop: theme.spacing(3)
    },
    marginBottom: {
      marginBottom: theme.spacing(3)
    },
    formControl: {
      marginBottom: theme.spacing(1.5),
      display: "flex",
      flexDirection: "row"
    },
    gridField: {
      marginTop: theme.spacing(0)
    },
    outerErrorListItem: {
      listStyleType: "none",
      fontWeight: "bold"
    },
    innerErrorListItem: {
      listStyleType: "none"
    },
    indentation: {
      marginLeft: 10
    },
    buttonGroup: {
      marginTop: theme.spacing(1),
      display: "flex",
      justifyContent: "end",
      "& > button": {
        width: "250px"
      },
      "& > :last-child": {
        marginLeft: theme.spacing(1)
      }
    }
  })
);

const RouteInstanceFormComponent: React.FC<{}> = () => {
  const classes = useStyles();
  const t = useTranslate("NewRouteInstancePage");
  const t3 = useTranslate("ValidationErrorMessages");
  const deadlineOptions: string[] = [
    "ASAP",
    "START_TIME",
    "END_TIME",
    "WAIT_FOR_CALL"
  ];
  const initialValues: RouteInstanceForm = {
    rows: [],
    participants: [],
    servicecategories: [],
    status: "DRAFT",
    title: `${t("orderLabel")} ${niceDate(new Date().toISOString())}`,
    deadline: "ASAP",
    time: null,
    id: -1
  };
  const [isDispatching, setIsDispatching] = useState(false);
  const [errorMessages, setErrorMessages] = useState<{ [key: string]: any }>(
    {}
  );
  const [companyOptions, setCompanyOptions] = useState<SelectFieldOption[]>([]);
  const [routePlanNames, setRoutePlanNames] = useState<
    { id: number; oca: number; name: string }[]
  >([]);
  const [refreshRoutePlans, setRefreshRoutePlans] = useState(false);
  const [modal, setModal] = useState<{
    m: "planOverwrite";
    resolve: (replace: boolean) => void;
  }>();
  let routerHistory = useHistory();

  useEffect(() => {
    let alive = true;
    void getAllRoutePlanNamesAPI().then(
      ({ results }) => alive && setRoutePlanNames(results)
    );
    return () => {
      alive = false;
    };
  }, [refreshRoutePlans]);

  const handleSubmit = async (
    values: RouteInstanceForm,
    actions: FormikHelpers<RouteInstanceForm>
  ) => {
    try {
      const checkedNullValues: RouteInstanceForm = {
        ...values,
        rows: values.rows.map((row, index) => ({
          ...row,
          row_id: index + 1,
          sort_order: index,
          notes: row.notes === "" ? null : row.notes
        })),
        servicecategories: [
          ...new Set(values.rows.flatMap((r) => r.servicecategories))
        ],
        routeplan: undefined
      };

      actions.setSubmitting(false);

      const routeInstancePostResponse = await createRouteInstanceAPI(
        checkedNullValues
      );

      void dispatchRouteInstanceAPI({
        id: routeInstancePostResponse.id,
        oca: routeInstancePostResponse.oca,
        dispatched: true
      })
        .then(() => {
          showGlobalSnackbar("Dispatch success", "success");

          routerHistory.push(`/orders/routeinstances/?same_tab=true`);
        })
        .catch(async (error: any) => {
          setErrorMessages(error.response_data);
          await deleteRouteInstanceAPI(routeInstancePostResponse.id);

          showGlobalSnackbar("Dispatch failed", "error");

          try {
          } catch (error: any) {
            console.error(error.message);
          }
        });
    } catch (error: any) {
      setErrorMessages(error.response_data);
    }
  };

  const selectRoutePlan = async (
    formikProps: FormikProps<RouteInstanceForm>,
    routePlanId: number
  ) => {
    if (routePlanId) {
      const plan = await getRoutePlanAPI(routePlanId);
      const orderName = `${plan.name || t("orderLabel")} ${niceDate(
        new Date().toISOString()
      )}`;
      formikProps.setValues({
        ...formikProps.values,
        routeplan: plan.id,
        title: orderName,
        servicecategories: plan.servicecategories,
        participants: plan.participants,
        rows: plan.rows
      });
    } else {
      formikProps.setFieldValue("routeplan", 0);
    }
  };

  const saveRoutePlan = async (formikProps: FormikProps<RouteInstanceForm>) => {
    const values = formikProps.values;
    const routePlan: RoutePlanForm = {
      name: values.title,
      servicecategories: [
        ...new Set(values.rows.flatMap((r) => r.servicecategories))
      ],
      participants: values.participants,
      rows: values.rows?.map((row, index) => ({
        ...row,
        row_id: index + 1,
        sort_order: index,
      })),
      status: "DRAFT"
    };
    let routePlanId = values.routeplan;
    if (routePlanId) {
      const overwrite = await new Promise<boolean>((resolve) => {
        setModal({ m: "planOverwrite", resolve });
      });
      if (overwrite) {
        let oca = routePlanNames.find((r) => r.id === routePlanId)?.oca;
        if (oca === undefined) {
          throw new Error("Could not find matching route plan");
        }
        await updateRoutePlanAPI(routePlanId, oca, routePlan);
        showGlobalSnackbar(
          `${t("templateUpdatedMessage")}: ${routePlan.name}`,
          "success"
        );
        setRefreshRoutePlans((r) => !r);
      } else {
        showGlobalSnackbar(
          `${t("templateNotUpdatedMessage")}: ${routePlan.name}`,
          "info"
        );
      }
    } else {
      let { id: newRoutePlanId } = await createRoutePlanAPI(routePlan);
      showGlobalSnackbar(
        `${t("templateCreatedMessage")}: ${routePlan.name}`,
        "success"
      );
      formikProps.setFieldValue("routeplan", newRoutePlanId);
      setRefreshRoutePlans((r) => !r);
    }
  };

  const searchContractorCompanies = async (filter: string) => {
    const response = await getSearchContractorOptionsAPI(filter);
    if (response) {
      setCompanyOptions(
        response.results.map((item) => ({
          label: item.name,
          value: item.id
        }))
      );
    }
  };

  useEffect(() => void searchContractorCompanies(""), []);

  const handleDeadlineChange =
    ({ setFieldValue, handleChange }: FormikProps<RouteInstanceForm>) =>
    (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const deadLineType = e.target.value as string;
      if (deadLineType === "ASAP" || deadLineType === "WAIT_FOR_CALL") {
        setFieldValue("time", null);
      }
      handleChange(e);
    };

  const prettyPrint = (
    obj: { [key: string]: any } | any[] | number | string
  ): JSX.Element => {
    if (Array.isArray(obj)) {
      return <>{obj.map((element) => prettyPrint(element))}</>;
    } else if (
      typeof obj === "object" &&
      Object.keys(obj).length > 0 &&
      !Array.isArray(obj)
    ) {
      for (const [key, value] of Object.entries(obj)) {
        return (
          <>
            <li key={key} className={classes.outerErrorListItem}>
              {key}
            </li>
            {prettyPrint(value)}
          </>
        );
      }
    } else if (typeof obj !== "object") {
      return (
        <li
          key={obj}
          className={`${classes.innerErrorListItem} ${classes.indentation}`}
        >
          {obj}
        </li>
      );
    }
    return <></>;
  };

  useEffect(() => {
    if (Object.keys(errorMessages).length > 0) {
      setIsDispatching(false);
    }
  }, [errorMessages]);

  const clearErrorMessages = () => setErrorMessages({});

  const getHelperText = (formikProps: FormikProps<RouteInstanceForm>) => {
    const date = formikProps.values.time as any;

    if (
      date instanceof Date &&
      !isNaN(date.valueOf()) &&
      getDate(date.toString()) !== getDate(new Date().toString())
    ) {
      return getDate(date as any);
    }
  };

  const rowErrorIds = (errorMessages?.rows as Array<object>)
    ?.map((row, rowId) => (Object.keys(row).length !== 0 ? rowId : undefined))
    .filter((rowId): rowId is number => rowId !== undefined);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema(t3)}
        onSubmit={(dispatch: any, ownProps: any) => {
          setIsDispatching(true);
          handleSubmit(dispatch, ownProps);
        }}
      >
        {(formikProps) => {
          const noDeadlineTime =
            formikProps.values.deadline === "ASAP" ||
            formikProps.values.deadline === "WAIT_FOR_CALL";

          return (
            <form
              className={classes.form}
              onSubmit={formikProps.handleSubmit}
              autoComplete="off"
            >
              <Paper className={classes.paper}>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={8}>
                    <Typography component="h1" variant="h5">
                      {t("newOrderLabel")}
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={4}>
                    <RoutePlanSelect
                      formikProps={formikProps}
                      routePlans={routePlanNames}
                      onSelect={(id) => selectRoutePlan(formikProps, id)}
                    />
                  </Grid>
                </Grid>

                <BlurTextField
                  key={formikProps.values.routeplan}
                  id="routeinstance-form-title"
                  name="title"
                  label={t("titleLabel")}
                  placeholder={t("titleLabel")}
                  margin="normal"
                  className={classes.marginBottom}
                  fullWidth
                />
                <Grid container spacing={2}>
                  <Grid item sm={12} md={noDeadlineTime ? 12 : 6}>
                    <FormControl
                      id="routeinstance-form-deadline"
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="deadline">
                        {t("deadlineLabel")}
                      </InputLabel>
                      <Select
                        name="deadline"
                        value={formikProps.values.deadline || ""}
                        onChange={handleDeadlineChange(formikProps)}
                        fullWidth
                      >
                        {deadlineOptions.map((deadline, index) => (
                          <MenuItem key={index} value={deadline}>
                            {t(deadline)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {!noDeadlineTime && (
                    <Grid item sm={12} md={6}>
                      <Field
                        id="routeinstance-form-time"
                        name="time"
                        label={t("timeLabel")}
                        placeholder={t("timeLabel")}
                        component={DateTimePicker}
                        format={timeFormat}
                        helperText={(() =>
                          formikProps.values.time &&
                          getHelperText(formikProps))()}
                        margin="normal"
                        className={classes.gridField}
                      />
                    </Grid>
                  )}
                </Grid>
              </Paper>

              <RouteParticipantsComponent
                key={
                  `${formikProps.values.routeplan}-participants` /* Reload everything on plan change */
                }
                companyOptions={companyOptions}
                searchContractorCompanies={searchContractorCompanies}
                handleSubmit={formikProps.handleSubmit as any}
                formikProps={formikProps}
                readOnlyMode={false}
                isLoading={false}
                isDispatched={false}
              />
              <RoutePlanSegments
                key={
                  `${formikProps.values.routeplan}-segments` /* Reload everything on plan change */
                }
                contractorCompanies={companyOptions}
                handleSubmit={formikProps.handleSubmit as any}
                formikProps={formikProps}
                isDispatched={false}
                readOnlyMode={false}
                segmentRowIds={[]}
                participantsStatus={[]}
                rowErrorIds={rowErrorIds}
                clearErrorMessages={clearErrorMessages}
              />

              {Object.keys(errorMessages).length > 0 && (
                <Paper className={classes.paper}>
                  <Alert severity="error">
                    <AlertTitle>{t("errorTitle")}</AlertTitle>
                    <ul>{prettyPrint(errorMessages)}</ul>
                  </Alert>
                </Paper>
              )}
              <div className={classes.buttonGroup}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    formikProps.setTouched({
                      title: true,
                      participants: [
                        {
                          instructions: true,
                          service: true,
                          contractor: true,
                          person: true
                        }
                      ],
                      rows: [
                        { notes: true, task: true, servicecategories: true }
                      ]
                    });
                    formikProps.validateForm().then((errors) => {
                      if (Object.keys(errors).length === 0) {
                        saveRoutePlan(formikProps);
                      }
                    });
                  }}
                >
                  {t("saveAsRoutePlanButtonLabel")}
                </Button>
                {isDispatching ? (
                  <LoadingSpinner />
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<SendIcon />}
                    disabled={isDispatching}
                  >
                    {t("dispatchLabel")}
                  </Button>
                )}
              </div>
            </form>
          );
        }}
      </Formik>
      {modal?.m === "planOverwrite" && (
        <ConfirmationDialog
          title={t("confirmPlanReplaceTitle")}
          description={t("confirmPlanReplace")}
          onSuccess={() => {
            modal.resolve(true);
            setModal(undefined);
          }}
          onClose={() => {
            modal.resolve(false);
            setModal(undefined);
          }}
          open
        />
      )}
    </div>
  );
};

type RoutePlanSelectProps = {
  formikProps: FormikProps<RouteInstanceForm>;
  routePlans?: { id: number; name: string }[];
  onSelect: (id: number) => Promise<void>;
};

const RoutePlanSelect: React.FC<RoutePlanSelectProps> = ({
  formikProps,
  routePlans,
  onSelect
}) => {
  const t = useTranslate("NewRouteInstancePage");
  const [loading, setLoading] = useState(false);
  const options = [
    { label: t("newPlan"), value: 0 },
    ...(routePlans?.map((r) => ({ label: r.name, value: r.id })) ?? [])
  ];

  return !loading && !!options ? (
    <Autocomplete
      value={options?.find(
        (o) => o.value === formikProps.values.routeplan ?? 0
      )}
      defaultValue={options[0]}
      options={options}
      loading={loading || !routePlans}
      getOptionLabel={(o) => o.label}
      disableClearable
      renderInput={(params) => (
        <TextField {...params} label={t("useRoutePlanLabel")} />
      )}
      onChange={(_, v) => {
        if (v) {
          setLoading(true);
          onSelect(v.value).then(() => setLoading(false));
        }
      }}
    />
  ) : (
    <CircularProgress size={20} />
  );
};

export default RouteInstanceFormComponent;
