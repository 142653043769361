import * as actionTypes from "./actionTypes";

export function ResetLoadingRouteSegmentsTasks(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_LOADING_ROUTESEGMENTS_TASKS
  };
}

export function ResetRouteplan(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_ROUTEPLAN
  };
}

export function ResetRouteplans(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_ROUTEPLANS
  };
}

export function ResetRouteinstance(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_ROUTEINSTANCE
  };
}

export function ResetRouteinstances(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_ROUTEINSTANCES
  };
}

export function ResetRoute(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_ROUTE
  };
}

export function ResetLoadingContractorContracts(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_LOADING_CONTRACTOR_CONTRACTS
  };
}

export function ResetLoadingTaskServices(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_LOADING_TASK_SERVICES
  };
}

export function ResetTask(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_TASK
  };
}

export function ResetTasks(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_TASKS
  };
}

export function ResetCustomerobject(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_CUSTOMEROBJECT
  };
}

export function ResetCustomerobjects(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_CUSTOMEROBJECTS
  };
}

export function ResetCompany(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_COMPANY
  };
}

export function ResetCompanies(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_COMPANIES
  };
}

export function ResetInvoiceRecipient(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_INVOICERECIPIENT
  };
}

export function ResetPasscard(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_PASSCARD
  };
}

export function ResetPasscards(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_PASSCARDS
  };
}

export function ResetDistrict(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_DISTRICT
  };
}

export function ResetDistricts(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_DISTRICTS
  };
}

export function ResetWeatherArea(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_WEATHERAREA
  };
}

export function ResetWeatherAreas(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_WEATHERAREAS
  };
}

export function ResetLocation(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_LOCATION
  };
}

export function ResetLocations(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_LOCATIONS
  };
}

export function ResetInvoicingProfile(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_INVOICINGPROFILE
  };
}

export function ResetInvoicingProfiles(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_INVOICINGPROFILES
  };
}

export function ResetContractingProfile(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_CONTRACTINGPROFILE
  };
}

export function ResetContractingProfiles(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_CONTRACTINGPROFILES
  };
}

export function ResetBusinessArea(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_BUSINESSAREA
  };
}

export function ResetBusinessAreas(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_BUSINESSAREAS
  };
}

export function ResetServiceCategories(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_SERVICECATEGORIES
  };
}

export function ResetTaskOptions(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_TASKOPTIONS
  };
}

export function ResetProject(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_PROJECT
  };
}

export function ResetProjects(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_PROJECTS
  };
}

export function ResetCooperation(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_COOPERATION
  };
}

export function ResetCooperations(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_COOPERATIONS
  };
}

export function ResetProspect(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_PROSPECT
  };
}

export function ResetProspects(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_PROSPECTS
  };
}
export function ResetTemplateImage(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_TEMPLATEIMAGE
  };
}

export function ResetTemplateImages(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_TEMPLATEIMAGES
  };
}

export function ResetEmails(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_EMAILS
  };
}

export function ResetTerm(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_TERM
  };
}

export function ResetTerms(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_TERMS
  };
}

export function ResetHolidays(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_HOLIDAYS
  };
}

export function ResetGroup(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_GROUP
  };
}

export function ResetGroups(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_GROUPS
  };
}

export function ResetUser(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_USER
  };
}

export function ResetUsers(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_USERS
  };
}

export function ResetNotificationsProfiles(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_NOTIFICATIONSPROFILES
  };
}

export function ResetPerson(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_PERSON
  };
}

export function ResetPersons(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_PERSONS
  };
}

export function ResetContract(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_CONTRACT
  };
}

export function ResetContracts(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_CONTRACTS
  };
}

export function ResetContractItems(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_CONTRACT_ITEMS
  };
}

export function ResetLoadingServices(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_LOADING_SERVICES
  };
}

export function ResetLoadingMaterials(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_LOADING_MATERIALS
  };
}

export function ResetLoadingAccessories(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_LOADING_ACCESSORIES
  };
}

export function ResetLoadingAddons(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_LOADING_ADDONS
  };
}

export function ResetLoadingHistory(tabId: number) {
  return {
    tabId,
    type: actionTypes.RESET_HISTORY
  };
}
