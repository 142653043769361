import {
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
  Button,
  DialogActions,
  CircularProgress,
  makeStyles,
  createStyles,
  TextField,
  InputLabel,
  Grid,
  MenuItem,
  Select
} from "@material-ui/core";
import { useState, useEffect, ChangeEvent } from "react";
import {
  getContractorEmailData,
  getCustomerEmailData,
  sendContractorInvoiceEmailAPI,
  sendCustomerInvoiceEmailAPI
} from "../../../services/api-declaration";
import { useTranslate } from "../../../services/appLanguageService";
import LoadingSpinner from "../../LoadingSpinner";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromHTML,
  convertToRaw
} from "draft-js";
import { Add } from "@material-ui/icons";
import draftToHtml from "draftjs-to-html";
import { Invoice } from "../../../redux/types";
import { showGlobalSnackbar } from "../../../helpers/globalHelper";
import * as Yup from "yup";

interface SendInvoiceDialogProps {
  onClose: () => void;
  updateInvoice: (invoice: Invoice) => void;
  invoice: Invoice;
  invoiceType: "customerinvoices" | "contractorinvoices";
}

type AddressFormType = "to_addresses" | "cc_addresses" | "bcc_addresses";

interface SendInvoiceForm {
  subject: string;
  body: string;
  to_addresses: string[];
  cc_addresses: string[];
  bcc_addresses: string[];
}

interface AddAddressForm {
  add_address: string;
  type: AddressFormType;
}

const useModalStyles = makeStyles((theme) =>
  createStyles({
    content: {
      "& > div": {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    },
    actions: {
      display: "flex",
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2)
    },
    editor: {
      border: "1px solid lightgray",
      borderRadius: "5px",
      height: "300px"
    },
    field: {
      margin: 30
    },
    addressField: {
      marginTop: 7
    },
    selectField: {
      marginLeft: 20,
      marginTop: 7
    },
    addButton: {
      marginLeft: 30,
      height: 40
    },
    addButtonContainer: {
      display: "flex",
      justifyContent: "flex-end"
    },
    error: { fontSize: 12, color: "red" }
  })
);

const invoiceRecipientTypes: AddressFormType[] = [
  "to_addresses",
  "cc_addresses",
  "bcc_addresses"
];

const SendInvoiceDialog: React.FC<SendInvoiceDialogProps> = (props) => {
  const classes = useModalStyles();
  const { onClose, invoice, invoiceType, updateInvoice } = props;

  const t = useTranslate("InvoiceView");
  const t3 = useTranslate("ValidationErrorMessages");
  const [sendLoading, setSendLoading] = useState(false);
  const [loadingEmailData, setLoadingEmailData] = useState(true);
  const [sendInvoiceForm, setSendInvoiceForm] = useState<SendInvoiceForm>({
    subject: "",
    body: "",
    to_addresses: [],
    cc_addresses: [],
    bcc_addresses: []
  });
  const [formValidation, setFormValidation] = useState<{
    [key: string]: {
      error: boolean;
      errorText: string;
    };
  }>({});
  const [editorState, setEditorState] = useState<EditorState>();
  const [addAddressForm, setAddAddressForm] = useState<AddAddressForm>({
    add_address: "",
    type: "to_addresses"
  });

  const sendInvoiceEmail = async () => {
    setSendLoading(true);
    const rawContentState = editorState
      ? convertToRaw(editorState.getCurrentContent())
      : undefined;
    const body = rawContentState ? draftToHtml(rawContentState) : "";

    const sendEmailApi =
      invoiceType === "contractorinvoices"
        ? sendContractorInvoiceEmailAPI
        : sendCustomerInvoiceEmailAPI;
    showGlobalSnackbar(`${t("sendingAttempt")}...`, "info");
    sendEmailApi(invoice.id, {
      oca: invoice.oca,
      body: body,
      subject: sendInvoiceForm.subject,
      to_addresses: sendInvoiceForm.to_addresses.join(", "),
      cc_addresses: sendInvoiceForm.cc_addresses.join(", "),
      bcc_addresses: sendInvoiceForm.bcc_addresses.join(", ")
    })
      .then(({ oca }) => {
        updateInvoice({ ...invoice, oca: oca });
        onClose();
        setSendLoading(false);
        showGlobalSnackbar(t("sendingSucceded"), "success");
      })
      .catch(() => showGlobalSnackbar(t("sendingFailed"), "error"));
  };

  useEffect(() => {
    let isAlive = true;

    const getEmailData =
      invoiceType === "contractorinvoices"
        ? getContractorEmailData
        : getCustomerEmailData;

    getEmailData(invoice.id).then((emailDataResponse) => {
      if (isAlive) {
        setSendInvoiceForm({
          subject: emailDataResponse.subject,
          body: emailDataResponse.body,
          to_addresses: emailDataResponse.to_addresses.split(",").filter(a => a.length),
          cc_addresses: emailDataResponse.cc_addresses.split(",").filter(a => a.length),
          bcc_addresses: emailDataResponse.bcc_addresses.split(",").filter(a => a.length),
        });
        const blocksFromHTML = convertFromHTML(emailDataResponse.body);

        const contentState = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorState(EditorState.createWithContent(contentState));
        setLoadingEmailData(false);
      }
    });

    return () => {
      isAlive = false;
    };
  }, [invoice.id, invoiceType]);

  const schema = Yup.object().shape({
    subject: Yup.string()
      .min(1, t3("minLengthError"))
      .max(255, t3("max255LengthError")),
    to_addresses: Yup.array()
      .min(1, t3("atLeastOneMainAddressError"))
      .of(Yup.string().email(t3("emailInvalidError"))),
    cc_addresses: Yup.array().of(Yup.string().email(t3("emailInvalidError"))),
    bcc_addresses: Yup.array().of(Yup.string().email(t3("emailInvalidError"))),
    add_address: Yup.string().email(t3("emailInvalidError")),
    type: Yup.string().oneOf(
      invoiceRecipientTypes,
      t3("notValidEnumValueError")
    )
  });

  const validate = (
    value: string | string[],
    field: keyof SendInvoiceForm | keyof AddAddressForm
  ) => {
    schema
      .validate({ [field]: value })
      .then((valid) => {
        setFormValidation((fv) => ({
          ...fv,
          [field]: {
            error: false,
            errorText: ""
          }
        }));
      })
      .catch((e: Yup.ValidationError) => {
        setFormValidation((fv) => ({
          ...fv,
          [field]: {
            error: true,
            errorText: e.errors?.join() || ""
          }
        }));
      });
  };

  const handleSelect = (
    event: ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>
  ) => {
    validate(event.target.value as AddressFormType, "type");
    setAddAddressForm((f) => ({
      ...f,
      type: event.target.value as
        | "to_addresses"
        | "cc_addresses"
        | "bcc_addresses"
    }));
  };

  const handleAddAddress = () => {
    const newInvoiceForm = {
      ...sendInvoiceForm,
      [addAddressForm.type]: [
        ...sendInvoiceForm[addAddressForm.type],
        addAddressForm.add_address
      ]
    };
    setSendInvoiceForm(newInvoiceForm);
    validate(newInvoiceForm[addAddressForm.type], addAddressForm.type);
    setAddAddressForm({ add_address: "", type: "to_addresses" });
    const { add_address, ...fV } = formValidation;
    setFormValidation(fV);
  };

  const handleDeleteAddress = (
    type: "to_addresses" | "cc_addresses" | "bcc_addresses",
    addressToRemove: string
  ) => {
    const newInvoiceForm = {
      ...sendInvoiceForm,
      [type]: sendInvoiceForm[type].filter((a) => a !== addressToRemove)
    };
    setSendInvoiceForm(newInvoiceForm);
    validate(newInvoiceForm[type], type);
  };

  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{t("sendInvoiceTitle")}</DialogTitle>
      {loadingEmailData ? (
        <LoadingSpinner />
      ) : (
        <DialogContent className={classes.content}>
          {sendInvoiceForm.to_addresses.map((address) => (
            <Chip
              label={`MAIN: ${address}`}
              onDelete={() => handleDeleteAddress("to_addresses", address)}
              color="primary"
            />
          ))}
          {sendInvoiceForm.cc_addresses.map((address) => (
            <Chip
              label={`CC: ${address}`}
              onDelete={() => handleDeleteAddress("cc_addresses", address)}
            />
          ))}
          {sendInvoiceForm.bcc_addresses.map((address) => (
            <Chip
              label={`BCC: ${address}`}
              onDelete={() => handleDeleteAddress("bcc_addresses", address)}
            />
          ))}
          {invoiceRecipientTypes.map((type) =>
            formValidation[type]?.error ? (
              <div className={classes.error}>
                {formValidation[type]?.errorText}
              </div>
            ) : (
              <></>
            )
          )}
          <div className={classes.field}>
            <InputLabel>{t("addRecipientLabel")}</InputLabel>
            <Grid container>
              <Grid item xs={7} sm={7}>
                <TextField
                  data-cy="send-invoice-form-add_address"
                  fullWidth
                  className={classes.addressField}
                  value={addAddressForm.add_address}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    setAddAddressForm((form) => ({
                      ...form,
                      add_address: event.target.value
                    }))
                  }
                  onBlur={(event) =>
                    validate(event.target.value, "add_address")
                  }
                  error={formValidation.add_address?.error}
                  helperText={formValidation.add_address?.errorText}
                />
              </Grid>
              <Grid item xs={2} sm={2}>
                <Select
                  fullWidth
                  className={classes.selectField}
                  value={addAddressForm.type}
                  onChange={handleSelect}
                  error={formValidation.type?.error}
                >
                  {invoiceRecipientTypes
                    .map((type) => ({
                      label: t(type),
                      value: type
                    }))
                    .map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
              <Grid item xs={3} sm={3} className={classes.addButtonContainer}>
                <Button
                  disabled={
                    !(
                      addAddressForm.add_address !== "" &&
                      formValidation.add_address &&
                      !formValidation.add_address.error
                    )
                  }
                  className={classes.addButton}
                  variant="contained"
                  onClick={handleAddAddress}
                >
                  <Add />
                  {t("addButton")}
                </Button>
              </Grid>
            </Grid>
          </div>

          <div className={classes.field}>
            <InputLabel>{t("subjectLabel")}</InputLabel>
            <TextField
              data-cy="send-invoice-form-subject"
              defaultValue={sendInvoiceForm.subject}
              fullWidth
              onBlur={(event) => {
                validate(event.target.value, "subject");
                setSendInvoiceForm((f) => ({
                  ...f,
                  subject: event.target.value
                }));
              }}
              error={formValidation.subject?.error}
              helperText={formValidation.subject?.errorText}
            />
          </div>
          <div className={classes.field}>
            <InputLabel>{t("bodyLabel")}</InputLabel>
            <Editor
              toolbar={{
                options: ["inline", "blockType", "list", "remove", "history"],
                inline: {
                  options: ["bold", "italic"]
                },
                blockType: {
                  inDropdown: false,
                  options: ["Normal", "H1", "H2", "H3"]
                }
              }}
              editorState={editorState}
              onEditorStateChange={(editorState) => {
                setEditorState(editorState);
              }}
              editorClassName={classes.editor}
            />
          </div>
        </DialogContent>
      )}
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          aria-label="cancel"
          onClick={() => onClose()}
          color="primary"
        >
          {t("cancelLabel")}
        </Button>
        <Button
          disabled={
            Object.values(formValidation)
              .map((validation) => validation.error)
              .includes(true) || sendLoading
          }
          variant="contained"
          aria-label="confirm"
          startIcon={sendLoading ? <CircularProgress size={20} /> : undefined}
          onClick={() => sendInvoiceEmail()}
          color="primary"
        >
          {t("sendButton")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendInvoiceDialog;
