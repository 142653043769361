import { createSuccessActionType } from "../actionTypes";
import { LoadingBooleansState, Action } from "../../types";
import * as actionTypes from "./actionTypes";
import { RestStrings, ReduxActionStrings } from "../../strings";

const initialState: LoadingBooleansState = {
  loadingRouteSegmentsTasks: true,
  loadingRoute: true,
  loadingRouteplan: true,
  loadingRouteplans: true,
  loadingRouteinstance: true,
  loadingRouteinstances: true,
  loadingContract: true,
  loadingContracts: true,
  loadingContractItems: true,
  loadingTaskCooperations: true,
  loadingTaskContractorCooperations: true,
  loadingTaskServices: true,
  loadingTask: true,
  loadingTasks: true,
  loadingCustomerobject: true,
  loadingCustomerobjects: true,
  loadingCompany: true,
  loadingCompanies: true,
  loadingPasscard: true,
  loadingPasscards: true,
  loadingDistrict: true,
  loadingDistricts: true,
  loadingWeatherArea: true,
  loadingWeatherAreas: true,
  loadingLocation: true,
  loadingLocations: true,
  loadingInvoicingProfile: true,
  loadingInvoicingProfiles: true,
  loadingContractingProfile: true,
  loadingContractingProfiles: true,
  loadingHolidays: true,
  loadingBusinessArea: true,
  loadingBusinessAreas: true,
  loadingServiceCategories: true,
  loadingTaskOptions: true,
  loadingProject: true,
  loadingProjects: true,
  loadingCooperation: true,
  loadingCooperations: true,
  loadingProspect: true,
  loadingProspects: true,
  loadingTemplateImage: true,
  loadingTemplateImages: true,
  loadingTerm: true,
  loadingTerms: true,
  loadingGroup: true,
  loadingGroups: true,
  loadingUser: true,
  loadingUsers: true,
  loadingNotificationsProfiles: true,
  loadingPerson: true,
  loadingPersons: true,
  loadingServices: true,
  loadingMaterials: true,
  loadingAccessories: true,
  loadingAddons: true,
  loadingHistory: true,
  loadingEmails: true
};

function reducer(state = initialState, action: Action<any>) {
  switch (action.type) {
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ROUTESEGMENTS_TASKS
    ):
      return { ...state, loadingRouteSegmentsTasks: false };
    case actionTypes.RESET_LOADING_ROUTESEGMENTS_TASKS:
      return { ...state, loadingRouteSegmentsTasks: true };
    case actionTypes.RESET_LOADING_TASK_COOPERATIONS:
      return { ...state, loadingTaskContractorCooperations: true };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.TASK_CONTRACTOR_COOPERATIONS
    ):
      return { ...state, loadingTaskContractorCooperations: false };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.TASK_SERVICES
    ):
      return { ...state, loadingTaskServices: false };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.TASK):
      return { ...state, loadingTask: false };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.TASKS):
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.TASKS):
    case createSuccessActionType(RestStrings.PATCH, ReduxActionStrings.TASK):
    case createSuccessActionType(RestStrings.DELETE, ReduxActionStrings.TASK):
      return { ...state, loadingTasks: false };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.ROUTEPLAN):
      return { ...state, loadingRouteplan: false };
    case actionTypes.RESET_ROUTEPLAN:
      return { ...state, loadingRouteplan: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ROUTEPLANS
    ):
      return { ...state, loadingRouteplans: false };
    case actionTypes.RESET_ROUTEPLANS:
      return { ...state, loadingRouteplans: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ROUTEINSTANCE
    ):
      return { ...state, loadingRouteinstance: false };
    case actionTypes.RESET_ROUTE:
    case actionTypes.RESET_ROUTEINSTANCE:
      return { ...state, loadingRouteinstance: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ROUTEINSTANCES
    ):
      return { ...state, loadingRouteinstances: false };
    case actionTypes.RESET_ROUTEINSTANCES:
      return { ...state, loadingRouteinstances: true };

    case actionTypes.RESET_TASK:
      return { ...state, loadingTask: true };
    case actionTypes.RESET_TASKS:
      return { ...state, loadingTasks: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.COMPANY):
      return { ...state, loadingCompany: false };
    case actionTypes.RESET_COMPANY:
      return { ...state, loadingCompany: true };

    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.CONTRACTOR_COOPERATIONS
    ):
    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.CONTRACTOR_COOPERATIONS
    ):
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.COMPANIES):
      return { ...state, loadingCompanies: false };
    case actionTypes.RESET_COMPANIES:
      return { ...state, loadingCompanies: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PASSCARD):
      return { ...state, loadingPasscard: false };
    case actionTypes.RESET_PASSCARD:
      return { ...state, loadingPasscard: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PASSCARDS):
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.PASSCARD):
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.PASSCARD
    ):
    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.PASSCARD
    ):
      return { ...state, loadingPasscards: false };
    case actionTypes.RESET_PASSCARDS:
      return { ...state, loadingPasscards: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.DISTRICT):
      return { ...state, loadingDistrict: false };
    case actionTypes.RESET_DISTRICT:
      return { ...state, loadingDistrict: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.DISTRICTS):
      return { ...state, loadingDistricts: false };
    case actionTypes.RESET_DISTRICTS:
      return { ...state, loadingDistricts: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.HOLIDAYS):
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.HOLIDAY):
    case createSuccessActionType(RestStrings.PATCH, ReduxActionStrings.HOLIDAY):
    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.HOLIDAY
    ):
      return { ...state, loadingHolidays: false };
    case actionTypes.RESET_HOLIDAYS:
      return { ...state, loadingHolidays: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.WEATHERAREA
    ):
      return { ...state, loadingWeatherArea: false };
    case actionTypes.RESET_WEATHERAREA:
      return { ...state, loadingWeatherArea: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.WEATHERAREAS
    ):
      return { ...state, loadingWeatherAreas: false };
    case actionTypes.RESET_WEATHERAREAS:
      return { ...state, loadingWeatherAreas: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.LOCATION):
      return { ...state, loadingLocation: false };
    case actionTypes.RESET_LOCATION:
      return { ...state, loadingLocation: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.LOCATIONS):
      return { ...state, loadingLocations: false };
    case actionTypes.RESET_LOCATIONS:
      return { ...state, loadingLocations: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.GROUP):
      return { ...state, loadingGroup: false };
    case actionTypes.RESET_GROUP:
      return { ...state, loadingGroup: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.GROUPS):
      return { ...state, loadingGroups: false };
    case actionTypes.RESET_GROUPS:
      return { ...state, loadingGroups: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.PARAMS_USER
    ):
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.USER):
      return { ...state, loadingUser: false };
    case actionTypes.RESET_USER:
      return { ...state, loadingUser: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.USERS):
      return { ...state, loadingUsers: false };
    case actionTypes.RESET_USERS:
      return { ...state, loadingUsers: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.NOTIFICATIONSPROFILES
    ):
      return { ...state, loadingNotificationsProfiles: false };
    case actionTypes.RESET_NOTIFICATIONSPROFILES:
      return { ...state, loadingNotificationsProfiles: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PERSON):
      return { ...state, loadingPerson: false };
    case actionTypes.RESET_PERSON:
      return { ...state, loadingPerson: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PERSONS):
    case createSuccessActionType(RestStrings.POST, ReduxActionStrings.PERSON):
    case createSuccessActionType(RestStrings.PATCH, ReduxActionStrings.PERSON):
    case createSuccessActionType(RestStrings.DELETE, ReduxActionStrings.PERSON):
      return { ...state, loadingPersons: false };
    case actionTypes.RESET_PERSONS:
      return { ...state, loadingPersons: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.INVOICINGPROFILE
    ):
      return { ...state, loadingInvoicingProfile: false };
    case actionTypes.RESET_INVOICINGPROFILE:
      return { ...state, loadingInvoicingProfile: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.INVOICINGPROFILES
    ):
      return { ...state, loadingInvoicingProfiles: false };
    case actionTypes.RESET_INVOICINGPROFILES:
      return { ...state, loadingInvoicingProfiles: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CONTRACTINGPROFILE
    ):
      return { ...state, loadingContractingProfile: false };
    case actionTypes.RESET_CONTRACTINGPROFILE:
      return { ...state, loadingContractingProfile: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CONTRACTINGPROFILES
    ):
      return { ...state, loadingContractingProfiles: false };
    case actionTypes.RESET_CONTRACTINGPROFILES:
      return { ...state, loadingContractingProfiles: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.BUSINESSAREA
    ):
      return { ...state, loadingBusinessArea: false };
    case actionTypes.RESET_BUSINESSAREA:
      return { ...state, loadingBusinessArea: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.BUSINESSAREAS
    ):
      return { ...state, loadingBusinessAreas: false };
    case actionTypes.RESET_BUSINESSAREAS:
      return { ...state, loadingBusinessAreas: true };

    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.SERVICECATEGORIES
    ):
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.SERVICECATEGORY
    ):
    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.SERVICECATEGORY
    ):
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.SERVICECATEGORIES
    ):
      return { ...state, loadingServiceCategories: false };
    case actionTypes.RESET_SERVICECATEGORIES:
      return { ...state, loadingServiceCategories: true };

    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.TASKOPTIONS
    ):
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.TASKOPTION
    ):
    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.TASKOPTION
    ):
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.TASKOPTIONS
    ):
      return { ...state, loadingTaskOptions: false };
    case actionTypes.RESET_TASKOPTIONS:
      return { ...state, loadingTaskOptions: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PROJECT):
      return { ...state, loadingProject: false };
    case actionTypes.RESET_PROJECT:
      return { ...state, loadingProject: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PROJECTS):
      return { ...state, loadingProjects: false };
    case actionTypes.RESET_PROJECTS:
      return { ...state, loadingProjects: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.COOPERATION
    ):
      return { ...state, loadingCooperation: false };
    case actionTypes.RESET_COOPERATION:
      return { ...state, loadingCooperation: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.COOPERATIONS
    ):
      return { ...state, loadingCooperations: false };
    case actionTypes.RESET_COOPERATIONS:
      return { ...state, loadingCooperations: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PROSPECT):
      return { ...state, loadingProspect: false };
    case actionTypes.RESET_PROSPECT:
      return { ...state, loadingProspect: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.PROSPECTS):
      return { ...state, loadingProspects: false };
    case actionTypes.RESET_PROSPECTS:
      return { ...state, loadingProspects: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.TEMPLATEIMAGE
    ):
      return { ...state, loadingTemplateImage: false };
    case actionTypes.RESET_TEMPLATEIMAGE:
      return { ...state, loadingTemplateImage: true };

    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.TEMPLATEIMAGES
    ):
      return { ...state, loadingTemplateImages: false };
    case actionTypes.RESET_TEMPLATEIMAGES:
      return { ...state, loadingTemplateImages: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.EMAILS):
      return { ...state, loadingEmails: false };
    case actionTypes.RESET_EMAILS:
      return { ...state, loadingEmails: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.TERM):
      return { ...state, loadingTerm: false };
    case actionTypes.RESET_TERM:
      return { ...state, loadingTerm: true };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.TERMS):
      return { ...state, loadingTerms: false };
    case actionTypes.RESET_TERMS:
      return { ...state, loadingTerms: true };

    case actionTypes.RESET_CONTRACT:
      return { ...state, loadingContract: true };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.CONTRACT):
      return { ...state, loadingContract: false };

    case actionTypes.RESET_CONTRACTS:
      return { ...state, loadingContracts: true };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.CONTRACTS):
      return { ...state, loadingContracts: false };

    case actionTypes.RESET_CONTRACT_ITEMS:
      return { ...state, loadingContractItems: true };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CONTRACT_ITEMS
    ):
      return { ...state, loadingContractItems: false };

    case actionTypes.RESET_LOADING_SERVICES:
      return { ...state, loadingServices: true };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.SERVICES):
      return { ...state, loadingServices: false };

    case actionTypes.RESET_LOADING_MATERIALS:
      return { ...state, loadingMaterials: true };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.MATERIALS):
      return { ...state, loadingMaterials: false };

    case actionTypes.RESET_LOADING_ACCESSORIES:
      return { ...state, loadingAccessories: true };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.ACCESSORIES
    ):
      return { ...state, loadingAccessories: false };

    case actionTypes.RESET_LOADING_ADDONS:
      return { ...state, loadingAddons: true };
    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.ADDONS):
      return { ...state, loadingAddons: false };

    case actionTypes.RESET_CUSTOMEROBJECT:
      return { ...state, loadingCustomerobject: true };
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CUSTOMEROBJECT
    ):
      return { ...state, loadingCustomerobject: false };

    case actionTypes.RESET_CUSTOMEROBJECTS:
      return { ...state, loadingCustomerobjects: true };
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.CUSTOMEROBJECT_COOPERATIONS
    ):
    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.CUSTOMEROBJECT_COOPERATIONS
    ):
    case createSuccessActionType(
      RestStrings.GET,
      ReduxActionStrings.CUSTOMEROBJECTS
    ):
    case createSuccessActionType(
      RestStrings.POST,
      ReduxActionStrings.CUSTOMEROBJECT
    ):
    case createSuccessActionType(
      RestStrings.PATCH,
      ReduxActionStrings.CUSTOMEROBJECT
    ):
    case createSuccessActionType(
      RestStrings.DELETE,
      ReduxActionStrings.CUSTOMEROBJECT
    ):
      return { ...state, loadingCustomerobjects: false };

    case createSuccessActionType(RestStrings.GET, ReduxActionStrings.HISTORY):
      return { ...state, loadingHistory: false };
    case actionTypes.RESET_HISTORY:
      return { ...state, loadingHistory: true };

    default:
      return state;
  }
}

export default reducer;
