import React, { useEffect, useState } from "react";
import {
  Accessory,
  Addon,
  Contract,
  IContractItemType,
  IContractSummaryType,
  Material,
  Service,
  ServiceCategory,
  Servicepackage,
  Supplement,
  TabComponentProps
} from "../../../redux/types";
import {
  getContractAPI,
  getAllServiceCategories,
  getContractItemGenericAPI,
  getBusinessareasAPI
} from "../../../services/api-declaration";
import { Button, WithStyles } from "@material-ui/core";
import { useTranslate } from "../../../services/appLanguageService";
import { Link, LinkProps, useHistory } from "react-router-dom";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import ServiceView from "../../../components/contracts/services/Service";
import MaterialView from "../../../components/contracts/materials/Material";
import AccessoryView from "../../../components/contracts/accessories/Accessory";
import SupplementView from "../../../components/contracts/supplements/Supplement";
import AddonView from "../../../components/contracts/addons/Addon";
import ServicepackageView from "../../../components/contracts/servicepackages/Servicepackage";
import DeleteButtonContainer from "../../DeleteButtonContainer";
import CopyContractItemDialog from "../../../components/contracts/contracts/CopyContractItemDialog";
import { contractItemTypeToApi } from "../../../components/contracts/contracts/contractItemTypeToAPI";

const styles = (theme: any) =>
  createStyles({
    btnContainer: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        overflowX: "auto",
        width: "100%"
      }
    },
    btnSpacing: {
      minWidth: "auto",
      marginLeft: 20,
      justifyContent: "spaceBetween"
    }
  });

type ContractItemContainerProps = TabComponentProps<
  "contractId" | "contractItemId"
> &
  WithStyles<typeof styles>;

const ContractItemContainer: React.FC<ContractItemContainerProps> = ({
  classes,
  tabId,
  routeParams: { contractId, contractItemId },
  actualPath
}) => {
  const [contract, setContract] = useState<Contract>();
  const [initialValues, setInitialValues] = useState<{ price: number, name: string }>()
  const [open, setOpen] = useState(false);
  const [serviceCategories, setServicecategories] = useState<ServiceCategory[]>(
    []
  );
  const [businessAreaNames, setBusinessAreaNames] = useState<Map<number, string>>();
  const [contractItem, setContractItem] = useState<IContractItemType>();
  const history = useHistory();
  const t = useTranslate("ViewActionButtons");

  const backUrl = `contracts/contracts/${contractId}`;

  const contractItemType = ((): IContractSummaryType => {
    if (actualPath.includes("services")) {
      return "SERVICE";
    } else if (actualPath.includes("materials")) {
      return "MATERIAL";
    } else if (actualPath.includes("accessories")) {
      return "ACCESSORY";
    } else if (actualPath.includes("supplements")) {
      return "SUPPLEMENT";
    } else if (actualPath.includes("addons")) {
      return "ADDON";
    } else if (actualPath.includes("servicepackages")) {
      return "SERVICEPACKAGE";
    } else {
      throw Error("Not implemented");
    }
  })();

  useEffect(() => {
    let alive = true;
    void Promise.all([
      getContractAPI(contractId),
      getAllServiceCategories(),
      getContractItemGenericAPI(contractItemType, contractItemId),
      getBusinessareasAPI()
    ]).then(([contract, { results: serviceCategories }, contractItem, { results: businessAreas }]) => {
      if (alive) {
        setContract(contract);
        setServicecategories(serviceCategories);
        setBusinessAreaNames(new Map(businessAreas.map(ba => [ba.id, ba.name])))
        setInitialValues({ name: contractItem.name, price: contractItem.price })
        setContractItem(contractItem);
      }
    });
    return () => {
      alive = false;
    };
  }, [contractId, contractItemType, contractItemId]);

  const handleClick = () => {
    setOpen(!open);
  };

  const onCopy = (itemId: number, type: IContractSummaryType) => {
    history.replace(
      `/contracts/${contractId}/${contractItemTypeToApi[type]}/${itemId}`
    );
    setOpen(!open);
  }

  return (
    <>
      <div className={classes.btnContainer}>
        <Button
          variant="contained"
          color="primary"
          component={React.forwardRef<HTMLAnchorElement, Partial<LinkProps>>(
            (props, ref) => (
              <Link
                to={`/${backUrl}?same_tab=true`}
                {...props}
                ref={ref as any}
              />
            )
          )}
        >
          <NavigateBeforeIcon /> {contract?.name}
        </Button>

        <Button
          variant="contained"
          color="primary"
          className={classes.btnSpacing}
          onClick={handleClick}
        >
          <FileCopyIcon />
          {t("copyButtonLabel")}
        </Button>

        <div className={classes.btnSpacing}>
          <DeleteButtonContainer
            resourceId={contractItemId}
            resource={contractItemTypeToApi[contractItemType]}
            url={backUrl}
            backendUrl={`contracts/${contractItemTypeToApi[contractItemType]}`}
          />
        </div>
      </div>
      {open && contract && initialValues && businessAreaNames && (
        <CopyContractItemDialog
          contractId={contract.id}
          onCopy={onCopy}
          handleClose={handleClick}
          itemType={contractItemType}
          itemId={contractItemId}
          serviceCategories={serviceCategories}
          businessAreaNames={businessAreaNames}
          initialValues={initialValues}
        />
      )}

      {actualPath.includes("services") && contractItem && businessAreaNames && (
        <ServiceView
          tabId={tabId}
          contract={contract}
          service={contractItem as Service}
          businessAreaNames={businessAreaNames}
        />
      )}

      {actualPath.includes("materials") && contractItem && businessAreaNames && (
        <MaterialView
          tabId={tabId}
          material={contractItem as Material}
          contract={contract}
          businessAreaNames={businessAreaNames}
        />
      )}

      {actualPath.includes("accessories") && contractItem && businessAreaNames && (
        <AccessoryView
          tabId={tabId}
          accessory={contractItem as Accessory}
          contract={contract}
          businessAreaNames={businessAreaNames}
        />
      )}

      {actualPath.includes("supplements") && contractItem && (
        <SupplementView
          tabId={tabId}
          supplement={contractItem as Supplement}
          contract={contract}
        />
      )}

      {actualPath.includes("addons") && contractItem && (
        <AddonView tabId={tabId} addon={contractItem as Addon} contract={contract} />
      )}

      {actualPath.includes("servicepackages") && contractItem && businessAreaNames && (
        <ServicepackageView
          tabId={tabId}
          servicepackage={contractItem as Servicepackage}
          contract={contract}
          businessAreaNames={businessAreaNames}
        />
      )}
    </>
  );
};

export default withStyles(styles)(ContractItemContainer);
