import React, { useState, useEffect } from "react";
import {
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Box,
  Chip,
  useTheme
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import { Formik, Form, FormikProps } from "formik";
import {
  IDataCarryKey,
  TimeSheetOptions,
  LocationMark,
  IApprovalCorrectionsKey,
  IApprovalCorrections
} from "../../redux/types";
import { Grid } from "@material-ui/core";
import { EditModeContainer } from "./editModeContainer";
import GoogleMaps from "../googleMaps/GoogleMaps";
import TimesheetRowMaterialAddonComponent from "../timesheet-row-add-material/timesheetRowAddMaterial";
import { generateTaskLocation } from "../../helpers/mapsHelperService";
import { useTranslate } from "../../services/appLanguageService";
import RowApprovalEditModeButtons from "./rowApprovalEditModeButtons";
import ApprovalTypeSelect from "../routes/routeinstances/ApprovalTypeSelect";
import { VerificationUpdatedAt } from "../../redux/reducers/verificationCount/action";
import store from "../../redux/store";
import WorkApproval from "../../helpers/classes/verification/WorkApproval";
import CorrectionsCarry from "../../helpers/classes/verification/CorrectionsCarry";
import DateTimeFragment from "./dateTimeFragment";
import { PACKAGE_ENUM } from "../../strings/WorkApprovalStrings";
import PackageCarry from "../../helpers/classes/verification/PackageCarry";

const useStyles = makeStyles((theme: any) => ({
  viewOne: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    position: "sticky",
    top: theme.spacing(0),
    backgroundColor: "white",
    zIndex: 1,
    paddingTop: theme.spacing(1.5)
  },
  editingModeText: {
    marginRight: theme.spacing(1.5),
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(1)
  },
  formControl: {
    width: "100%",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
    display: "flex",
    flexDirection: "row"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  borderTop: {
    width: "100%",
    marginTop: theme.spacing(1),
    borderBottom: "1px solid #a7a7a7"
  },
  closeIconContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  chip: { margin: 2 },
  fieldCell: { position: "relative" },
  mb8: { marginBottom: theme.spacing(1) },
  field: { marginTop: theme.spacing(0.5) },
  dialogForm: { padding: "0 20px 20px 20px" },
  modifyBtn: { marginTop: theme.spacing(1.2) }
}));

export interface TimesheetApprovalDialogProps {
  options: TimeSheetOptions;
  editableFields: IApprovalCorrectionsKey[];
  defaultCorrectionsCarry: CorrectionsCarry;
  workApproval: WorkApproval;
  packageCarry: PackageCarry | undefined;
  disabledMode?: IDataCarryKey;
  onClose: (correctionsCarry: CorrectionsCarry) => void;
  onSuccess: (result: any) => void;
}

const getStyles = (id: string, theme: any, selections?: number[]) => ({
  fontWeight:
    selections && selections.includes(Number(id))
      ? theme.typography.fontWeightBold
      : theme.typography.fontWeightRegular
});

export default function TimeSheetApprovalDialog(
  props: TimesheetApprovalDialogProps
) {
  const classes = useStyles();
  const theme = useTheme();
  const {
    options,
    editableFields,
    defaultCorrectionsCarry,
    workApproval,
    packageCarry,
    disabledMode: disableMode,
    onClose,
    onSuccess
  } = props;
  const [editContext, setEditContext] = useState({
    correctionsCarry: defaultCorrectionsCarry
  });
  const { correctionsCarry } = editContext;
  const { corrections } = correctionsCarry;

  const [markers, setMarkers] = useState<LocationMark[]>();
  const t = useTranslate("TimeSheetRowsAndApprovals");
  const packageLabelsHash = {
    corrections: t("typeLabel"),
    contractor_payables: t("typeSLabel"),
    customer_billables: t("typeCLabel")
  };

  const [availableServicecategories, setAvailableServicecategories] = useState<
    number[]
  >([]);

  const handleEditModeChange = (editMode: IDataCarryKey) => {
    if (editMode === disableMode) {
      return;
    }
    setEditContext(({ correctionsCarry }) => ({
      correctionsCarry: correctionsCarry.editModeChanged(editMode)
    }));
  };

  const handleDialogFieldValueChange = (
    fieldName: IApprovalCorrectionsKey,
    value: any
  ) =>
    setEditContext(({ correctionsCarry }) => ({
      correctionsCarry: correctionsCarry.correctionChanged(fieldName, value)
    }));

  const resetFormChanges = () => {
    if (
      packageCarry &&
      editContext.correctionsCarry.editMode !== "corrections"
    ) {
      packageCarry.setCurrentPackage(
        PACKAGE_ENUM[editContext.correctionsCarry.editMode],
        undefined
      );
    }
    setEditContext(({ correctionsCarry }) => ({
      correctionsCarry: correctionsCarry.resetChanges()
    }));
  };

  const handleSubmit = async () => {
    try {
      if (packageCarry) {
        await packageCarry.savePackages();
      }

      const response = await correctionsCarry.saveWorkApproval();
      onSuccess(response);
      store.dispatch(VerificationUpdatedAt());
    } catch (err) {
      console.warn(err);
    }
  };

  const handlSelectChange =
    (
      fieldName: IApprovalCorrectionsKey,
      formikProps: FormikProps<IApprovalCorrections>
    ) =>
    ({ target: { value } }: React.ChangeEvent<{ value: any }>) => {
      formikProps.setFieldValue(fieldName, value);
      handleDialogFieldValueChange(fieldName, value);
      if (fieldName === "service") {
        updateAvailableServicecategories(value, formikProps);
      }
    };

  const updateAvailableServicecategories = (
    serviceId: number,
    formikProps?: FormikProps<IApprovalCorrections>
  ) => {
    const service = Object.entries(options.contracted.services).find(
      ([_serviceId, service]) => +_serviceId === serviceId
    );

    if (service) {
      setAvailableServicecategories(service[1].categories);

      if (
        formikProps &&
        service[1].categories.length === 1 &&
        formikProps.values.servicecategory !== service[1].categories[0]
      ) {
        formikProps.setFieldValue("servicecategory", service[1].categories[0]);
        handleDialogFieldValueChange(
          "servicecategory",
          service[1].categories[0]
        );
      }
    }
  };

  const displayTaskLocation = () => {
    if (corrections.task) {
      const drawingData = generateTaskLocation(
        corrections.task,
        options.contracted.tasks
      );
      if (corrections.reported_longitude && corrections.reported_latitude) {
        drawingData.push({
          position: {
            lng: corrections.reported_longitude,
            lat: corrections.reported_latitude
          },
          markerOptions: {
            title: t("reportedLocationLabel"),
            label: "R"
          }
        });
      }
      setMarkers(drawingData);
    }
  };

  const renderAccessoryChip = (selected: unknown) => (
    <div className={classes.chips}>
      {(selected as string[]).map((value) => (
        <Chip
          key={value}
          className={classes.chip}
          label={options.contracted.accessories[value].name}
        />
      ))}
    </div>
  );

  useEffect(
    () => updateAvailableServicecategories(corrections.service),
    [corrections.service]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(displayTaskLocation, [corrections.task]);

  const defaultMapOptions: google.maps.MapOptions = {
    mapTypeId: "hybrid",
    maxZoom: 17
  };

  return (
    <>
      <div className={classes.closeIconContainer}>
        <IconButton onClick={() => onClose(editContext.correctionsCarry)}>
          <CloseIcon />
        </IconButton>
      </div>

      <Grid container direction="row">
        <Grid item xs={12} md={8}>
          <div className={classes.viewOne}>
            <p className={classes.editingModeText}>{`${t(
              "selectEditingModeHeading"
            )}:`}</p>
            <RowApprovalEditModeButtons
              disabledMode={disableMode}
              handleEditModeChange={handleEditModeChange}
              resetFormChanges={resetFormChanges}
              selectedEditMode={correctionsCarry.editMode}
              inLineEdit={false}
            />
          </div>
          <Formik
            initialValues={corrections}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {(_props) => (
              <Form className={classes.dialogForm}>
                <>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <DateTimeFragment
                        fieldName="start_time"
                        fieldInitials="start"
                        fieldValue={_props.values.start_time}
                        correctionChanged={(fieldName, value) => {
                          _props.setFieldValue(fieldName, value);
                          handleDialogFieldValueChange(fieldName, value);
                        }}
                      />

                      <EditModeContainer
                        disabledEditMode={disableMode}
                        dataCarry={correctionsCarry.dataCarry}
                        fieldName={"start_time"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateTimeFragment
                        fieldName="end_time"
                        fieldInitials="end"
                        fieldValue={_props.values.end_time}
                        correctionChanged={(fieldName, value) => {
                          _props.setFieldValue(fieldName, value);
                          handleDialogFieldValueChange(fieldName, value);
                        }}
                      />

                      <EditModeContainer
                        disabledEditMode={disableMode}
                        dataCarry={correctionsCarry.dataCarry}
                        fieldName={"end_time"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.fieldCell}>
                      <FormControl
                        className={classes.formControl}
                        disabled={
                          Object.keys(options.contracted.tasks).length === 0 ||
                          !editableFields.includes("task")
                        }
                      >
                        <InputLabel htmlFor="task">
                          {t("selectTaskInputLabel")}
                        </InputLabel>
                        <Select
                          name="task"
                          value={_props.values.task || ""}
                          className={`${classes.field}`}
                          onChange={handlSelectChange("task", _props)}
                          type="number"
                          placeholder={t("selectTaskPlaceholder")}
                          fullWidth
                        >
                          {Object.entries(options.contracted.tasks).map(
                            ([taskId, task]) => (
                              <MenuItem key={taskId} value={Number(taskId)}>
                                {task.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                      <EditModeContainer
                        disabledEditMode={disableMode}
                        dataCarry={correctionsCarry.dataCarry}
                        fieldName={"task"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.fieldCell}>
                      <FormControl
                        className={classes.formControl}
                        disabled={
                          (options.contracted.services &&
                            Object.keys(options.contracted.services).length ===
                              0) ||
                          !editableFields.includes("service")
                        }
                      >
                        <InputLabel htmlFor="service">
                          {t("selectServiceInputLabel")}
                        </InputLabel>
                        <Select
                          disabled
                          name="service"
                          value={_props.values.service || ""}
                          onChange={handlSelectChange("service", _props)}
                          className={`${classes.field}`}
                          type="number"
                          placeholder={"Service"}
                          fullWidth
                        >
                          {options.contracted.services &&
                            Object.entries(options.contracted.services).map(
                              ([serviceId, service]) => (
                                <MenuItem
                                  key={serviceId}
                                  value={Number(serviceId)}
                                >
                                  {service.name}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>
                      <EditModeContainer
                        disabledEditMode={disableMode}
                        dataCarry={correctionsCarry.dataCarry}
                        fieldName={"service"}
                      />
                    </Grid>
                    {/* <Grid item xs={12} className={classes.mb8}>
                      <TimesheetRowMaterialAddonComponent
                        options={options.contracted.accompanying_persons}
                        fieldName="accompanying_people"
                        fieldValue={_props.values.accompanying_people}
                        correctionChanged={(fieldName, value) => {
                          _props.setFieldValue(fieldName, value);
                          handleDialogFieldValueChange(fieldName, value);
                        }}
                        disabled={
                          !editableFields.includes("accompanying_people")
                        }
                      />
                      <EditModeContainer
                        disabledEditMode={disableMode}
                        dataCarry={correctionsCarry.dataCarry}
                        fieldName={"accompanying_people"}
                      />
                    </Grid> */}
                    <Grid item xs={12} sm={6} className={classes.fieldCell}>
                      <FormControl
                        className={classes.formControl}
                        disabled={
                          (options.contracted.accessories &&
                            Object.keys(options.contracted.accessories)
                              .length === 0) ||
                          !editableFields.includes("accessories")
                        }
                      >
                        <InputLabel>
                          {t("selectAccessoryInputLabel")}
                        </InputLabel>
                        <Select
                          className={classes.field}
                          multiple
                          value={
                            _props.values.accessories
                              ? _props.values.accessories.filter(
                                  (value) =>
                                    options.contracted.accessories[value]
                                )
                              : []
                          }
                          onChange={handlSelectChange("accessories", _props)}
                          fullWidth
                          renderValue={renderAccessoryChip}
                        >
                          {options.contracted.accessories &&
                            Object.entries(options.contracted.accessories).map(
                              ([accessoryId, accessory]) => (
                                <MenuItem
                                  key={accessoryId}
                                  value={Number(accessoryId)}
                                  style={getStyles(
                                    accessoryId,
                                    theme,
                                    _props.values.accessories
                                  )}
                                >
                                  {accessory.name}
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>
                      <EditModeContainer
                        disabledEditMode={disableMode}
                        dataCarry={correctionsCarry.dataCarry}
                        fieldName={"accessories"}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.fieldCell}>
                      <FormControl
                        className={classes.formControl}
                        disabled={
                          (options.contracted.servicecategories &&
                            Object.keys(options.contracted.servicecategories)
                              .length === 0) ||
                          !editableFields.includes("servicecategory")
                        }
                      >
                        <InputLabel htmlFor="servicecategory">
                          {t("selectServiceCategoryInputLabel")}
                        </InputLabel>
                        <Select
                          name="servicecategory"
                          value={_props.values.servicecategory || ""}
                          onChange={handlSelectChange(
                            "servicecategory",
                            _props
                          )}
                          className={`${classes.field}`}
                          type="number"
                          placeholder={"Servicecategory"}
                          fullWidth
                        >
                          {options.contracted.servicecategories &&
                            Object.entries(options.contracted.servicecategories)
                              .filter(([servicecategoryId, servicecategory]) =>
                                availableServicecategories.includes(
                                  +servicecategoryId
                                )
                              )
                              .map(([servicecategoryId, servicecategory]) => (
                                <MenuItem
                                  key={servicecategoryId}
                                  value={Number(servicecategoryId)}
                                >
                                  {servicecategory.name}
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                      <EditModeContainer
                        disabledEditMode={disableMode}
                        dataCarry={correctionsCarry.dataCarry}
                        fieldName={"servicecategory"}
                      />
                    </Grid>
                    <Grid item xs={12} className={classes.mb8}>
                      <TimesheetRowMaterialAddonComponent
                        options={options.contracted.materials}
                        fieldName="materials"
                        fieldValue={_props.values.materials}
                        correctionChanged={(fieldName, value) => {
                          _props.setFieldValue(fieldName, value);
                          handleDialogFieldValueChange(fieldName, value);
                        }}
                        disabled={!editableFields.includes("materials")}
                      />
                      <EditModeContainer
                        disabledEditMode={disableMode}
                        dataCarry={correctionsCarry.dataCarry}
                        fieldName={"materials"}
                      />
                    </Grid>
                    {packageCarry && (
                      <>
                        <div className={classes.borderTop}></div>
                        <Grid item xs={12} sm={6} className={classes.fieldCell}>
                          <FormControl className={classes.formControl}>
                            <ApprovalTypeSelect
                              label={
                                packageLabelsHash[correctionsCarry.editMode]
                              }
                              packageType={
                                PACKAGE_ENUM[correctionsCarry.editMode]
                              }
                              packageCarry={packageCarry}
                              isDisabled={
                                correctionsCarry.editMode === "corrections"
                              }
                              onPackageCreated={(_packageCarry) => {}}
                            />
                          </FormControl>
                          <EditModeContainer
                            disabledEditMode={disableMode}
                            dataCarry={correctionsCarry.dataCarry}
                            checker={(editMode) =>
                              Boolean(
                                packageCarry.current[PACKAGE_ENUM[editMode]]
                              )
                            }
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <Button
                        id="new-user-form-submit"
                        data-cy="form-submit"
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.modifyBtn}
                      >
                        {t("modifyLabel")}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              </Form>
            )}
          </Formik>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            style={{
              height: "100%",
              padding: "0 20px 20px 0"
            }}
          >
            {markers && (
              <GoogleMaps
                id="approvalDialogMap"
                markers={markers}
                options={defaultMapOptions}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
