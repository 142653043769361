import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { ValidationError } from "yup";
import { format, isValid } from "date-fns";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  CircularProgress,
  makeStyles,
  createStyles,
  TextField
} from "@material-ui/core";
import { useTranslate } from "../../../services/appLanguageService";
import {
  createManualInvoiceAPI,
  getActiveContractsAPI,
  getCustomerobjectByCompanyIdsAPI,
  getCustomerobjectByIdsAPI
} from "../../../services/api-declaration";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";

interface CreateInvoiceDialogProps {
  invoiceType: "customerinvoices" | "contractorinvoices";
  onClose: () => void;
  onAdd: (contractItemId: number) => void;
}

const useModalStyles = makeStyles((theme) =>
  createStyles({
    content: {
      "& > div": {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(2)
      }
    },
    actions: {
      display: "flex",
      paddingRight: theme.spacing(3),
      paddingBottom: theme.spacing(2)
    }
  })
);

const CreateInvoiceDialog: React.FC<CreateInvoiceDialogProps> = ({
  invoiceType,
  onClose,
  onAdd
}) => {
  const [contracts, setContracts] =
    useState<Awaited<ReturnType<typeof getActiveContractsAPI>>["results"]>();
  const [customerObjects, setCustomerObjects] =
    useState<
      Awaited<ReturnType<typeof getCustomerobjectByIdsAPI>>["results"]
    >();
  const dialogRef = useRef<HTMLElement>();
  const now = new Date();
  const [state, setState] = useState<{
    contractId: number;
    objectId: number;
    periodStart: string;
    periodEnd: string;
  }>({
    contractId: 0,
    objectId: 0,
    periodStart: format(
      new Date(now.getFullYear(), now.getMonth() - 1, 1),
      "yyyy-MM-dd"
    ),
    periodEnd: format(
      new Date(now.getFullYear(), now.getMonth(), 0),
      "yyyy-MM-dd"
    )
  });
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>(
    {}
  );
  const [loading, setLoading] = useState(false);

  const classes = useModalStyles();
  const t = useTranslate("InvoicesView");

  const contractOptions = contracts?.map(({ id, name }) => ({
    id,
    label: name
  }));
  const objectOptions = customerObjects?.map(({ id, name }) => ({
    id,
    label: name
  }));

  useEffect(() => {
    let alive = true;

    void getActiveContractsAPI(
      invoiceType === "customerinvoices" ? "CUSTOMER" : "CONTRACTOR"
    ).then(({ results }) => alive && setContracts(results));

    return () => {
      alive = false;
    };
  }, [invoiceType]);

  useEffect(() => {
    const company =
      state.contractId &&
      contracts?.find((c) => c.id === state.contractId)?.company;
    if (company) {
      setCustomerObjects(undefined);
      let alive = true;
      void getCustomerobjectByCompanyIdsAPI([company]).then(({ results }) => {
        if (alive) {
          results.sort((a, b) => a.name.localeCompare(b.name));
          setCustomerObjects(results);
        }
      });
      return () => {
        alive = false;
      };
    } else {
      setCustomerObjects([]);
    }
  }, [state.contractId, contracts]);

  const periodStartParsed = new Date(state.periodStart);
  const schema = Yup.object({
    contractId: Yup.number().moreThan(0, t("fieldRequired")),
    objectId: Yup.number(),
    periodStart: Yup.date()
      .typeError(t("fieldRequired"))
      .required(t("fieldRequired")),
    periodEnd: Yup.date()
      .typeError(t("fieldRequired"))
      .min(
        isValid(periodStartParsed) ? periodStartParsed : new Date(),
        t("fieldPeriodEndAfterStart")
      )
      .required(t("fieldRequired"))
  });

  const validateFields = (
    patch: Partial<typeof state> | undefined,
    ...fields: (keyof typeof state)[]
  ): void => {
    let next = { ...errors };
    const patched = patch ? { ...state, ...patch } : state;
    void Promise.all(
      fields.map((field) =>
        schema
          .validateAt(field, patched)
          .then(() => field in next && delete next[field])
          .catch(
            (err: ValidationError) => (next[field] = err.errors.join(", "))
          )
      )
    ).then(() => setErrors(next));
  };

  const disabled = !state.contractId || !!Object.keys(errors).length;

  const handleCreate = () => {
    if (!disabled) {
      setLoading(true);
      void createManualInvoiceAPI(invoiceType, {
        contract: state.contractId,
        customerobject: customerObjects?.find((o) => o.id === state.objectId)
          ? state.objectId
          : null,
        period_start: state.periodStart,
        period_end: state.periodEnd,
        copy_from_invoice_id: null,
        copy_quantity_factor: null
      })
        .then(({ id }) => onAdd(id))
        .finally(() => setLoading(false));
    } else {
      validateFields(
        undefined,
        ...(Object.keys(state) as (keyof typeof state)[])
      );
    }
  };

  return (
    <Dialog ref={dialogRef} open={true} onClose={onClose} fullWidth>
      <DialogTitle>{t("createInvoiceTitle")}</DialogTitle>
      <DialogContent className={classes.content}>
        <div>
          <Autocomplete
            value={
              contractOptions?.find((o) => o.id === state.contractId) ?? null
            }
            options={contractOptions ?? []}
            loading={!contractOptions}
            onChange={(_, value) => {
              const next = {
                ...state,
                contractId: value?.id ?? 0
              };
              setState(next);
              validateFields(next, "contractId");
            }}
            renderInput={(params) => (
              <TextField {...params} label={t("contractLabel")} fullWidth />
            )}
            getOptionLabel={(option) => option.label}
            selectOnFocus
          />
          {!!errors["contractId"] && (
            <FormHelperText error>{errors["contractId"]}</FormHelperText>
          )}
        </div>

        <div>
          <Autocomplete
            value={objectOptions?.find((o) => o.id === state.objectId) ?? null}
            options={objectOptions ?? []}
            loading={!objectOptions}
            onChange={(_, value) => {
              const next = {
                ...state,
                objectId: value?.id ?? 0
              };
              setState(next);
              validateFields(next, "objectId");
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("customerObjectLabel")}
                fullWidth
              />
            )}
            getOptionLabel={(option) => option.label}
          />
          {!!errors["objectId"] && (
            <FormHelperText error>{errors["objectId"]}</FormHelperText>
          )}
        </div>

        <div>
          <KeyboardDatePicker
            label={t("periodStartLabel")}
            format="yyyy-MM-dd"
            value={state.periodStart}
            onChange={(_d, v) => {
              if (v) {
                const next = { ...state, periodStart: v };
                setState(next);
                validateFields(next, "periodStart");
              }
            }}
            onBlur={() => validateFields(undefined, "periodStart", "periodEnd")}
            fullWidth
            error={!!errors["periodStart"]}
            helperText={errors["periodStart"]}
          />
        </div>
        <div>
          <KeyboardDatePicker
            label={t("periodEndLabel")}
            format="yyyy-MM-dd"
            value={state.periodEnd}
            onChange={(_d, v) => {
              if (v) {
                const next = { ...state, periodEnd: v };
                setState(next);
                validateFields(next, "periodEnd");
              }
            }}
            fullWidth
            error={!!errors["periodEnd"]}
            helperText={errors["periodEnd"]}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          variant="contained"
          aria-label="cancel"
          onClick={() => onClose()}
          color="primary"
        >
          {t("cancelLabel")}
        </Button>
        <Button
          variant="contained"
          aria-label="add"
          startIcon={loading ? <CircularProgress size={20} /> : undefined}
          onClick={() => handleCreate()}
          disabled={loading}
          color="primary"
        >
          {t("addLabel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateInvoiceDialog;
