import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  WithStyles,
  Paper,
  Grid,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useTranslate } from "../../../services/appLanguageService";
import store from "../../../redux/store";
import { setTitle } from "../../../redux/reducers/tabRouter/tabTitle/actions";
import {
  BaseService,
  Contract,
  ContractBaseItem,
  Service,
  ServiceCategory,
  Task
} from "../../../redux/types";
import EditableTextField from "../../EditableTextField";
import EditableCheckBoxField from "../../EditableCheckBoxField";
import { niceDate } from "../../FormatHelpers";
import ResourceLink from "../../links/ResourceLink";
import AssociatedField from "../../AssociatedField";
import {
  getAllServiceCategories,
  getBaseServiceAPI,
  getContractAccessoriesAPI,
  getContractMaterialsAPI,
  getContractSupplementsAPI,
  patchServiceAPI,
  getTasksByFilterIds,
} from "../../../services/api-declaration";
import {
  useConfirmationDialog,
  ConfirmationDialogServiceProvider
} from "../../confirmationDialog/ConfirmationDialogServiceProvider";
import * as Yup from "yup";
import SimpleImageUpload from "../../imageUploadComponent/SimpleImageUpload";
import LockIcon from "@material-ui/icons/Lock";
import ContractItemTask from "../ContractItemTask";
import LoadingSpinnerPaper from "../../../components/LoadingSpinnerPaper";

const styles = (theme: any) =>
  createStyles({
    root: {
      flexGrow: 1
    },
    paper: {
      marginTop: 20
    },
    pageNotFound: {
      marginTop: 20,
      padding: 20
    },
    editButton: {
      marginLeft: 20
    },
    expandHeader: {
      color: theme.palette.text.secondary
    },
    logo: {
      maxWidth: 300,
      maxHeight: 300
    },
    bold: {
      fontWeight: "bold"
    },
    tableContainer: {
      padding: 0
    }
  });

interface ServiceProps extends WithStyles<typeof styles> {
  contract: Contract | undefined;
  tabId: number;
  service: Service;
  businessAreaNames: Map<number, string>;
}

const ServiceView: React.FC<ServiceProps> = (props) => {
  const { classes, tabId, contract, service: initialService, businessAreaNames } = props;
  const t = useTranslate("ServicePage");
  const t3 = useTranslate("ValidationErrorMessages");

  const [materials, setMaterials] = useState<ContractBaseItem[]>([]);
  const [materialsLoading, setMaterialsLoading] = useState<boolean>(false);
  const [accessories, setAccessories] = useState<ContractBaseItem[]>([]);
  const [accessoriesLoading, setAccessoriesLoading] = useState<boolean>(false);
  const [supplements, setSupplements] = useState<ContractBaseItem[]>([]);
  const [servicecategories, setServicecategories] = useState<ServiceCategory[]>(
    []
  );
  const [baseService, setBaseservice] = useState<BaseService>();
  const [supplementsLoading, setSupplementsLoading] = useState<boolean>(false);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const [service, setService] = useState<Service>(initialService);
  const [tasks, setTasks] = useState<Task[]>([]);

  const updateResourceState = (value: Service) => setService(value);

  const confirmationDialog = useConfirmationDialog();

  const patchUrl = `contracts/services/${initialService.id}/`;

  const [expanded, setExpanded] = useState(false);
  useEffect(() => {
    if (service) store.dispatch(setTitle(service.name, tabId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  useEffect(() => {
    let isAlive = true;

    if (contract?.id) {
      (async () => {
        const service = initialService;

        const [
          materialsResponse,
          supplementsResponse,
          accessoriesResponse,
          servicecategoriesResponse,
          baseServiceResponse,
        ] = await Promise.all([
          getContractMaterialsAPI({
            filter__contract: contract.id
          }),
          getContractSupplementsAPI(contract.id),
          getContractAccessoriesAPI(contract.id),
          getAllServiceCategories({ filter__id__in: service.categories }),
          getBaseServiceAPI(service.base),
        ]);

        const filterItem = (item: ContractBaseItem) =>
          service.task
            ? item.task === service.task || item.task === null
            : true;

        if (isAlive) {
          setMaterials(materialsResponse.results.filter(filterItem));
          setSupplements(supplementsResponse.results.filter(filterItem));
          setAccessories(accessoriesResponse.results.filter(filterItem));
          setServicecategories(servicecategoriesResponse.results);
          setBaseservice(baseServiceResponse);

          const taskIds = [
            ...new Set([
              ...(materialsResponse.results
                .map((m) => m.task)
                .filter((id) => id !== null) as number[]),
              ...(supplementsResponse.results
                .map((s) => s.task)
                .filter((id) => id !== null) as number[]),
              ...(accessoriesResponse.results
                .map((a) => a.task)
                .filter((id) => id !== null) as number[])
            ])
          ];

          if (taskIds.length > 0) {
            getTasksByFilterIds(taskIds).then((tasksResponse) => {
              setTasks(tasksResponse.results);
            });
          }
        }
      })();
    }

    return () => {
      isAlive = false;
    };
  }, [contract?.id, initialService]);

  const serviceIsLocked =
    contract?.locked_items["services"].includes(initialService.id) || false;

  const addMaterial = async (
    serviceId: number,
    currentMaterialIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("associateMaterialConfirmation")
    });
    setMaterialsLoading(true);

    const response = await patchServiceAPI(serviceId, {
      materials: currentMaterialIds
    });

    updateResourceState(response);
    setMaterialsLoading(false);
  };

  const removeMaterial = async (
    serviceId: number,
    currentMaterialIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("disassociateMaterialConfirmation")
    });
    setMaterialsLoading(true);
    const response = await patchServiceAPI(serviceId, {
      materials: currentMaterialIds
    });

    updateResourceState(response);
    setMaterialsLoading(false);
  };

  const addAccessory = async (
    serviceId: number,
    currentAccessoryIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("associateAccessoryConfirmation")
    });
    setAccessoriesLoading(true);

    const response = await patchServiceAPI(serviceId, {
      accessories: currentAccessoryIds
    });

    updateResourceState(response);
    setAccessoriesLoading(false);
  };

  const removeAccessory = async (
    serviceId: number,
    currentAccessoryIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("disassociateAccessoryConfirmation")
    });
    setAccessoriesLoading(true);
    const response = await patchServiceAPI(serviceId, {
      accessories: currentAccessoryIds
    });

    updateResourceState(response);
    setAccessoriesLoading(false);
  };

  const addSupplement = async (
    serviceId: number,
    currentSupplementIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("associateSupplementConfirmation")
    });
    setSupplementsLoading(true);

    const response = await patchServiceAPI(serviceId, {
      supplements: currentSupplementIds
    });

    updateResourceState(response);
    setSupplementsLoading(false);
  };

  const removeSupplement = async (
    serviceId: number,
    currentSupplementIds: number[]
  ) => {
    await confirmationDialog({
      title: t("confirmationTitle"),
      description: t("disassociateSupplementConfirmation")
    });
    setSupplementsLoading(true);
    const response = await patchServiceAPI(serviceId, {
      supplements: currentSupplementIds
    });

    updateResourceState(response);
    setSupplementsLoading(false);
  };

  const addImage = async (image: string) => {
    setImageLoading(true);
    const response = await patchServiceAPI(initialService.id, {
      image: image
    });

    updateResourceState(response);
    setImageLoading(false);
  };

  const deleteImage = async () => {
    setImageLoading(true);
    const response = await patchServiceAPI(initialService.id, {
      image: null
    });

    updateResourceState(response);
    setImageLoading(false);
  };

  const materialOptions = materials.map((material) => {
    const task = tasks.find((t) => t.id === material.task);
    const businessareaName = task ? businessAreaNames.get(task.businessarea) : '';
    return {
      value: material.id,
      label: `${material.name} ${
        material.task && task && businessareaName
          ? `(${task.name}) - ${businessareaName}`
          : ""
      }`
    };
  });

  const accessoryOptions = accessories.map((accessory) => {
    const task = tasks.find((t) => t.id === accessory.task);
    const businessareaName = task ? businessAreaNames.get(task.businessarea) : '';
    return {
      value: accessory.id,
      label: `${accessory.name} ${
        accessory.task && task && businessareaName
          ? `(${task.name}) - ${businessareaName}`
          : ""
      }`
    };
  });

  const supplementOptions = supplements.map((supplement) => {
    const task = tasks.find((t) => t.id === supplement.task);
    const businessareaName = task ? businessAreaNames.get(task?.businessarea) : '';
    return {
      value: supplement.id,
      label: `${supplement.name} ${
        supplement.task && task && businessareaName
          ? `(${task.name}) - ${businessareaName}`
          : ""
      }`
    };
  });

  if (!service) return <LoadingSpinnerPaper />;

  return service.contract !== contract?.id ? (
    <Paper className={classes.pageNotFound}>
      <Typography variant="h6">{t("pageNotFoundTitle")}</Typography>
    </Paper>
  ) : (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TableContainer component={Paper} className={classes.paper}>
            <Table size="small" aria-label="a dense table">
              <TableBody>
                <TableRow>
                  <TableCell align="left" className={classes.bold} colSpan={2}>
                    <SimpleImageUpload
                      handleImageSave={addImage}
                      handleImageDelete={deleteImage}
                      image={service.image}
                      alt={service.name}
                      isLoading={imageLoading}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" colSpan={2}>
                    <Typography variant="h5">
                      {contract?.name}: <b>{service.name}</b>{" "}
                      {serviceIsLocked && (
                        <Tooltip title={t("serviceLockedLabel")}>
                          <LockIcon />
                        </Tooltip>
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("nameLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {serviceIsLocked ? (
                      service.name
                    ) : (
                      <EditableTextField
                        field="name"
                        initValue={service.name}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .min(1, t3("minLengthError"))
                          .max(255, t3("max255LengthError"))
                          .required(t3("requiredError"))}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("contractLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {contract && (
                      <ResourceLink
                        label={contract.name}
                        id={contract.id}
                        url="contracts/contracts"
                      />
                    )}
                  </TableCell>
                </TableRow>
                {service.price !== null && (
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("priceLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {serviceIsLocked ? (
                        service.price
                      ) : (
                        <EditableTextField
                          isPrice
                          field="price"
                          initValue={service.price}
                          url={patchUrl}
                          updateResourceState={updateResourceState}
                          number={true}
                          validation={Yup.number()
                            .typeError(t3("numberRequiredError"))
                            .min(0, t3("minZeroError"))
                            .max(10000000, t3("max10000000NumberError"))
                            .required(t3("requiredError"))}
                        />
                      )}{" "}
                      kr {service.unit ? `/ ${service.unit}` : ""}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("baseServiceLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {baseService && (
                      <ResourceLink
                        url="contracts/baseservices"
                        label={baseService.name}
                        id={baseService.id}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left" className={classes.bold}>
                    {t("servicecategoriesLabel")}
                  </TableCell>
                  <TableCell align="right">
                    {servicecategories
                      .map((servicecategory) => servicecategory.name)
                      .join(", ")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={classes.expandHeader}
              onClick={() =>
                expanded ? setExpanded(false) : setExpanded(true)
              }
            >
              <Typography>
                {expanded ? t("hideLabel") : t("showMoreLabel")}
              </Typography>
            </AccordionSummary>
            <TableContainer
              component={AccordionDetails}
              className={classes.tableContainer}
            >
              <Table size="small" aria-label="a dense table">
                <TableBody>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("materialTransportLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="material_transport"
                        label={t("materialTransportLabel")}
                        initValue={service.material_transport}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("automaticTransportLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="automatic_transport"
                        label={t("automaticTransportLabel")}
                        initValue={service.automatic_transport}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("multipleDriversLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="multiple_drivers"
                        label={t("multipleDriversLabel")}
                        initValue={service.multiple_drivers}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("isAccompanyingPersonAllowedLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        readonly
                        field="is_accompanying_person_allowed"
                        label={t("isAccompanyingPersonAllowedLabel")}
                        initValue={service.is_accompanying_person_allowed}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("regNumberLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="reg_number"
                        initValue={service.reg_number}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .max(255, t3("max255LengthError"))
                          .nullable()
                          .notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("lengthLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="length"
                        initValue={service.length}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100000, t3("max100000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      mm
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("widthLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="width"
                        initValue={service.width}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100000, t3("max100000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      mm
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("heightLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="height"
                        initValue={service.height}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100000, t3("max100000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      mm
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("weightLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="weight"
                        initValue={service.weight}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(100000, t3("max100000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      kg
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("horsepowerLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="horsepower"
                        initValue={service.horsepower}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        number={true}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .min(0, t3("minZeroError"))
                          .max(10000, t3("max10000NumberError"))
                          .nullable()
                          .notRequired()}
                      />{" "}
                      hk
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("emissionStandardLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="emission_standard"
                        initValue={service.emission_standard}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .max(255, t3("max255LengthError"))
                          .nullable()
                          .notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("driverDemandLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="driver_demand"
                        initValue={service.driver_demand}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string()
                          .max(255, t3("max255LengthError"))
                          .nullable()
                          .notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("notesLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="notes"
                        initValue={service.notes}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.string().nullable().notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("yearLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="year"
                        initValue={service.year}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                        validation={Yup.number()
                          .typeError(t3("numberRequiredError"))
                          .integer(t3("inValidYear"))
                          .min(1000, t3("inValidYear"))
                          .max(9999, t3("inValidYear"))
                          .nullable()
                          .notRequired()}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("displayLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableCheckBoxField
                        field="display"
                        label={t("displayLabel")}
                        initValue={service.display}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("orderByLabel")}
                    </TableCell>
                    <TableCell align="right">
                      <EditableTextField
                        field="year"
                        initValue={service.order_by}
                        url={patchUrl}
                        updateResourceState={updateResourceState}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("createdAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(service.created_at)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" className={classes.bold}>
                      {t("updatedAtLabel")}
                    </TableCell>
                    <TableCell align="right">
                      {niceDate(service.updated_at)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ContractItemTask taskId={service.task} businessAreaNames={businessAreaNames} />
          {materials && service && (
            <AssociatedField
              field="materials"
              resourceIds={service.materials}
              options={materialOptions}
              addResource={addMaterial}
              owningId={service.id}
              label={t("materialLabel")}
              removeResource={removeMaterial}
              isLoading={materialsLoading}
              title={t("materialsLabel")}
              locked={serviceIsLocked}
            />
          )}
          {accessories && service && (
            <AssociatedField
              field="accessories"
              resourceIds={service.accessories}
              options={accessoryOptions}
              addResource={addAccessory}
              owningId={service.id}
              label={t("accessoryLabel")}
              removeResource={removeAccessory}
              isLoading={accessoriesLoading}
              title={t("accessoriesLabel")}
              locked={serviceIsLocked}
            />
          )}
          {supplements && service && (
            <AssociatedField
              field="supplements"
              resourceIds={service.supplements}
              options={supplementOptions}
              addResource={addSupplement}
              owningId={service.id}
              label={t("supplementLabel")}
              removeResource={removeSupplement}
              isLoading={supplementsLoading}
              title={t("supplementsLabel")}
              locked={serviceIsLocked}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles)((props: ServiceProps) => (
  <ConfirmationDialogServiceProvider>
    <ServiceView {...props} />
  </ConfirmationDialogServiceProvider>
));
