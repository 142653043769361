import { connect } from "react-redux";
import { mainRootState, RouteInstancesQuery } from "../../redux/types";
import { Dispatch } from "redux";
import * as RestActions from "../../redux/sagas/restActions";
import { RestStrings, ReduxActionStrings } from "../../redux/strings";
import { filterQueryParams } from "../../helpers/queryParams";
import * as LoadingBooleansActions from "../../redux/reducers/loadingBooleans/actions";
import RouteInstances from "../../components/routes/routeinstances/RouteInstances";

const mapDispatchToProps = (dispatch: Dispatch, ownProps: any) => ({
  getRouteInstances: (filter: RouteInstancesQuery = {}) => {
    dispatch(
      RestActions.restRequest(
        ownProps.tabId,
        RestStrings.GET,
        ReduxActionStrings.ROUTEINSTANCES,
        `orders/routeinstances/${filterQueryParams(filter)}`
      )
    );
  },
  resetRouteInstances: () =>
    dispatch(LoadingBooleansActions.ResetRouteinstances(ownProps.tabId))
});

const mapStateToProps = (state: mainRootState, ownProps: any) => ({
  routeinstances:
    state.tabStates[ownProps.tabId].routes.routeinstances.routeinstances,
  routeInstancesCount:
    state.tabStates[ownProps.tabId].routes.routeinstances.count,
  loadingRouteinstances:
    state.tabStates[ownProps.tabId].loadingBooleans.loadingRouteinstances
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteInstances);
