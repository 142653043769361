import React, { useEffect, useState } from "react";
import { createStyles } from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import { Paper, Typography, WithStyles } from "@material-ui/core";
import { useTranslate } from "../../services/appLanguageService";
import { getTaskAPI } from "../../services/api-declaration";
import { TaskFormResponse } from "../../redux/types";
import ResourceLink from "../links/ResourceLink";

const styles = (theme: any) =>
  createStyles({
    paper: {
      margin: 20,
      padding: 20
    }
  });

interface ContractItemTaskProps extends WithStyles<typeof styles> {
  taskId: number | null;
  businessAreaNames?: Map<number, string>;
}

const ContractItemTask: React.FC<ContractItemTaskProps> = (props) => {
  const { classes, taskId, businessAreaNames=null } = props;

  const t = useTranslate("ContractPage");
  const [task, setTask] = useState<TaskFormResponse>();

  useEffect(() => {
    let isAlive = true;

    (async () => {
      if (taskId) {
        const taskResponse = await getTaskAPI(taskId);

        if (isAlive) {
          setTask(taskResponse);
        }
      }
    })();

    return () => {
      isAlive = false;
    };
  }, [taskId]);

  return (
    <Paper className={classes.paper}>
      <Typography>{t("priceForLabel")}:</Typography>
      <Typography>
        {taskId && task ? (
          <ResourceLink label={`${task.name} [${businessAreaNames?.get(task.businessarea)}]`} url="contracts/tasks" id={task.id} />
        ) : (
          t("allTasksLabel")
        )}
      </Typography>
    </Paper>
  );
};

export default withStyles(styles)(ContractItemTask);
