import compareAsc from "date-fns/compareAsc";
import compareDesc from "date-fns/compareDesc";
import WorkApproval from "./classes/verification/WorkApproval";
import {
  ITimeCarry,
  BulkApprovals,
  ConflictData,
  ConflictGroup,
  IApprovalSummary,
  IRowImage,
  ServiceCategory,
  SubmittedWork,
  SummaryRowMaterial,
  TimeSheetSummary,
  TimeSheetSummaryByRouteInstanceRows,
  IApprovalSelectionStats,
  IApprovalSummaryDetails,
  IRouteparticipant,
  IApprovalSubmittedRow,
  IApprovalDictionaries,
  TimeSheetRowMaterials,
  IVerificationImages,
  IApprovableGroup
} from "../redux/types";
import {
  translateRITitle,
  translate,
  useTranslate
} from "../services/appLanguageService";
import { applySequence } from "./globalHelper";
import minutesToHours from "date-fns/minutesToHours";
import millisecondsToMinutes from "date-fns/millisecondsToMinutes";
import { ReportedImageSequence } from "../strings/ReportedImageStrings";

export interface IConflictCarry {
  conflictGroups: ConflictGroup[];
  conflictRowsMap: Map<string, ConflictGroup>;
  summariesNeeded: Set<number>;
}

export const bulkPayload = (submitIDs: number[], option: string) => {
  const payload: BulkApprovals = {
    set_approvals: {
      timesheets: [],
      submits: option === "set" ? submitIDs : [],
      row_approvals: []
    },
    unset_approvals: {
      timesheets: [],
      submits: option === "unset" ? submitIDs : [],
      row_approvals: []
    }
  };
  return payload;
};

export const calculateSelectionStats = (
  approvalSummaries: IApprovalSummary[]
): IApprovalSelectionStats | undefined => {
  const selectedRows = approvalSummaries.flatMap(({ selectedRows }) =>
    Array.from(selectedRows.values())
  );
  if (selectedRows.length === 0) {
    return undefined;
  }
  const timeCarry: ITimeCarry = {
    start_time: "",
    end_time: ""
  };

  selectedRows.forEach((submittedRow) =>
    compareAndAssignTimes(
      timeCarry,
      submittedRow.readables,
      "start_time",
      "end_time"
    )
  );
  return {
    start_time: timeCarry.start_time,
    end_time: timeCarry.end_time,
    formattedTotalTime: sumTotalTime(
      selectedRows.map(({ readables }) => readables)
    ),
    rowCount: selectedRows.length
  };
};

const constructConflict = (
  {
    routeinstance_id,
    routeinstance_title
  }: TimeSheetSummaryByRouteInstanceRows,
  timesheet_id: TimeSheetSummary["timesheet_id"],
  submittedRow: SubmittedWork
): ConflictData => {
  const workApproval = WorkApproval.constructWorkApprovalFromSBRow(
    submittedRow,
    timesheet_id
  );
  const readables = workApproval.getCorrectionsByPriority(workApproval);
  return {
    routeinstance_id,
    timesheet_id,
    routeinstance_title,
    title: submittedRow.title,
    readables: {
      start_time: readables.start_time,
      end_time: readables.end_time
    },
    total_time: calculateTotalTime(readables),
    workApproval
  };
};

let conflictIdCount = 1;
const makeConflictGroup = (
  { conflictGroups, conflictRowsMap, summariesNeeded }: IConflictCarry,
  summary: TimeSheetSummaryByRouteInstanceRows,
  timesheet: TimeSheetSummary,
  { conflicts, row_id }: SubmittedWork
) => {
  const itemKeys = [
    `${summary.routeinstance_id}_${timesheet.timesheet_id}_${row_id}`,
    ...conflicts.map(({ routeinstance_id, timesheet_id, timesheetrow_id }) => {
      summariesNeeded.add(routeinstance_id);
      return `${routeinstance_id}_${timesheet_id}_${timesheetrow_id}`;
    })
  ];

  const matchedKey = itemKeys.find((itemKey) => conflictRowsMap.has(itemKey));
  let conflictGroup = conflictRowsMap.get(matchedKey as string);

  if (!conflictGroup) {
    conflictGroup = {
      id: conflictIdCount++,
      routeinstanceTitles: [],
      conflicts: []
    };
    conflictGroups.push(conflictGroup);
  }
  itemKeys.forEach((itemKey) =>
    conflictRowsMap.set(itemKey, conflictGroup as ConflictGroup)
  );
};

const makeConflictRow = (
  conflictsMap: Map<string, ConflictGroup>,
  summary: TimeSheetSummaryByRouteInstanceRows,
  timesheet: TimeSheetSummary,
  submittedRow: SubmittedWork
) => {
  const itemKey = `${summary.routeinstance_id}_${timesheet.timesheet_id}_${submittedRow.row_id}`;
  if (conflictsMap.has(itemKey)) {
    const conflictGroup = conflictsMap.get(itemKey) as ConflictGroup;
    if (
      !conflictGroup.routeinstanceTitles.includes(summary.routeinstance_title)
    ) {
      conflictGroup.routeinstanceTitles.push(summary.routeinstance_title);
    }
    conflictGroup.conflicts.push(
      constructConflict(summary, timesheet.timesheet_id, submittedRow)
    );
  }
};

export const collectConflictDetails = (
  summaries: TimeSheetSummaryByRouteInstanceRows[]
) => {
  const conflictMap = new Map<number, true>();
  const conflictCarry: IConflictCarry = {
    conflictGroups: [],
    conflictRowsMap: new Map(),
    summariesNeeded: new Set()
  };

  summaries.forEach((summary) =>
    summary.timesheets.forEach((timesheet) =>
      timesheet.submitted.forEach((submitted) => {
        if (
          submitted.status === "CANCELLED" ||
          submitted.conflicts.length === 0
        ) {
          return;
        }
        conflictMap.set(summary.routeinstance_id, true);
        makeConflictGroup(conflictCarry, summary, timesheet, submitted);
      })
    )
  );

  return {
    conflictGroups: conflictCarry.conflictGroups,
    conflictMap,
    conflictRowsMap: conflictCarry.conflictRowsMap,
    moreSummaryIds: Array.from(conflictCarry.summariesNeeded).filter(
      (routeinstanceId) => !conflictMap.has(routeinstanceId)
    )
  };
};

const convertToDict = <T, K extends keyof T>(
  arr: T[],
  key: K
): Record<string | number, T> =>
  Object.fromEntries(arr.map((obj) => [obj[key], obj]));

let rowIdCount = 1;
export const shapeApprovalSummaryTimesheet = (
  summary: TimeSheetSummaryByRouteInstanceRows
) => {
  // dealt things which are missing/wrong from server response
  summary.routeinstance_title = translateRITitle(summary.routeinstance_title);
  summary.timesheets.forEach((timesheet) => {
    timesheet.submitted
      .concat(timesheet.work_in_progress)
      .forEach((submittedRow) => {
        submittedRow._id = rowIdCount++;
        if (submittedRow.materials) {
          submittedRow.materials = convertToDict(
            // TODO: Get rid of this
            submittedRow.materials as any as SummaryRowMaterial[],
            "material_id"
          );
        }
        submittedRow.task = (submittedRow as any).task_id;
        submittedRow.servicecategory = (submittedRow as any).servicecategory_id;
        submittedRow.service = (submittedRow as any).service_id;
      });
    timesheet.work_in_progress.forEach(
      (unSubmitted) => (unSubmitted._id = rowIdCount++)
    );
  });
};

const compareAndAssignTimes = <T>(
  carry: ITimeCarry,
  obj: T,
  startKey: keyof T,
  endKey: keyof T
) => {
  const start_time: string = obj[startKey] as any;
  const end_time: string = obj[endKey] as any;

  if (start_time && end_time) {
    if (
      !carry.start_time ||
      compareAsc(new Date(carry.start_time), new Date(start_time)) === 1
    ) {
      carry.start_time = start_time;
    }
    if (
      !carry.end_time ||
      compareDesc(new Date(carry.end_time), new Date(end_time)) === 1
    ) {
      carry.end_time = end_time;
    }
  }
};

const constructApprovalSummary = (
  isVerificationView: boolean,
  routeInstanceSummary: TimeSheetSummaryByRouteInstanceRows,
  conflictMap: Map<number, true>,
  allSCIdSet: Set<number>
): IApprovalSummary => {
  const {
    routeinstance_id,
    routeinstance_title,
    routeinstance_status,
    submitted_unapproved_count,
    timesheets
  } = routeInstanceSummary;
  const serviceCategoryIdSet = new Set<number>();
  const participants = new Map<string, IRouteparticipant>();
  const tasksSet = new Set<number>();
  let submittedCount = 0;
  let unSubmittedCount = 0;
  let hasImages = false;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const t = useTranslate("TimesheetApprovalPage");

  timesheets.forEach((ts) => {
    ts.submitted.concat(ts.work_in_progress).forEach((row) => {
      if (isVerificationView && row.status === "CANCELLED") {
        return;
      }

      if (row.general_corrections?.servicecategory) {
        serviceCategoryIdSet.add(row.general_corrections.servicecategory);
        allSCIdSet.add(row.general_corrections.servicecategory);
      } else if (row.servicecategory) {
        serviceCategoryIdSet.add(row.servicecategory);
        allSCIdSet.add(row.servicecategory);
      }
      tasksSet.add(row.task);
      hasImages = hasImages || row.images.length !== 0;
    });

    const correctionServiceNames = [
      ...new Set(
        ts.submitted.map(
          (s) => s.general_corrections?.service_name || s.service_name
        )
      )
    ];

    const accompanyingServiceNames = new Set(
      ts.submitted.flatMap((s) =>
        s.accompanying_people.map((a) => a.service_name)
      )
    );
    const accompanyingPeopleNames = new Set(
      ts.submitted.flatMap((s) =>
        s.accompanying_people.map((a) => a.person_name)
      )
    );

    const service_name =
      correctionServiceNames.join(", ") +
      (accompanyingServiceNames.size > 0
        ? `, ${accompanyingPeopleNames.size} ${t("extraLabel")} ${[
            ...accompanyingServiceNames
          ].join(", ")}`
        : "");

    submittedCount += ts.submitted.length;
    unSubmittedCount += ts.work_in_progress.length;
    const participantKey = `${ts.contractor_name}_${ts.service_names}_${ts.timesheet_id}`;
    if (!participants.has(participantKey)) {
      participants.set(participantKey, {
        participantKey,
        positionNo: participants.size + 1,
        contractor_name: ts.contractor_name,
        service_name: service_name,
        person_name: [ts.person_name]
          .concat(...accompanyingPeopleNames)
          .join(", "),
        notes: ts.notes || "",
        workrating: ts.workrating
      });
    }
  });

  const timeCarry: ITimeCarry = { start_time: "", end_time: "" };
  const timeRows: ITimeCarry[] = [];
  timesheets.forEach((ts) =>
    ts.submitted.forEach((submitted) => {
      if (isVerificationView && submitted.status === "CANCELLED") {
        return;
      }
      const workApproval = WorkApproval.constructWorkApprovalFromSBRow(
        submitted,
        ts.timesheet_id
      );
      const readables = workApproval.getCorrectionsByPriority(workApproval);
      compareAndAssignTimes(timeCarry, readables, "start_time", "end_time");
      timeRows.push({
        start_time: readables.start_time,
        end_time: readables.end_time
      });
    })
  );

  return {
    routeinstance_id,
    routeinstance_title,
    routeinstance_status,
    submitted_unapproved_count,
    hasConflict: conflictMap.has(routeinstance_id),
    tasksCount: tasksSet.size,
    participants: participants,
    start_time: timeCarry.start_time,
    total_time: sumTotalTime(timeRows),
    serviceCategoryIds: Array.from(serviceCategoryIdSet),
    serviceCategories: [],
    allServiceCategories: [],
    unSubmittedCount: unSubmittedCount,
    rowsCount: submittedCount + unSubmittedCount,
    hasImages,
    selectedRows: new Map(),
    routeInstanceSummary,
    summaryRows: []
  };
};

export const groupRows = (
  rowGroupsMap: Map<string, IApprovableGroup>,
  submittedRow: IApprovalSubmittedRow
) => {
  const groupId = `${submittedRow.segment_row_id}_${submittedRow.readables.task}`;
  let rowGroup = rowGroupsMap.get(groupId);
  if (!rowGroup) {
    rowGroup = {
      segment_row_id: submittedRow.segment_row_id,
      task: submittedRow.readables.task,
      rows: []
    };
    rowGroupsMap.set(groupId, rowGroup);
  }
  rowGroup.rows.push(submittedRow);
};

export const constructApprovalSummaryDetails = (
  isVerificationView: boolean,
  approvalSummary: IApprovalSummary
): IApprovalSummaryDetails => {
  const { routeinstance_id, routeinstance_notes, timesheets } =
    approvalSummary.routeInstanceSummary;
  const approvableRows: IApprovalSummaryDetails["approvableRows"] = [];

  const mapImages = (images: IVerificationImages[]) => {
    const enteries = images.map(
      ({ classification, notes, url }) =>
        [
          classification,
          {
            notes,
            url,
            title: translate("ImageClassificationLabel")(classification)
          }
        ] as [string, IRowImage]
    );
    return applySequence(ReportedImageSequence, enteries);
  };
  const submittedRowGroups = new Map<string, IApprovableGroup>();
  timesheets.forEach((ts) => {
    ts.submitted.forEach((submitted) => {
      if (isVerificationView && submitted.status === "CANCELLED") {
        return;
      }
      const workApproval = WorkApproval.constructWorkApprovalFromSBRow(
        submitted,
        ts.timesheet_id
      );

      const readables = workApproval.getCorrectionsByPriority(workApproval);
      const submittedRow: IApprovalSubmittedRow = {
        _id: submitted._id,
        row_id: submitted.row_id,
        segment_row_id: submitted.segment_row_id,
        submit_id: submitted.submit_id,
        workApproval,
        readables: {
          start_time: readables.start_time,
          end_time: readables.end_time,
          task: readables.task,
          materials: readables.materials,
          service: readables.service,
          service_name: readables.service_name,
          servicecategory: readables.servicecategory
        },
        total_time: calculateTotalTime(readables),
        timesheet_id: ts.timesheet_id,

        title: submitted.title,
        status: submitted.status,
        packageData: workApproval.getPackage(),
        instructions: submitted.instructions,
        notes: submitted.notes,
        images: mapImages(submitted.images),
        geo_polygons: submitted.geo_polygons,
        service_name: submitted.service_name,
        accessories: submitted.accessories,
        participant: approvalSummary.participants.get(
          `${ts.contractor_name}_${ts.service_names}_${ts.timesheet_id}`
        ) as IRouteparticipant,
        hasConflict: submitted.conflicts.length !== 0,
        isApprovable:
          submitted.status !== "APPROVED" && submitted.conflicts.length === 0,
        servicecategory: submitted.servicecategory,
        task: submitted.task,
        materials: submitted.materials,
        start_time: submitted.start_time,
        end_time: submitted.end_time
      };
      groupRows(submittedRowGroups, submittedRow);
      if (submittedRow.isApprovable) {
        approvableRows.push(submittedRow);
      }
    });
    ts.work_in_progress.forEach((unSubmitted) => {
      const workInProgressRow: IApprovalSubmittedRow = {
        _id: unSubmitted._id,
        row_id: unSubmitted.row_id,
        segment_row_id: unSubmitted.segment_row_id,
        submit_id: undefined as any,
        workApproval: undefined as any,
        readables: {
          start_time: undefined as any,
          end_time: undefined as any,
          task: unSubmitted.task,
          materials: unSubmitted.materials,
          service: unSubmitted.service,
          service_name: unSubmitted.service_name,
          servicecategory: unSubmitted.servicecategory
        },
        total_time: undefined as any,
        timesheet_id: ts.timesheet_id,
        title: unSubmitted.title,
        status: "IN_PROGRESS",
        packageData: undefined as any,
        instructions: unSubmitted.instructions,
        notes: unSubmitted.notes,
        images: unSubmitted.images,
        geo_polygons: unSubmitted.geo_polygons,
        service_name: unSubmitted.service_name,
        accessories: unSubmitted.accessories,
        participant: approvalSummary.participants.get(
          `${ts.contractor_name}_${ts.service_names}_${ts.timesheet_id}`
        ) as IRouteparticipant,

        hasConflict: false,
        isApprovable: false,
        servicecategory: unSubmitted.servicecategory,
        task: unSubmitted.task,
        materials: unSubmitted.materials,
        start_time: unSubmitted.start_time,
        end_time: unSubmitted.end_time
      };
      groupRows(submittedRowGroups, workInProgressRow);
    });
  });
  const groupKeys = Array.from(submittedRowGroups.keys()).sort();
  return {
    routeinstance_id,
    routeinstance_notes,
    submittedRows: groupKeys.map(
      (key) => submittedRowGroups.get(key) as IApprovableGroup
    ),
    approvableRows,
    timesheetOptionsMap: new Map(),
    packageOptionsMap: new Map()
  };
};

export const processVerificationSummaries = (
  isVerificationView: boolean,
  summaries: TimeSheetSummaryByRouteInstanceRows[],
  dictionaries: IApprovalDictionaries,
  moreSummariesForConflict: TimeSheetSummaryByRouteInstanceRows[],
  conflictMap: Map<number, true>,
  conflictRowsMap?: Map<string, ConflictGroup>
) => {
  // ::: process conflicted rows and collect Material :::
  summaries.concat(moreSummariesForConflict).forEach((summary) => {
    shapeApprovalSummaryTimesheet(summary);
    summary.timesheets.forEach((timesheet) =>
      timesheet.submitted
        .concat(timesheet.work_in_progress)
        .forEach((submitted) => {
          if (isVerificationView && submitted.status === "CANCELLED") {
            return;
          }
          const {
            general_corrections,
            contractor_corrections,
            customer_corrections
          } = submitted;

          dictionaries.forEach((dictionary) =>
            dictionary.collectFromEnteries([
              submitted,
              general_corrections,
              contractor_corrections,
              customer_corrections
            ])
          );

          if (conflictRowsMap) {
            makeConflictRow(conflictRowsMap, summary, timesheet, submitted);
          }
        })
    );
  });

  const allSCIdSet = new Set<number>();
  return {
    approvalSummaries: summaries.map((summary) =>
      constructApprovalSummary(
        isVerificationView,
        summary,
        conflictMap,
        allSCIdSet
      )
    ),
    serviceCategoriesPresent: Array.from(allSCIdSet)
  };
};

export const applySCOnApprovalSummary = (
  approvalSummaries: IApprovalSummary[],
  serviceCategories: ServiceCategory[]
) => {
  const scMap = new Map(serviceCategories.map((sc) => [sc.id, sc]));
  approvalSummaries.forEach((approvalSummary) => {
    approvalSummary.serviceCategories = approvalSummary.serviceCategoryIds.map(
      (scId) => scMap.get(scId) as ServiceCategory
    );
    approvalSummary.allServiceCategories = serviceCategories;
  });
};

const convertToMinTwoDigits = (n: number) => {
  const str = String(n);
  return `00${n}`.slice(str.length > 2 ? -str.length : -2);
};

const formatTotalTime = (totalMinutes: number) => {
  const hours = minutesToHours(totalMinutes);
  const minutes = totalMinutes % 60;
  return `${convertToMinTwoDigits(hours as number)}:${convertToMinTwoDigits(
    minutes as number
  )}`;
};
const getDurationInMinutes = ({ start_time, end_time }: ITimeCarry) => {
  let totalMinutes = 0;
  if (start_time && end_time) {
    const start = new Date(start_time);
    const end = new Date(end_time);
    totalMinutes = millisecondsToMinutes(Number(end) - Number(start));
  }
  return totalMinutes;
};
export const calculateTotalTime = (timeCarry: ITimeCarry) => {
  const totalMinutes = getDurationInMinutes(timeCarry);
  return totalMinutes ? formatTotalTime(totalMinutes) : "";
};

export const sumTotalTime = (rows: ITimeCarry[]) => {
  const totalMinutes = rows.reduce(
    (sum, timeCarry) => sum + getDurationInMinutes(timeCarry),
    0
  );
  return totalMinutes ? formatTotalTime(totalMinutes) : "--:--";
};

export const copyApprovalAddon = (addonObj: TimeSheetRowMaterials) =>
  Object.fromEntries(
    Object.entries(addonObj).map(([id, { amount }]) => [id, { amount }])
  );

export const clearApprovalSelection = (
  approvalSummaries: IApprovalSummary[],
  routeInstanceId: number
) =>
  approvalSummaries.some(({ routeinstance_id, selectedRows }) => {
    if (routeinstance_id === routeInstanceId) {
      selectedRows.clear();
      return true;
    }
    return false;
  });
