import {
  TableRow,
  TableCell,
  makeStyles,
  TextField,
  IconButton,
  InputLabel
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { Field, FormikProps, useField } from "formik";
import React, { useState } from "react";
import {
  Accessory,
  FormikSetFieldValue,
  Material,
  SelectFieldOption,
  Service,
  ServiceCategory,
  TaskResponse,
  TimeSheetForm,
  TimeSheetRowExtended
} from "../../../redux/types";
import {
  AutoCompleteSelect,
  AutoCompleteSelectMulti
} from "../../AutoCompleteSelect";
import TimesheetRowMaterialAddonComponent from "../../timesheet-row-add-material/timesheetRowAddMaterial";
import { useTranslate } from "../../../services/appLanguageService";
import InputMask from "react-input-mask";
import { parseTimeAsFloat } from "../../../helpers/globalHelper";

const useStyles = makeStyles((_theme) => ({
  editIconsContainer: {
    display: "flex",
    alignItems: "center"
  },
  selectField: {
    width: "260px"
  },
  notesField: {
    width: "100%"
  }
}));

type TimeEditorProps = {
  label: string;
  fieldName: string;
};

const TimeEditor: React.FC<TimeEditorProps> = ({ label, fieldName }) => {
  const [, { value, error, touched }, { setValue, setTouched }] =
    useField<string>(fieldName);
  const [time, setTime] = useState<string>(value ?? "");

  const onKeyDown = (e: { key: string; preventDefault: () => void }) => {
    e.key === "Enter" && e.preventDefault();
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  };
  const onBlur = () => {
    setValue(time);
    setTouched(true);
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "120px",
        marginRight: "5px"
      }}
    >
      <div>
        <InputLabel shrink>{label}</InputLabel>
        <InputMask
          mask={[/\d/, /\d/, ":", /\d/, /\d/]}
          maskPlaceholder="__:__"
          onChange={onChange}
          onBlur={onBlur}
          value={time}
          onKeyDown={onKeyDown}
        >
          <TextField />
        </InputMask>
        {error && touched && <InputLabel error>{error}</InputLabel>}
      </div>
    </div>
  );
};

interface TimeSheetEditableRowProps {
  formikProps: FormikProps<TimeSheetForm>;
  index: number;
  deleteRow: () => void;
  readOnly?: boolean;
  tasks: TaskResponse[];
  businessAreaNames: Map<number, string>;
  serviceCategories: ServiceCategory[];
  materials: Material[];
  accessories: Accessory[];
  selectedService: Service | null;
  title: string;
}

const TimeSheetEditableRow: React.FC<TimeSheetEditableRowProps> = ({
  formikProps,
  index,
  readOnly,
  tasks,
  businessAreaNames,
  serviceCategories,
  deleteRow,
  materials,
  accessories,
  selectedService
}) => {
  const classes = useStyles();

  const taskOptions: SelectFieldOption[] = tasks.map((task) => ({
    value: task.id,
    label: `${task.name} (${businessAreaNames.get(task.businessarea)})`
  }));
  const serviceCategoryOptions: SelectFieldOption[] = serviceCategories
    .filter((sc) => !!selectedService?.categories.includes(sc.id))
    .map((sc) => ({ label: sc.name, value: sc.id }));
  const accessoryOptions: SelectFieldOption[] = accessories
    .filter((a) => !!selectedService?.accessories.includes(a.id))
    .map((a) => ({
      label: a.name,
      value: a.id
    }));
  const materialOptions = Object.fromEntries(
    materials
      .filter((m) => !!selectedService?.materials.includes(m.id))
      .map((m) => [m.id.toString(), m])
  );

  const timesheet = formikProps.values;
  const row = timesheet.rows[index];
  const isEditable = row.submit_status === "DRAFT"; // Are rows editable
  const t = useTranslate("AfterregistrationPage");

  const [, addedDays] = timesheet.rows
    .slice(0, index + 1)
    .map((r) => [parseTimeAsFloat(r.start_time), parseTimeAsFloat(r.end_time)])
    .reduce(
      ([prev, addedDays], [start, end]) => [
        end,
        addedDays + (prev > start ? 1 : 0) + (start >= end ? 1 : 0)
      ],
      [0, 0]
    );

  if (
    selectedService &&
    selectedService.id &&
    row.service !== selectedService.id
  ) {
    formikProps.setFieldValue(`rows.${index}.service`, selectedService.id);
    formikProps.setFieldValue(
      `rows.${index}.servicecategory`,
      row.servicecategory
    );
    formikProps.setFieldValue(
      `rows.${index}.accessories`,
      row.accessories.filter(
        (id) => !!accessoryOptions.find((sc) => sc.value === id)
      )
    );
    formikProps.setFieldValue(
      `rows.${index}.materials`,
      Object.fromEntries(
        Object.entries(row.materials).filter(([id]) => !!materialOptions[id])
      )
    );
  }

  /**
   * This function is needed beacause onChange of the referenced component isn't consistent with other components @see TimesheetRowMaterialAddonComponent.tsx
   */
  const handleMaterialsChange =
    (rows: TimeSheetRowExtended[], setFieldValue: FormikSetFieldValue) =>
    (fieldName: string, values: TimeSheetRowExtended["materials"]) => {
      const updatedRows = rows.slice();
      if (fieldName === "materials") {
        updatedRows[index][fieldName] = values;
      }
      setFieldValue("rows", updatedRows);
    };

  return (
    <TableRow id={`table-row-${index}`}>
      <TableCell align="left">
        <Field
          autoFocus
          id="afterregistration-row-task"
          className={classes.selectField}
          disabled={readOnly}
          name={`rows.${index}.task`}
          type="text"
          component={AutoCompleteSelect}
          isDisabled={!isEditable}
          options={taskOptions}
          placeholder={t("taskLabel")}
          customHandleChange={(taskId: number) => {
            formikProps.setFieldValue(
              `rows.${index}.title`,
              taskOptions.find((t) => t.value === taskId)?.label
            );
          }}
        />
      </TableCell>
      <TableCell align="left">
        <Field
          className={classes.selectField}
          disabled={readOnly}
          id="afterregistration-row-servicecategory"
          type="text"
          name={`rows.${index}.servicecategory`}
          label={t("serviceCategoryLabel")}
          placeholder={t("serviceCategoryLabel")}
          options={serviceCategoryOptions}
          component={AutoCompleteSelect}
          margin="normal"
          fullWidth
        />
      </TableCell>
      {!!accessoryOptions.length && (
        <TableCell align="left">
          <Field
            id="afterregistration-row-accessories"
            className={classes.selectField}
            disabled={readOnly}
            type="text"
            name={`rows.${index}.accessories`}
            label={t("accessoriesLabel")}
            placeholder={t("accessoriesLabel")}
            options={accessoryOptions}
            component={AutoCompleteSelectMulti}
            margin="normal"
            fullWidth
          />
        </TableCell>
      )}
      <TableCell align="left">
        <div style={{ display: "flex" }}>
          <TimeEditor
            label={t("startTimeLabel")}
            fieldName={`rows.${index}.start_time`}
          />
          <TimeEditor
            label={t("endTimeLabel")}
            fieldName={`rows.${index}.end_time`}
          />
        </div>
        <div style={{ textAlign: "right" }}>
          {!!addedDays && (
            <>
              +{addedDays} {t("addedDaysLabel")}
            </>
          )}
        </div>
      </TableCell>
      {!!Object.keys(materialOptions).length && (
        <TableCell>
          <div>
            <TimesheetRowMaterialAddonComponent
              showNoButton={readOnly}
              disabled={readOnly}
              options={materialOptions}
              fieldName="materials"
              fieldValue={row.materials}
              correctionChanged={handleMaterialsChange(
                timesheet.rows,
                formikProps.setFieldValue
              )}
            />
          </div>
        </TableCell>
      )}
      <TableCell align="left" className={classes.notesField}>
        <TextField
          disabled={readOnly}
          type="text"
          multiline
          minRows={2}
          fullWidth
          defaultValue={row.notes}
          onBlur={(e) => {
            formikProps.setFieldValue(
              `rows.${index}.notes`,
              e.currentTarget.value
            );
          }}
        />
      </TableCell>
      {!readOnly && (
        <TableCell>
          <div className={classes.editIconsContainer}>
            {/* <AfterregistrationRowStatusIcon submitStatus={row.submit_status} /> */}
            <IconButton color="primary" onClick={deleteRow}>
              <DeleteIcon />
            </IconButton>
          </div>
        </TableCell>
      )}
    </TableRow>
  );
};

export default TimeSheetEditableRow;
