import React, { Dispatch, SetStateAction } from "react";
import { Paper, Checkbox, Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  BulkInvoiceStatusUpdatePayload,
  BulkInvoiceStatusReturn,
  Invoice,
  InvoiceStatus
} from "../../../redux/types";

import { showGlobalSnackbar } from "../../../helpers/globalHelper";
import { useConfirmationDialog } from "../../confirmationDialog/ConfirmationDialogServiceProvider";
import { useTranslate } from "../../../services/appLanguageService";
import clsx from "clsx";
import { getInvoiceStatusIcon } from "../../../helpers/invoiceHelper";
import { Send } from "@material-ui/icons";

const useStyles = makeStyles((theme: any) => ({
  boldFont: {
    fontWeight: "bold"
  },
  bottomViewContainer: {
    boxShadow: "0 0 5px 1px #00000038"
  },
  sticky: {
    bottom: 0,
    position: "fixed",
    transform: "translate(25%, 0)",
    width: "50%"
  },
  content: {
    padding: theme.spacing(1.25)
  },
  mr1_25: {
    marginRight: theme.spacing(1.25)
  },
  statusAvailableActionButton: {
    marginLeft: theme.spacing(1.25),
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(1.25)
  },
  darkGray: {
    color: "#636363"
  }
}));

interface BottomInvoiceViewProps {
  bulkInvoiceStatusUpdate: (
    payload: BulkInvoiceStatusUpdatePayload
  ) => Promise<BulkInvoiceStatusReturn>;
  bulkSendInvoiceEmail: (payload: { invoice_ids: number[] }) => Promise<void>;
  invoices: Invoice[];
  selectedInvoices: Set<number>;
  setSelectedInvoices: Dispatch<SetStateAction<Set<number>>>;
}

type InvoiceStatusGroup = {
  [key in InvoiceStatus]: Invoice[];
};

function BottomInvoiceButtonsView(props: BottomInvoiceViewProps) {
  const {
    bulkInvoiceStatusUpdate,
    bulkSendInvoiceEmail,
    invoices,
    selectedInvoices,
    setSelectedInvoices
  } = props;
  const openConfirmationDialog = useConfirmationDialog();
  const t = useTranslate("InvoicesView");
  const classes = useStyles();

  const groups = {} as InvoiceStatusGroup;
  const emailGroup: number[] = [];
  invoices
    .filter((invoice) => selectedInvoices.has(invoice.id))
    .forEach((invoice) => {
      const availableStatusActions = invoice.allowed_transitions;
      availableStatusActions.forEach((status) => {
        if (groups[status] === undefined) {
          groups[status] = [];
        }
        groups[status].push(invoice);
      });
      if (invoice.status !== "DRAFT") {
        emailGroup.push(invoice.id);
      }
    });

  const getAvailableActionsText = (status: InvoiceStatus) => {
    switch (status) {
      case "DRAFT":
        return t("draftInvoiceLabel");
      case "READY":
        return t("readyInvoiceLabel");
      case "EXPORTED":
        return t("exportedInvoiceLabel");
      case "CREDITED":
        return t("creditedInvoiceLabel");
      case "CANCELLED":
        return t("cancelInvoiceLabel");
      default:
        return status;
    }
  };

  const updateAvailableStatusActions = (status: InvoiceStatus) => async () => {
    await openConfirmationDialog({
      description: `${t("confirmationMessage")} ${getAvailableActionsText(
        status
      )}?`,
      title: "Confirmation"
    });
    const response = await bulkInvoiceStatusUpdate({
      invoice_ids: groups[status].map((invoice) => invoice.id),
      status
    });
    setSelectedInvoices(
      (selected) =>
        new Set(
          Array.from(selected.values()).filter(
            (id) => !response.altered_ids.includes(id)
          )
        )
    );
    showGlobalSnackbar("Status updated successfully", "success");
  };

  const sendEmailAction = async () => {
    await openConfirmationDialog({
      description: t("sendEmailMessage"),
      title: "Confirmation"
    });
    await bulkSendInvoiceEmail({
      invoice_ids: emailGroup
    });
  };

  const handleCheckbox = (
    _: React.ChangeEvent<HTMLInputElement>,
    check: boolean
  ) => {
    if (check) {
      setSelectedInvoices(new Set(invoices.map((inv) => inv.id)));
    } else {
      setSelectedInvoices(new Set());
    }
  };

  return (
    <Paper className={clsx(classes.sticky, classes.bottomViewContainer)}>
      <Grid
        container
        className={classes.content}
        justifyContent="space-between"
        direction="row"
        wrap="nowrap"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          item
        >
          <Checkbox
            className={classes.mr1_25}
            indeterminate={selectedInvoices.size !== invoices.length}
            checked={selectedInvoices.size === invoices.length}
            onChange={handleCheckbox}
          />
          <Typography className={clsx(classes.boldFont, classes.darkGray)}>
            {`${t("selectedLabel")}: ${selectedInvoices.size}`}
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          {!!emailGroup.length && (
            <>
              <Button
                color="primary"
                className={classes.statusAvailableActionButton}
                variant="contained"
                onClick={sendEmailAction}
                startIcon={<Send />}
              >
                {t("sendEmail")}: {emailGroup.length}
              </Button>
            </>
          )}
          {Object.keys(groups).map((status, idx) => {
            const invoiceStatus = status as InvoiceStatus;
            return (
              <Button
                key={idx}
                color="primary"
                className={classes.statusAvailableActionButton}
                variant="contained"
                onClick={updateAvailableStatusActions(invoiceStatus)}
                startIcon={getInvoiceStatusIcon(invoiceStatus)}
              >
                {`${getAvailableActionsText(invoiceStatus)}: ${
                  groups[invoiceStatus].length
                }`}
              </Button>
            );
          })}
        </Grid>
      </Grid>
    </Paper>
  );
}

export default BottomInvoiceButtonsView;
