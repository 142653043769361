import { useEffect, useMemo, useState } from "react";
import { Paper, Button, Collapse } from "@material-ui/core";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { history } from "../../../redux/store";
import { Formik, Form, Field, FormikProps } from "formik";
import { useHistory } from "react-router";
import {
  TimeSheetForm,
  TimeSheetExtended,
  TabComponentProps,
  TimeSheetOptions
} from "../../../redux/types";
import { useTranslate } from "../../../services/appLanguageService";
import { ConfirmationDialogServiceProvider } from "../../confirmationDialog/ConfirmationDialogServiceProvider";
import {
  addNewTimesheetAPI,
  saveAndSubmitWorkAPI,
  getCompanyByIdAPI,
  getTimesheetOptionsForContractorAPI,
  getBusinessareasAPI
} from "../../../services/api-declaration";
import AfterregistrationTable from "./AfterregistrationTable";
import {
  parseTimeAsFloat,
  showGlobalSnackbar
} from "../../../helpers/globalHelper";
import AfterregistrationForm from "./AfterregistrationForm";
import { niceDate } from "../../FormatHelpers";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import { add, parse, set, sub } from "date-fns";

interface AfterregistrationProps extends TabComponentProps<"contractorId"> {}

interface AfterregistrationInnerProps {
  formikProps: FormikProps<TimeSheetForm>;
}

let ValidationSchema = (t: any) =>
  Yup.object().shape({
    current_service: Yup.number()
      .typeError(t("requiredError"))
      .positive(t("requiredError"))
      .required(t("requiredError")),
    person: Yup.number()
      .typeError(t("requiredError"))
      .positive(t("requiredError"))
      .required(t("requiredError")),
    rows: Yup.array()
      .min(1, t("requiredError"))
      .of(
        Yup.object().shape({
          notes: Yup.string().nullable().notRequired(),
          task: Yup.number()
            .positive(t("requiredError"))
            .required(t("requiredError")),
          servicecategory: Yup.number()
            .positive(t("requiredError"))
            .required(t("requiredError")),
          start_time: Yup.string()
            .required(t("requiredError"))
            .matches(/^(([01]\d)|(2[0123])):[012345]\d$/, t("badFormatError")),
          end_time: Yup.string()
            .required(t("requiredError"))
            .matches(/^(([01]\d)|(2[0123])):[012345]\d$/, t("badFormatError"))
        })
      )
  });

const useStyles = makeStyles((theme: any) =>
  createStyles({
    paper: {
      padding: theme.spacing(2.5),
      marginTop: theme.spacing(4)
    },
    form: {
      width: "100%"
    },
    formControl: {
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(1.5)
    },
    flexBox: {
      display: "flex",
      alignItems: "center",
      marginBottom: theme.spacing(2.5)
    },
    btnMargin: { marginLeft: theme.spacing(1.5) },
    editIcon: {
      fontSize: theme.spacing(2.5),
      marginRight: theme.spacing(1)
    },
    timesheetActions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginTop: theme.spacing(2.5)
    },
    btnSpacing: { marginLeft: theme.spacing(2) }
  })
);

const AfterregistrationInner = ({
  formikProps
}: AfterregistrationInnerProps): JSX.Element => {
  const s = useTranslate("AfterregistrationPage");
  const classes = useStyles();

  const [contractorCompanyName, setContractorCompanyName] = useState("");
  const [isLoadingTimesheetOptions, setIsLoadingTimesheetOptions] =
    useState<boolean>(false);
  const [timesheetOptions, setTimesheetOptions] = useState<TimeSheetOptions>({
    contracted: {
      services: {},
      servicecategories: {},
      accessories: {},
      tasks: {},
      materials: {}
    },
    colleagues: {}
  });
  const [businessAreaNames, setBusinessAreaNames] =
    useState<Map<number, string>>();

  useEffect(() => {
    let alive = true;
    setIsLoadingTimesheetOptions(true);
    if (
      formikProps.values.contractor !== null &&
      formikProps.values.contractor !== undefined
    ) {
      const id =
        typeof formikProps.values.contractor === "string"
          ? parseInt(formikProps.values.contractor)
          : formikProps.values.contractor;
      void Promise.all([
        getTimesheetOptionsForContractorAPI(id),
        getBusinessareasAPI()
      ])
        .then(([opts, { results: businessAreas }]) => {
          if (alive) {
            setTimesheetOptions(opts);
            setBusinessAreaNames(
              new Map(businessAreas.map((ba) => [ba.id, ba.name]))
            );
            setIsLoadingTimesheetOptions(false);
          }
        })
        .catch(console.warn);

      return () => {
        alive = false;
      };
    }
  }, [formikProps.values.contractor]);

  const services = useMemo(
    () =>
      Object.values(timesheetOptions.contracted.services).sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    [timesheetOptions.contracted.services]
  );
  const drivers = useMemo(
    () =>
      Object.values(timesheetOptions.colleagues).sort((a, b) =>
        a.first_name.localeCompare(b.first_name)
      ),
    [timesheetOptions.colleagues]
  );

  const showRows =
    formikProps.values.current_service !== null &&
    formikProps.values.current_service !== -1 &&
    formikProps.values.person !== null &&
    formikProps.values.person !== -1;

  useEffect(() => {
    let alive = true;
    if (formikProps.values.contractor) {
      void getCompanyByIdAPI(formikProps.values.contractor).then(
        (r) => alive && setContractorCompanyName(r.name)
      );
      return () => {
        alive = false;
      };
    }
  }, [formikProps.values.contractor]);

  return (
    <Form
      className={classes.form}
      onSubmit={formikProps.handleSubmit}
      autoComplete="off"
    >
      <AfterregistrationForm
        formikProps={formikProps}
        services={services}
        drivers={drivers}
        contractorName={contractorCompanyName}
        isLoadingTimesheetOptions={isLoadingTimesheetOptions}
      />

      <Collapse in={showRows}>
        <Paper className={classes.paper}>
          {businessAreaNames && (
            <AfterregistrationTable
              formikProps={formikProps}
              timesheetOptions={timesheetOptions}
              businessAreaNames={businessAreaNames}
            />
          )}
        </Paper>
      </Collapse>
      <Paper className={classes.paper}>
        <Field
          data-cy="afterregistration-notes"
          id="afterregistration-notes"
          type="text"
          name="notes"
          label={s("commentLabel")}
          placeholder={s("commentLabel")}
          component={TextField}
          multiline={true}
          margin="normal"
          minRows="4"
          variant="outlined"
          fullWidth
        />
      </Paper>
      <div className={classes.timesheetActions}>
        <Button
          data-cy="afterregistration-save"
          disabled={
            !formikProps.dirty ||
            formikProps.isSubmitting ||
            formikProps.values.rows.length === 0
          }
          id="timesheet-save"
          color="primary"
          variant="contained"
          type="submit"
          className={classes.btnSpacing}
        >
          {s("saveAndSubmitButton")}
        </Button>
      </div>
    </Form>
  );
};

const Afterregistration = ({
  routeParams: { contractorId }
}: AfterregistrationProps) => {
  const t = useTranslate("AfterregistrationPage");
  const t3 = useTranslate("ValidationErrorMessages");
  const u = useTranslate("Utility");
  let routerHistory = useHistory();

  const initialValues: TimeSheetForm = {
    title: `Afterregistration: ${niceDate(new Date().toISOString())}`,
    contractor: contractorId,
    start_date: set(sub(new Date(), { days: 1 }), {
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    }).toISOString(),
    person: null,
    notes: "",
    rows: [],
    current_service: null
  };

  const goBack = () => {
    history.replace("/after-registrations?same_tab=true");
  };

  const handleSubmit = async ({
    accompanying_people,
    ...value
  }: TimeSheetForm) => {
    const startDate = new Date(value.start_date);
    const [rows] = (value.rows ?? []).reduce(
      ([rows, prev, addedDays], row, row_id) => {
        const start_h = parseTimeAsFloat(row.start_time);
        const start_days = addedDays + (prev > start_h ? 1 : 0);
        const start_time = parse(
          row.start_time,
          "HH:mm",
          add(startDate, { days: start_days })
        ).toISOString();

        const end_h = parseTimeAsFloat(row.end_time);
        const end_days = start_days + (start_h >= end_h ? 1 : 0);
        const end_time = parse(
          row.end_time!,
          "HH:mm",
          add(startDate, { days: end_days })
        ).toISOString();

        rows.push({
          ...row,
          row_id,
          start_time,
          end_time,
          accompanying_people
        });

        return [rows, end_h, end_days];
      },
      [[] as typeof value.rows, 0, 0]
    );

    try {
      const response = await addNewTimesheetAPI({
        ...value,
        rows,
        manually_entered: true
      });
      const updatedData: Partial<TimeSheetExtended> = {
        oca: response.oca,
        submitted: true,
        accepted: true
      };
      await saveAndSubmitWorkAPI(response.id, updatedData);
      showGlobalSnackbar(u("addedSuccessfullyMessage"), "success");
      routerHistory.push(`/approvals?same_tab=true`);
    } catch (e: any) {
      console.warn(e);
      showGlobalSnackbar(e.message, "error");
    }
  };

  return (
    <div>
      <Button variant="contained" color="primary" centerRipple onClick={goBack}>
        <NavigateBeforeIcon /> {t("navigateToTimesheetsLabel")}
      </Button>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ValidationSchema(t3)}
      >
        {(formikProps) => <AfterregistrationInner formikProps={formikProps} />}
      </Formik>
    </div>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (props: AfterregistrationProps) => (
  <ConfirmationDialogServiceProvider>
    <Afterregistration {...props} />
  </ConfirmationDialogServiceProvider>
);
